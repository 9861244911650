import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { NumberValidator } from "../../common/components/validators/NumberValidator";
import { NumberDecimalValidator } from "../../common/components/validators/NumberDecimalValidator";
import { IProductCategory } from "./ProductCategory";
import { IMaterialDefaultGrade } from "./MaterialDefaultGrade";

export interface IMillProduct {
    id?: number,
    mill_id?: number,
    product_category_id?: number,
    product_category?: IProductCategory,
    material_grade_category_id?: number,
    material_grade_category?: IMaterialDefaultGrade,
    od_min?: number,
    od_max?: number,
    wt_min?: number,
    wt_max?: number,
}


export class MillProduct extends DataModel<IMillProduct> {

    public resourceName = "mill-product";
    public resourceNamePlural = "mill-products";

    private _id: number;
    private _mill_id?: number;
    private _product_category_id: number;
    private _product_category: IProductCategory;
    private _material_grade_category_id: number;
    private _material_grade_category: IMaterialDefaultGrade;
    private _od_min?: number;
    private _od_max?: number;
    private _wt_min?: number;
    private _wt_max?: number;


    protected getDefaultValues(): IMillProduct {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IMillProduct> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IMillProduct> {
        return new DataModelValidator<IMillProduct>({
            product_category_id: [new RequiredValidator(), new NumberValidator()],
            material_grade_category_id: [new RequiredValidator(), new NumberValidator()],
            od_min: [new RequiredValidator(), new NumberDecimalValidator()],
            od_max: [new RequiredValidator(), new NumberDecimalValidator()],
            wt_min: [new RequiredValidator(), new NumberDecimalValidator()],
            wt_max: [new RequiredValidator(), new NumberDecimalValidator()],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IMillProduct>, action: AnyAction): IDataModelState<IMillProduct> {
        return state;
    }

    public loadAmlClasss(): Promise<IMillProduct[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }

    public loadByMillId(id: number): Promise<IMillProduct[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural+ "?filter=mill_id:eq:"+id)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IMillProduct | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<IMillProduct | AxiosError> {

        return this.createDataSource(this.resourceName)
            .postOperation({
                mill_id: +this._mill_id,
                product_category_id: +this._product_category_id,
                material_grade_category_id: +this._material_grade_category_id,
                od_max: +this._od_max,
                od_min: +this._od_min,
                wt_min: +this._wt_min,
                wt_max: +this._wt_max,
            });
    }

    public update(data: IMillProduct): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation(data);
    }



    getStoreKey(): string {
        return "MILL-PRODUCT";
    }

    protected setFromObj(data: IMillProduct): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._mill_id = DataModel.safeGet(data.mill_id, this._mill_id);
        this._product_category_id = DataModel.safeGet(data.product_category_id, this._product_category_id);
        this._product_category = DataModel.safeGet(data.product_category, this._product_category);
        this._material_grade_category_id = DataModel.safeGet(data.material_grade_category_id, this._material_grade_category_id);
        this._material_grade_category = DataModel.safeGet(data.material_grade_category, this._material_grade_category);
        this._od_max = DataModel.safeGet(data.od_max, this._od_max);
        this._wt_max = DataModel.safeGet(data.wt_max, this._wt_max);
        this._od_min = DataModel.safeGet(data.od_min, this._od_min);
        this._wt_min = DataModel.safeGet(data.wt_min, this._wt_min);
    }


    protected toObj(): IMillProduct {
        return {
            id: +this._id,
            mill_id: +this._mill_id,
            product_category_id: +this._product_category_id,
            material_grade_category_id: +this._material_grade_category_id,
            od_max: +this._od_max,
            wt_max: +this._wt_max,
            od_min: +this._od_min,
            wt_min: +this._wt_min
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get product_category_id() {
        return this._product_category_id;
    }

    get product_category() {
        return this._product_category;
    }


    get mill_id() {
        return this._mill_id;
    }

    set mill_id(id: number) {
        this._mill_id = id;
    }


    get material_grade_category_id() {
        return this._material_grade_category_id;
    }

    get material_grade_category() {
        return this._material_grade_category;
    }


    get od_max() {
        return this._od_max;
    }


    get wt_max() {
        return this._wt_max;
    }

    get od_min() {
        return this._od_min;
    }

    get wt_min() {
        return this._wt_min;
    }


}
