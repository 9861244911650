import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IMaterialGradeCategory } from "./MaterialGradeCategory";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import { NumberValidator } from "../../common/components/validators/NumberValidator";

export interface IMaterialDefaultGrade {
    id?: number,
    name?: string,
    material_grade_category_id?: number,
    material_grade_category?: IMaterialGradeCategory
}


export class MaterialDefaultGrade extends DataModel<IMaterialDefaultGrade> {

    public resourceName = "material-default-grade";
    public resourceNamePlural = "material-default-grades";

    private _id: number;
    private _name: string;
    private _material_grade_category_id: number;
    private _material_grade_category: IMaterialGradeCategory


    protected getDefaultValues(): IMaterialDefaultGrade {
        return {
            material_grade_category: {}
        };
    }

    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IMaterialDefaultGrade> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IMaterialDefaultGrade> {
        return new DataModelValidator<IMaterialDefaultGrade>({
            name: [new RequiredValidator(), new LengthValidator({ min: 2 })],
            material_grade_category_id: [new RequiredValidator(), new NumberValidator()],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IMaterialDefaultGrade>, action: AnyAction): IDataModelState<IMaterialDefaultGrade> {
        return state;
    }

    public loadMaterialDefaultGrades(): Promise<IMaterialDefaultGrade[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IMaterialDefaultGrade | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public createNew(): Promise<IMaterialDefaultGrade | AxiosError> {
        return this.createDataSource(this.resourceName)
            .postOperation({
                name: this._name,
                material_grade_category_id: Number(this._material_grade_category_id)
            });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource()
            .addCustomPath('/' + id)
            .plainDelOperation();
    }

    public update(data: IMaterialDefaultGrade): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation(
                {
                    name: data.name,
                    material_grade_category_id: Number(data.material_grade_category_id)
                }
            );
    }


    getStoreKey(): string {
        return "MATERIAL-DEFAULT-GRADE";
    }

    protected setFromObj(data: IMaterialDefaultGrade): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._material_grade_category_id = DataModel.safeGet(data.material_grade_category_id, this._material_grade_category_id);
        this._material_grade_category = DataModel.safeGet(data.material_grade_category, this._material_grade_category);
    }


    protected toObj(): IMaterialDefaultGrade {
        return {
            id: this._id,
            name: this._name,
            material_grade_category_id: this._material_grade_category_id,
            material_grade_category: this._material_grade_category
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }

    get material_grade_category_id() {
        return this._material_grade_category_id;
    }

    get material_grade_category() {
        return this._material_grade_category;
    }

}
