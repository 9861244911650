import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import { User, IUser } from "./User";
import { ISupplierClassification } from "./SupplierClassification";
import { ICountry } from "./Country";
import Identity from "../user/Identity";
import Util from "../custom/Util";

export interface ISupplier {
    id?: number,
    manager_id?: number,
    manager?: IUser,
    supplier_classification_id?: number,
    supplier_classification?: ISupplierClassification,
    country_id?: number,
    country?: ICountry,
    name?: string
    city?: string
    zip_code?: string
    address?: string
    phone_number?: string
    website?: string,
    framework_agreement_date?: string,
    framework_agreement_attachment?: string,
    bonus_agreement_date?: string,
    bonus_agreement_attachment?: string,
    code_of_conduct?: string,
}


export class Supplier extends DataModel<ISupplier> {

    public resourceName = "supplier";
    public resourceNamePlural = "suppliers";

    private _id: number;
    private _manager_id: number;
    private _manager: IUser;
    private _supplier_classification_id: number;
    private _supplier_classification: ISupplierClassification;
    private _country_id: number;
    private _country: ICountry;
    private _name: string;
    private _city: string;
    private _zip_code: string;
    private _address: string;
    private _phone_number: string;
    private _website: string;
    private _framework_agreement_date: string;
    private _framework_agreement_attachment: string;
    private _bonus_agreement_date: string;
    private _bonus_agreement_attachment: string;
    private _code_of_conduct: string;

    protected getDefaultValues(): ISupplier {
        return {
            manager: {},
            country: {},
        };
    }

    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<ISupplier> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ISupplier> {
        return new DataModelValidator<ISupplier>({
            name: [new RequiredValidator(), new LengthValidator({ min: 3 })],
            manager_id: [new RequiredValidator()],
            supplier_classification_id: [new RequiredValidator()],
            country_id: [new RequiredValidator()],
            city: [new RequiredValidator(), new LengthValidator({ max: 50 })],
            zip_code: [new RequiredValidator(), new LengthValidator({ max: 20 })],
            address: [new RequiredValidator(), new LengthValidator({ max: 100 })],
            phone_number: [new LengthValidator({ max: 20 })],
            website: [new LengthValidator({ max: 100 })],
            framework_agreement_attachment: [new LengthValidator({ max: 255 })],
            bonus_agreement_attachment: [new LengthValidator({ max: 255 })],
            code_of_conduct: [new LengthValidator({ max: 255 })],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<ISupplier>, action: AnyAction): IDataModelState<ISupplier> {
        return state;
    }

    public loadSuppliers(): Promise<ISupplier[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<ISupplier | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                name: this._name,
                manager_id: Number(this._manager_id),
                supplier_classification_id: Number(this._supplier_classification_id),
                country_id: Number(this._country_id),
                city: this._city,
                zip_code: this._zip_code,
                address: this._address,
                framework_agreement_date: this._framework_agreement_date,
                framework_agreement_attachment: this._framework_agreement_attachment,
                bonus_agreement_date: this._bonus_agreement_date,
                bonus_agreement_attachment: this._bonus_agreement_attachment,
                phone_number: this._phone_number,
                website: this._website,
                code_of_conduct: this._code_of_conduct,
            });
    }

    public update(data: ISupplier): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation(data);
    }



    getStoreKey(): string {
        return "SUPPLIER";
    }

    protected setFromObj(data: ISupplier): void {
        this._id = DataModel.safeGet(data.id, this._id) as number;
        this._manager_id = DataModel.safeGet(data.manager_id, this._manager_id) as number;
        this._manager = DataModel.safeGet(data.manager, this._manager);
        this._supplier_classification_id = DataModel.safeGet(data.supplier_classification_id, this._supplier_classification_id) as number;
        this._supplier_classification = DataModel.safeGet(data.supplier_classification, this._supplier_classification);
        this._country_id = DataModel.safeGet(data.country_id, this._country_id) as number;
        this._country = DataModel.safeGet(data.country, this._country);
        this._name = DataModel.safeGet(data.name, this._name);
        this._city = DataModel.safeGet(data.city, this._city);
        this._zip_code = DataModel.safeGet(data.zip_code, this._zip_code);
        this._address = DataModel.safeGet(data.address, this._address);
        this._phone_number = DataModel.safeGet(data.phone_number, this._phone_number);
        this._website = DataModel.safeGet(data.website, this._website);
        this._framework_agreement_date = Util.formatDatetime(DataModel.safeGet(data.framework_agreement_date, this._framework_agreement_date));
        this._framework_agreement_attachment = DataModel.safeGet(data.framework_agreement_attachment, this._framework_agreement_attachment);
        this._bonus_agreement_date = Util.formatDatetime(DataModel.safeGet(data.bonus_agreement_date, this._bonus_agreement_date));
        this._bonus_agreement_attachment = DataModel.safeGet(data.bonus_agreement_attachment, this._bonus_agreement_attachment);
        this._code_of_conduct = DataModel.safeGet(data.code_of_conduct, this._code_of_conduct);
    }


    protected toObj(): ISupplier {
        return {
            id: this._id,
            manager_id: +this._manager_id,
            manager: this._manager,
            supplier_classification: this._supplier_classification,
            supplier_classification_id: +this._supplier_classification_id,
            country: this._country,
            country_id: +this._country_id,
            name: this._name,
            city: this._city,
            zip_code: this._zip_code,
            address: this._address,
            phone_number: this._phone_number,
            website: this._website,
            framework_agreement_date: this._framework_agreement_date ? Util.formatDatetimeTimestamp(this._framework_agreement_date) : null,
            framework_agreement_attachment: this._framework_agreement_attachment,
            bonus_agreement_date: this._bonus_agreement_date ? Util.formatDatetimeTimestamp(this._bonus_agreement_date) : null,
            bonus_agreement_attachment: this._bonus_agreement_attachment,
            code_of_conduct: this._code_of_conduct,
        };
    }

    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    set manager_id(id: number) {
        this._manager_id = id;
    }

    get name() {
        return this._name;
    }

    get manager_id() {
        return this._manager_id;
    }

    get country_id() {
        return this._country_id;
    }

    get supplier_classification_id() {
        return this._supplier_classification_id;
    }

    get supplier_classification() {
        return this._supplier_classification;
    }

    get city() {
        return this._city;
    }

    get zip_code() {
        return this._zip_code;
    }

    get address() {
        return this._address;
    }

    get phone_number() {
        return this._phone_number;
    }


    get website() {
        return this._website;
    }

    get country() {
        return this._country;
    }

    get manager() {
        return this._manager;
    }

    get framework_agreement_date() {
        return this._framework_agreement_date;
    }

    get framework_agreement_attachment() {
        return this._framework_agreement_attachment;
    }

    set framework_agreement_attachment(url: string) {
        this._framework_agreement_attachment = url;
    }

    get bonus_agreement_date() {
        return this._bonus_agreement_date;
    }

    get bonus_agreement_attachment() {
        return this._bonus_agreement_attachment;
    }

    set bonus_agreement_attachment(url: string) {
        this._bonus_agreement_attachment = url;
    }

    get code_of_conduct() {
        return this._code_of_conduct;
    }

    set code_of_conduct(url: string) {
        this._code_of_conduct = url;
    }

}
