import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";

export interface ISupplierMetting {
    id?: number,
    country_id?: number,
    supplier_id?: number,
    supplier_attendants?: string,
    vl_attendants?: string,
    subject?: string,
    meeting_location?: string,
    meeting_date?: string,
    attachment?: string,
}


export class SupplierMeeting extends DataModel<ISupplierMetting> {

    public resourceName = "supplier-meeting";
    public resourceNamePlural = "supplier-meetings";

    private _id?: number;
    private _country_id?: number;
    private _supplier_id?: number;
    private _supplier_attendants?: string;
    private _vl_attendants?: string;
    private _subject?: string;
    private _meeting_location?: string;
    private _meeting_date?: string;
    private _attachment?: string;

    protected getDefaultValues(): ISupplierMetting {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<ISupplierMetting> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ISupplierMetting> {
        return new DataModelValidator<ISupplierMetting>({
            subject: [new RequiredValidator(), new LengthValidator({ min: 6 })],
            meeting_date: [new RequiredValidator()],
            meeting_location: [new RequiredValidator()],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<ISupplierMetting>, action: AnyAction): IDataModelState<ISupplierMetting> {
        return state;
    }

    public loadAmlClasss(): Promise<ISupplierMetting[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<ISupplierMetting | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
        .plainPostOperation({
            supplier_id: Number(this._supplier_id),
            supplier_attendants: this._supplier_attendants,
            vl_attendants: this._vl_attendants,
            subject: this._subject,
            meeting_location: this._meeting_location,
            meeting_date: this._meeting_date,
            attachment: this._attachment,
            country_id: Number(this._country_id),
        });
    }

    public update(data: ISupplierMetting): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                supplier_id: Number(data.supplier_id),
                supplier_attendants: data.supplier_attendants,
                vl_attendants: data.vl_attendants,
                subject: data.subject,
                meeting_location: data.meeting_location,
                meeting_date: data.meeting_date,
                attachment: data.attachment,
                country_id: Number(data.country_id),
            });
    }

    getStoreKey(): string {
        return "SUPPLIER-MEETING";
    }

    protected setFromObj(data: ISupplierMetting): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._country_id = DataModel.safeGet(data.country_id, this._country_id) as number;
        this._supplier_id = DataModel.safeGet(data.supplier_id, this._supplier_id) as number;
        this._supplier_attendants = DataModel.safeGet(data.supplier_attendants, this._supplier_attendants);
        this._vl_attendants = DataModel.safeGet(data.vl_attendants, this._vl_attendants);
        this._subject = DataModel.safeGet(data.subject, this._subject);
        this._meeting_location = DataModel.safeGet(data.meeting_location, this._meeting_location);
        this._meeting_date = DataModel.safeGet(data.meeting_date, this._meeting_date);
        this._attachment = DataModel.safeGet(data.attachment, this._attachment);
    }


    protected toObj(): ISupplierMetting {
        return {
            id: this._id,
            country_id: this._country_id,
            supplier_id: this._supplier_id,
            supplier_attendants: this._supplier_attendants,
            vl_attendants: this._vl_attendants,
            subject: this._subject,
            meeting_location: this._meeting_location,
            meeting_date: this._meeting_date,
            attachment: this._attachment
        };
    }

    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get country_id() {
        return this._country_id;
    }

    set country_id(id: number) {
        this._country_id = id;
    }

    get supplier_id() {
        return this._supplier_id;
    }

    get supplier_attendants() {
        return this._supplier_attendants;
    }

    get subject() {
        return this._subject;
    }

    get meeting_location() {
        return this._meeting_location;
    }

    get meeting_date() {
        return this._meeting_date;
    }

    get attachment() {
        return this._attachment;
    }

    set attachment(path: string) {
        this._attachment = path;
    }

}
