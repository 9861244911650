import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";

export interface IProductCategory {
    id?: number,
    name?: string,
    abbreviation?: string
}


export class ProductCategory extends DataModel<IProductCategory> {

    public resourceName = "product-category";
    public resourceNamePlural = "product-categories";

    private _id: number;
    private _name: string;
    private _abbreviation: string;


    protected getDefaultValues(): IProductCategory {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IProductCategory> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL  + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IProductCategory> {
        return new DataModelValidator<IProductCategory>({
            name: [new RequiredValidator(), new LengthValidator({ min: 4 })],
            abbreviation: [new RequiredValidator(), new LengthValidator({ min: 3 })],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IProductCategory>, action: AnyAction): IDataModelState<IProductCategory> {
        return state;
    }

    public loadProductCategories(): Promise<IProductCategory[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IProductCategory | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public createNew(): Promise<IProductCategory | AxiosError> {
        return this.createDataSource(this.resourceName)
            .postOperation({
                name: this._name,
                abbreviation: this._abbreviation
            });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource()
            .addCustomPath('/' + id)
            .plainDelOperation();
    }

    public update(data: IProductCategory): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                name: data.name,
                abbreviation: data.abbreviation
            });
    }


    getStoreKey(): string {
        return "PRODUCT-CATEGORY";
    }

    protected setFromObj(data: IProductCategory): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._abbreviation = DataModel.safeGet(data.abbreviation, this._abbreviation);
    }


    protected toObj(): IProductCategory {
        return {
            id: this._id,
            name: this._name,
            abbreviation: this._abbreviation
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }

    get abbreviation() {
        return this._abbreviation;
    }


}
