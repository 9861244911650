import * as React from 'react';
import { ITableColumn } from "../Table";
import { BasicSorter } from "../sorter/BasicSorter";
import { BasicFilter } from "../filter/BasicFilter";

export interface IDataColumn extends ITableColumn {
    attr: string,
    sorter?: BasicSorter,
    filter?: BasicFilter
}

export class DataColumn implements IDataColumn {

    private _label: string;

    private _attr: string;

    private _sorter?: BasicSorter;

    private _filter?: BasicFilter;

    private _width?: number;

    public constructor(label: string, attr: string, width?: number, sorter?: BasicSorter, filter?: BasicFilter) {
        this._label = label;
        this._attr = attr;
        this._width = width;

        if (sorter) {
            this._sorter = sorter;
        }

        if (filter) {
            this._filter = filter;
        }
    }

    get label(): React.ReactNode {
        const className: string = (this._sorter) ? 'sortable' : '';
        const cb: (event: any) => void = (this._sorter) ? this._sorter.sort.bind(this._sorter) : (event: any): void => { };

        return (<div className={className} onClick={cb}>
            {this._label} {(this._sorter) ? this._sorter.render() : null}
        </div>);
    }

    get attr(): string {
        return this._attr;
    }

    get width(): number | undefined {
        return this._width;
    }

    get content(): (row: any) => React.ReactNode {
        return (row: any) => {
            return (<div>{this.getValue(row)}</div>);
        }
    }

    protected getValue(row: any): string {
        if (this._attr.includes(".")) {
            let parts = this._attr.split(".");
            let result = row;
            for (let i = 0; i < parts.length; i++) {
                const nextO = result[parts[i]];
                if (nextO === null || nextO === undefined) return "";
                result = nextO;
            }
            return result;
        }
        else return row[this._attr];
    }

    get sorter(): BasicSorter | undefined {
        return this._sorter;
    }

    get filter(): BasicFilter | undefined {
        return this._filter;
    }
}