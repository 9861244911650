import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import Logo from './header/Logo';
import Search from './header/Search';
import HeaderNav from './header/HeaderNav';

class Header extends Component {
    render() {

        return (
            <header className="header">
                <Container fluid>
                    <Row>

                        <Logo /> {/* Importovao logotip kao funkciju */}

                        <Search /> {/* Komponenta napredne pretrage u headeru */}

                    </Row>
                </Container>

                <HeaderNav /> {/* Navigacija sa opcijama */}

            </header>


                    );
                }
            }

export default Header;
