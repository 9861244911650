import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import { EmailValidator } from "../../common/components/validators/EmailValidator";

export interface IMillContact {
    id?: number,
    mill_id?: number,
    name?: string,
    job_title?: string,
    phone?: string,
    mobile_phone?: string
    email?: string
}


export class MillContact extends DataModel<IMillContact> {

    public resourceName = "mill-contact";
    public resourceNamePlural = "mill-contacts";

    private _id: number;
    private _mill_id?: number;
    private _job_title?: string;
    private _phone?: string;
    private _name?: string;
    private _mobile_phone?: string;
    private _email?: string;


    protected getDefaultValues(): IMillContact {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IMillContact> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IMillContact> {
        return new DataModelValidator<IMillContact>({
            name: [new RequiredValidator(), new LengthValidator({ max: 100 })],
            job_title: [new RequiredValidator(), new LengthValidator({max : 50})],
            phone: [new RequiredValidator(), new LengthValidator({max : 20})],
            email: [new EmailValidator()]
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IMillContact>, action: AnyAction): IDataModelState<IMillContact> {
        return state;
    }

    public loadMillContacts(): Promise<IMillContact[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IMillContact | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }


    public loadByMillId(id: number): Promise<IMillContact[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural+ "?filter=mill_id:eq:"+id)
            .getList({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource()
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<IMillContact | AxiosError> {

        return this.createDataSource(this.resourceName)
            .postOperation({
                name: this._name,
                mill_id: +this._mill_id,
                email: this._email,
                job_title: this._job_title,
                phone: this._phone,
                mobile_phone: this._mobile_phone,
            });
    }

    public update(data: IMillContact): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                name: this._name,
                mill_id: +this._mill_id,
                email: this._email,
                job_title: this._job_title,
                phone: this._phone,
                mobile_phone: this._mobile_phone,
            });
    }



    getStoreKey(): string {
        return "MILL-CONTACT";
    }

    protected setFromObj(data: IMillContact): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._mill_id = DataModel.safeGet(data.mill_id, this._mill_id);
        this._job_title = DataModel.safeGet(data.job_title, this._job_title);
        this._phone = DataModel.safeGet(data.phone, this._phone);
        this._mobile_phone = DataModel.safeGet(data.mobile_phone, this._mobile_phone);
        this._email = DataModel.safeGet(data.email, this._email);
    }


    protected toObj(): IMillContact {
        return {
            id: this._id,
            name: this._name,
            mill_id: this._mill_id,
            job_title: this._job_title,
            phone: this._phone,
            mobile_phone: this._mobile_phone,
            email: this._email
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }


    get mill_id() {
        return this._mill_id;
    }

    set mill_id(id: number) {
        this._mill_id = id;
    }


    get phone() {
        return this._phone;
    }


    get mobile_phone() {
        return this._mobile_phone;
    }


    get email() {
        return this._email;
    }


}
