import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Container, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, FormGroup, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import Util from '../custom/Util';
import { PasswordInput } from '../../common/components/widgets/form/input/PasswordInput';
import { User, IUser } from '../models/User';
import { AxiosResponse, AxiosError } from 'axios';
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Identity from '../user/Identity';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import { DataProvider } from '../../common/model/DataProvider';
import { IFavoriteMill, Mill } from '../models/Mill';
import { Table as TableModel } from '../../common/components/widgets/table/Table';

@withRouter(Path.PROFILE_SETTINGS.toString(), PageContainer)
@withSecurity([Role.ADMIN.toString(), Role.CATEGORY_MANAGER.toString(), Role.USER.toString()], Identity, Path.HOMEPAGE)
export default class ProfileSettingsPage extends VlbPage {
    private userModel: User = new User();
    private favMillDP = new DataProvider<IFavoriteMill>();
    private mill = new Mill(false);

    private inputs: { [key: string]: React.RefObject<any>; } = {

        password: React.createRef(),
        password_confirmed: React.createRef()

    };

    constructor(props: any) {
        super(props);


        this.getValidateHandle = this.getValidateHandle.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.deleteMill = this.deleteMill.bind(this);

        this.state = {
            userInfo: {},
            userInfoLoaded: false,
            modalDelete: false,
            currentModelDelete: null
        };
    }

    pageTitle() {
        return "Profile settings" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
            this.userModel
        ];
    }

    componentDidMount() {

        this.userModel.getCurrentUser({})
            .then((response: AxiosResponse) => {

                this.setState({
                    userInfo: response.data,
                    userInfoLoaded: true
                });


            }).catch((e: AxiosError) => {
                Util.notification("error", "Profile cannot be loaded", 1500);
            });

        super.componentDidMount();
    }

    deleteMill() {
        this.mill.delFav(this.state.currentModelDelete["mill_id"])
            .then(() => {
                Util.notification("success", "Your have removed mill from saved mills.", 1000);
                this.setState(prevState => ({
                    modalDelete: !prevState.modalDelete,
                }));
            })
            .catch(() => {

                Util.notification("error", "Mill could not be removed.", 10000);
            });
    }

    changePassword(event: React.MouseEvent<HTMLInputElement>) {

        this.userModel.changeScenario(User.SCENARIO.CHANGE_PASSWORD);

        if (this.getValidateHandle(event)) {
            this.userModel.updatePassword(this.userModel)
                .then((response: AxiosResponse) => {
                    Util.notification("success", "Your password has been changed successfully!", 10000);
                })
                .catch((error: AxiosError) => {
                    Util.notification("error", "Password change failed.", 10000);
                });
        }
    }

    getValidateHandle(event: React.MouseEvent<HTMLInputElement>) {

        this.readInfo();
        const valid = this.userModel.validate();

        if (!valid) {
            const errors: { [attr: string]: string } = this.userModel.getErrors();
            this.fillInputsWithErrors(errors, this.inputs);
        }

        event.preventDefault();

        return valid;
    }

    private readInfo(): IUser {

        const plainObject: IUser = {};

        for (const key in this.inputs) {

            if (this.inputs.hasOwnProperty(key) && this.inputs[key].current) {
                plainObject[key] = this.inputs[key].current.getValue();
                this.inputs[key].current.removeError();
            }
        }

        this.userModel.setFromPlainObject(plainObject);

        return plainObject;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    renderContent() {

        let user = this.state.userInfo;
        let loadStatus = this.state.userInfoLoaded;
        const loader = <i className="content-loader w-100 mw-2" />;

        const cols: any[] = [
            new DataColumn('Mill', 'mill.name', 50, new BasicSorter(), null),
            new DataColumn('Country', 'mill.country.name', 10, new BasicSorter(), null),
            new DataColumn('City', 'mill.city', 10, new BasicSorter(), null),
            new DataColumn('Supplier', 'mill.supplier.name', 10, new BasicSorter(), null),
            new ActionColumn("", null, null, 10,
                [{
                    operationCb: (row: any) => {
                        window.location.pathname = "/supplier/" + row.mill.supplier_id + "/mill/" + row.id
                    },
                    iconClassName: "table-edit",
                    message: null,
                    icon: <FontAwesomeIcon icon={faExternalLinkSquareAlt} />,
                    iconStyle: {}
                }],
                "Link"
            ),
            new ActionColumn("",
                (row: Object) => {

                    this.setState({
                        modalDelete: !this.state.modalDelete,
                        currentModelDelete: row
                    })
                },
                null,
                10,
                null,
                "Remove"
            )
        ];


        let deleteModal;
        deleteModal = (
            <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={() => this.setState({ modalDelete: !this.state.modalDelete })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: !this.state.modalDelete })}>{this.state.currentModelDelete ? this.state.currentModelDelete.mill.name : null}</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this?
                    </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalDelete: !this.state.modalDelete })}>Cancel</Button>
                    <Button outline color="primary" onClick={() =>
                        this.deleteMill()
                    }>Delete</Button>
                </ModalFooter>
            </Modal>
        )

        return (
            <>
                <Container fluid className="page-content" >

                    <div className="title-box">
                        <h1 className="page-title">Profile settings</h1>
                    </div>

                    <Row>
                        <Col xl="4">
                            <div className="element-box">
                                <h2>Profile info</h2>
                                <Table hover responsive>
                                    <tbody>
                                        <tr>
                                            <th>Username</th>
                                            <td>{loadStatus ? user.username : loader}</td>
                                        </tr>

                                        <tr>
                                            <th>E-mail</th>
                                            <td>{loadStatus ? user.email : loader}</td>
                                        </tr>

                                        <tr>
                                            <th>Full name</th>
                                            <td>{loadStatus ? user.full_name : loader}</td>
                                        </tr>

                                        <tr>
                                            <th>Role</th>
                                            <td>{loadStatus ? user.item_name : loader}</td>
                                        </tr>

                                        <tr>
                                            <th>Abbreviation</th>
                                            <td>{loadStatus ? user.abbreviation : loader}</td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </Col>

                        <Col xl="8">
                            <div className="element-box">
                                <h2>Change password</h2>
                                <p>It's always a good idea to update your password regularly and to make sure it's unique from other passwords you use. If you've forgotten your password, you can reset it for a fresh start.</p>
                                <FormGroup>
                                    <PasswordInput
                                        label=""
                                        id="Password"
                                        placeholder="New password"
                                        ref={this.inputs.password}
                                        onBlur={this.getValidateHandle}
                                        autoComplete="new-password"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <PasswordInput
                                        label=""
                                        id="rePassword"
                                        placeholder="Confirm password"
                                        ref={this.inputs.password_confirmed}
                                        onBlur={this.getValidateHandle}
                                        autoComplete="new-password"
                                    />
                                </FormGroup>
                                <Button color="primary" onClick={this.changePassword}>Change password</Button>
                            </div>
                        </Col>

                        <Col xl="12">
                            <div className="element-box">
                                <h2>Saved mills</h2>

                                <ReduxComponentWrapper component={TableModel} componentPropsCallback={(state: any) => ({
                                    provider: this.favMillDP,
                                    searchCallback: (params: IRestDataSourceParams): void => {
                                        this.favMillDP.refreshWithHeaders(
                                            this.mill.getFavoriteMills(params)
                                        )
                                    },
                                    columns: cols,
                                    loader:[6,5]
                                })} />
                            </div>
                        </Col>

                    </Row>
                    {deleteModal}

                </Container>
            </>
        );
    }
}
