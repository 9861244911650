import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";

export interface IMillInformation {
    id?: number,
    mill_id?: number,
    name?: string,
    attachment?: string
}


export class MillInformation extends DataModel<IMillInformation> {

    public resourceName = "mill-information";
    public resourceNamePlural = "mill-informations";

    private _id: number;
    private _mill_id?: number;
    private _name?: string;
    private _attachment?: string;


    protected getDefaultValues(): IMillInformation {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IMillInformation> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IMillInformation> {
        return new DataModelValidator<IMillInformation>({
            name: [new RequiredValidator(), new LengthValidator({ max: 100 })],
            attachment: [new RequiredValidator()],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IMillInformation>, action: AnyAction): IDataModelState<IMillInformation> {
        return state;
    }

    public loadMillInformations(): Promise<IMillInformation[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IMillInformation | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public loadByMillId(id: number): Promise<IMillInformation[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural+ "?filter=mill_id:eq:"+id)
            .getList({});
    }


    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<IMillInformation | AxiosError> {

        return this.createDataSource(this.resourceName)
            .postOperation({
                name: this._name,
                mill_id: +this._mill_id,
                attachment: this._attachment
            });
    }

    public update(data: IMillInformation): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                name: this._name,
                mill_id: +this._mill_id,
                attachment: this._attachment
            });
    }



    getStoreKey(): string {
        return "MILL-INFORMATION";
    }

    protected setFromObj(data: IMillInformation): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._mill_id = DataModel.safeGet(data.mill_id, this._mill_id);
        this._attachment = DataModel.safeGet(data.attachment, this._attachment);
    }


    protected toObj(): IMillInformation {
        return {
            id: this._id,
            name: this._name,
            mill_id: this._mill_id,
            attachment: this._attachment,
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }


    get mill_id() {
        return this._mill_id;
    }

    set mill_id(id: number) {
        this._mill_id = id;
    }


    get attachment() {
        return this._attachment;
    }

    set attachment(path: string) {
        this._attachment = path;
    }


}
