export const reactSelectStyle = {
    control: (base, state) => ({
        ...base,
        background: "#FFF",
        fontSize: "1rem",
        borderColor: state.isFocused ? "#3421cc" : "#ced4da",
        boxShadow: state.isFocused ? '0 0 0 .2rem rgba(24,15,94,.25)' : '',
        color: '#fff',
        borderRadius: '0.35rem',
        "&:hover": {
            borderColor: state.isFocused ? "#3421cc" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 .2rem rgba(24,15,94,.25)" : "",
        },
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? "#a8052c" : "#FFF",
        ':active': {
            backgroundColor: state.isSelected ? "#a8052c" : "#a8052c",
            color: state.isSelected ? "#FFF" : "#FFF"
        },
        '&:hover': {
            backgroundColor: state.isSelected ? "#a8052c" : "#a8052c",
            color: state.isSelected ? "#FFF" : "#FFF"
        }
    })
}
