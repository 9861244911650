import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";

export interface ICertificateIssuer {
    id?: number,
    name?: string,
}


export class CertificateIssuer extends DataModel<ICertificateIssuer> {

    public resourceName = "certificate-issuer";
    public resourceNamePlural = "certificate-issuers";

    private _id: number;
    private _name: string;


    protected getDefaultValues(): ICertificateIssuer {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceName): RestDataSource<ICertificateIssuer> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ICertificateIssuer> {
        return new DataModelValidator<ICertificateIssuer>({
            name: [new RequiredValidator(), new LengthValidator({ min: 3 })],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<ICertificateIssuer>, action: AnyAction): IDataModelState<ICertificateIssuer> {
        return state;
    }

    public loadCertificateIssuers(): Promise<ICertificateIssuer[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<ICertificateIssuer | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public createNew(): Promise<ICertificateIssuer | AxiosError> {
        console.log(this._name)
        return this.createDataSource(this.resourceName)
            .postOperation({ name: this._name });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource()
            .addCustomPath('/' + id)
            .plainDelOperation();
    }

    public update(data: ICertificateIssuer): Promise<AxiosResponse> {
        return this.createDataSource()
            .addCustomPath('/' + this.id)
            .plainPatchOperation({ name: data.name });
    }


    getStoreKey(): string {
        return "CERTIFICATE-ISSUER";
    }

    protected setFromObj(data: ICertificateIssuer): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
    }


    protected toObj(): ICertificateIssuer {
        return {
            id: this._id,
            name: this._name,
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }


}
