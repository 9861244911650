import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import { EmailValidator } from "../../common/components/validators/EmailValidator";

export interface ISupplierContact {
    id?: number,
    supplier_id?: number,
    name?: string
    job_title?: string
    phone?: string
    mobile_phone?: string
    email?: string,
    sales_office?: string
}


export class SupplierContact extends DataModel<ISupplierContact> {

    public resourceName = "supplier-contact";
    public resourceNamePlural = "supplier-contacts";

    private _id: number;
    private _supplier_id: number;
    private _name: string
    private _job_title: string
    private _phone: string
    private _mobile_phone: string
    private _email: string
    private _sales_office: string

    protected getDefaultValues(): ISupplierContact {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<ISupplierContact> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ISupplierContact> {
        return new DataModelValidator<ISupplierContact>({
            name: [new RequiredValidator(), new LengthValidator({ max: 100 })],
            job_title: [new RequiredValidator(), new LengthValidator({ max: 50 })],
            phone: [new RequiredValidator(), new LengthValidator({ max: 20 })],
            mobile_phone: [new RequiredValidator(), new LengthValidator({ max: 20 })],
            email: [new RequiredValidator(),new EmailValidator(), new LengthValidator({ max: 100 })]
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<ISupplierContact>, action: AnyAction): IDataModelState<ISupplierContact> {
        return state;
    }

    public loadSupplierContacts(): Promise<ISupplierContact[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<ISupplierContact | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }


    public loadBySupplierId(id: number): Promise<ISupplierContact[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural + '?filter=supplier_id:eq:' + id+'&status_id:eq:1')
            .getList({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<ISupplierContact | AxiosError> {

        let obj = this.asPlainObject();
        delete obj.id;
        return this.createDataSource(this.resourceName)
            .postOperation(obj);
    }

    public update(data: ISupplierContact): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + data.id)
            .plainPatchOperation(data);
    }



    getStoreKey(): string {
        return "SUPPLIER-CONTACT";
    }

    protected setFromObj(data: ISupplierContact): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._supplier_id = DataModel.safeGet(data.supplier_id, this._supplier_id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._job_title = DataModel.safeGet(data.job_title, this._job_title);
        this._phone = DataModel.safeGet(data.phone, this._phone);
        this._mobile_phone = DataModel.safeGet(data.mobile_phone, this._mobile_phone);
        this._email = DataModel.safeGet(data.email, this._email);
        this._sales_office = DataModel.safeGet(data.sales_office, this._sales_office);
    }


    protected toObj(): ISupplierContact {
        return {
            id: this._id,
            supplier_id: Number(this._supplier_id),
            job_title: this._job_title,
            name: this._name,
            phone: this._phone,
            mobile_phone: this._mobile_phone,
            email: this._email,
            sales_office: this._sales_office
        };
    }

    get id(): number {
        return this._id;
    }


    set supplier_id(id: number) {
        this._supplier_id = id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }

    get supplier_id() {
        return this._supplier_id;
    }

    get job_title() {
        return this._job_title;
    }

    get phone() {
        return this._phone;
    }

    get mobile_phone() {
        return this._mobile_phone;
    }

    get email() {
        return this._email;
    }

    get sales_office() {
        return this._sales_office;
    }

}
