import React, { Component } from 'react';
import { InputGroup, Col, Row, FormGroup, Label, CustomInput } from 'reactstrap';
import Select from 'react-select';
import { reactSelectStyle } from '../../../assets/css/reactSelectStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Path } from '../../container/Path';
import { Country } from '../../models/Country';
import { MaterialGradeCategory } from '../../models/MaterialGradeCategory';
import { ProductCategory } from '../../models/ProductCategory';
import { MillApproval } from '../../models/MillApproval';
import { AxiosResponse, AxiosError } from 'axios';
import { IQuery, Search as SearchModel } from '../../models/Search';
import Util from '../../custom/Util';
import { Certificate } from '../../models/Certificate';
import { NumberInput } from '../../../common/components/widgets/form/input/NumberInput';
import * as qs from 'query-string';

interface ISearchState {
  filters: boolean;
  selectedOption: any,
  isFilterSelected: boolean,
  countries: any,
  selectedCountryOption?: any,
  materials: any,
  selectedMaterialOption?: any,
  products: any,
  selectedPCOption?: any,
  certificates: any,
  selectedCertificateOption?: any,
  approvals: any,
  selectedApprovalOption?: any,
  amlClasses: any,
  selectedACOption?: any,
  query?: any,
  selectedVLAMLvalue?: boolean,
  queryObj?: IQuery,
  autocompleteResultSet?: any
}

class Search extends Component<{}, ISearchState> {

  private countries: Country = new Country();
  private materials: MaterialGradeCategory = new MaterialGradeCategory();
  private certificates: Certificate = new Certificate();
  private products: ProductCategory = new ProductCategory();
  private approvals: MillApproval = new MillApproval();
  private search: SearchModel = new SearchModel();


  constructor(props: any) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.autocomplete = this.autocomplete.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
    this.buildUrlParams = this.buildUrlParams.bind(this);

    this.state = {
      isFilterSelected: false,
      filters: false,
      selectedOption: null,
      countries: [],
      selectedCountryOption: null,
      materials: [],
      selectedMaterialOption: null,
      products: [],
      selectedPCOption: null,
      certificates: [],
      selectedCertificateOption: null,
      approvals: [],
      selectedApprovalOption: null,
      amlClasses: [
        {value: "A", label: "A"},
        {value: "B", label: "B"},
        {value: "C", label: "C"},
        {value: "D", label: "D"},
        {value: "E", label: "E"}
      ],
      selectedACOption: null,
      queryObj: {},
      query: {},
      selectedVLAMLvalue: true,
      autocompleteResultSet: null
    };
  }


  handleClick() {
    if (!this.state.filters) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      filters: !prevState.filters,
    }));
  }

  handleOutsideClick = (e: any) => {

    if (document.getElementById('search-filter-box') && document.getElementById('search-filter-box')!.contains(e.target) || this.state.isFilterSelected) {
      this.setState({
        isFilterSelected: false,
      })

      return
    }

    this.handleClick();
  }


  handleCountryChange = (selectedOption: any) => {
    if (selectedOption === null) {
      let queries = {...this.state.query};
      delete queries.country;
      this.setState({
        query: queries,
        isFilterSelected: false,
        selectedCountryOption: null,
      });
    } else {
      this.setState({
        selectedCountryOption: selectedOption,
        query: { ...this.state.query, country: selectedOption ? selectedOption.value : null },
        isFilterSelected: true,
      });
    }
  }

  handleMaterialChange = (selectedOption: any) => {
    if (selectedOption === null) {
      let queries = {...this.state.query};
      delete queries.material;
      this.setState({
        query: queries,
        isFilterSelected: false,
        selectedMaterialOption: null,
      });
    } else {
      this.setState({
        selectedMaterialOption: selectedOption,
        query: { ...this.state.query, material: selectedOption ? selectedOption.value : null },
        isFilterSelected: true,
      });
    }
  }

  handleProductCategoryChange = (selectedOption: any) => {
    if (selectedOption === null) {
      let queries = {...this.state.query};
      delete queries.product_category;
      this.setState({
        query: queries,
        isFilterSelected: false,
        selectedPCOption: null,
      });
    } else {
      this.setState({
        selectedPCOption: selectedOption,
        query: { ...this.state.query, product_category: selectedOption ? selectedOption.value : null },
        isFilterSelected: true,
      });
    }
  }

  handleCertificateChange = (selectedOption: any) => {
    if (selectedOption === null) {
      let queries = {...this.state.query};
      delete queries.certificate;
      this.setState({
        query: queries,
        isFilterSelected: false,
        selectedCertificateOption: null,
      });
    } else {
      this.setState({
        selectedCertificateOption: selectedOption,
        query: { ...this.state.query, certificate: selectedOption ? selectedOption.value : null },
        isFilterSelected: true,
      });
    }
  }

  handleApprovalChange = (selectedOption: any) => {
    if (selectedOption === null) {
      let queries = {...this.state.query};
      delete queries.approval;
      this.setState({
        query: queries,
        isFilterSelected: false,
        selectedApprovalOption: null,
      });
    } else {
      this.setState({
        selectedApprovalOption: selectedOption,
        query: { ...this.state.query, approval: selectedOption ? selectedOption.value : null },
        isFilterSelected: true,
      });
    }
  }

  handleACChange = (selectedOption: any) => {
    if (selectedOption === null) {
      let queries = {...this.state.query};
      delete queries.aml_class;
      this.setState({
        query: queries,
        isFilterSelected: false,
        selectedACOption: null,
      });
    } else {
      this.setState({
        selectedACOption: selectedOption,
        query: { ...this.state.query, aml_class: selectedOption ? selectedOption.value : null },
        isFilterSelected: true,
      });
    }
  }

  handleVLAMLChange = () => {
    this.setState({
      isFilterSelected: true,
      selectedVLAMLvalue: !this.state.selectedVLAMLvalue,
      query: { ...this.state.query, vl_aml: this.state.selectedVLAMLvalue },
    });
  }

  handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let target = event.target as HTMLInputElement;
    this.setState({
      isFilterSelected: true,
      query: { ...this.state.query, od_min: target.value },
    });
  }


  handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let target = event.target as HTMLInputElement;
    this.setState({
      isFilterSelected: true,
      query: { ...this.state.query, od_max: target.value },
    });
  }

  restartFilter() {
    return
  }

  onFocus(e: React.FormEvent<HTMLInputElement>) {
  }

  onBlur(e: React.FormEvent<HTMLInputElement>) {

    setTimeout(() => {
      document.getElementById("search-find").classList.remove("show-search-find");

    }, 200);
  }

  autocomplete(target: HTMLInputElement) {

    if (target.value.length > 2) {
      this.search.autocomplete(target.value).then((response: AxiosResponse | any) => {
        const aError: any = response as AxiosError;
        const resultSet: AxiosResponse = response as AxiosResponse;

        if (!aError.response) {
          this.setState({ autocompleteResultSet: resultSet.data });

          console.log(this.state.autocompleteResultSet.mill.length)
          if (this.state.autocompleteResultSet.mill.length > 0) {
            document.getElementById("search-find").classList.add("show-search-find");
          } else {
            document.getElementById("search-find").classList.remove("show-search-find");
          }
        }
      });
    }
  }


  onQueryChange(e: React.ChangeEvent<HTMLInputElement>) {
    let target = e.target as HTMLInputElement;

    this.autocomplete(target);

    this.setState({
      query: { ...this.state.query, query: target.value }
    })
  }

  componentDidMount() {

    //load countries
    this.countries.createDataSource(this.countries.resourceNamePlural + "?sort=name").getListPlain({})
      .then((response: AxiosResponse) => {

        var defaultValues = [];

        if (this.state.countries !== undefined) {

          defaultValues = response.data.filter(country => {
            return Array.isArray(this.state.countries)
              ? this.state.countries.indexOf(country.id) !== -1
              : this.state.countries === country.id;
          });

          if (defaultValues.length > 0) {

            defaultValues = defaultValues.map(country => {
              return { value: country.id, label: country.name, key: country.id }
            });
          }
        }

        this.setState({
          countries: response.data.map(country => {
            return { value: country.id, label: country.name, }
          }),
          selectedCountryOption: defaultValues
        });

      }).catch((error: AxiosError) => {
        Util.notification("error", "Countries could not be loaded ", 1500)
      });

    //load material

    this.materials.createDataSource(this.materials.resourceNamePlural + "?sort=name").getListPlain({})
      .then((response: AxiosResponse) => {

        var defaultValues = [];

        if (this.state.materials !== undefined) {

          defaultValues = response.data.filter(material => {
            return Array.isArray(this.state.materials)
              ? this.state.materials.indexOf(material.id) !== -1
              : this.state.countries === material.id;
          });

          if (defaultValues.length > 0) {

            defaultValues = defaultValues.map(material => {
              return { value: material.id, label: material.name, key: material.id }
            });
          }
        }

        this.setState({
          materials: response.data.map(material => {
            return { value: material.id, label: material.name, }
          }),
          selectedMaterialOption: defaultValues
        });

      }).catch((error: AxiosError) => {
        Util.notification("error", "Materials could not be loaded ", 1500)
      });

    //load products
    this.products.createDataSource(this.products.resourceNamePlural + "?sort=name").getListPlain({})
      .then((response: AxiosResponse) => {

        var defaultValues = [];

        if (this.state.products !== undefined) {

          defaultValues = response.data.filter(product => {
            return Array.isArray(this.state.products)
              ? this.state.products.indexOf(product.id) !== -1
              : this.state.products === product.id;
          });

          if (defaultValues.length > 0) {

            defaultValues = defaultValues.map(product => {
              return { value: product.id, label: product.name, key: product.id }
            });
          }
        }

        this.setState({
          products: response.data.map(product => {
            return { value: product.id, label: product.name, }
          }),
          selectedPCOption: defaultValues
        });

      }).catch((error: AxiosError) => {
        Util.notification("error", "Product categories could not be loaded ", 1500)
      });

    //load certificates
    this.certificates.createDataSource(this.certificates.resourceNamePlural + "?sort=name").getListPlain({})
      .then((response: AxiosResponse) => {

        var defaultValues = [];

        if (this.state.certificates !== undefined) {

          defaultValues = response.data.filter(item => {
            return Array.isArray(this.state.certificates)
              ? this.state.certificates.indexOf(item.id) !== -1
              : this.state.certificates === item.id;
          });

          if (defaultValues.length > 0) {

            defaultValues = defaultValues.map(item => {
              return { value: item.id, label: item.name, key: item.id }
            });
          }
        }

        this.setState({
          certificates: response.data.map(item => {
            return { value: item.id, label: item.name, }
          }),
          selectedCertificateOption: defaultValues
        });

      }).catch((error: AxiosError) => {
        Util.notification("error", "Product categories could not be loaded ", 1500)
      });


    //load approvals
    this.approvals.createDataSource(this.approvals.resourceNamePlural + "?sort=name").getListPlain({})
      .then((response: AxiosResponse) => {

        var defaultValues = [];

        if (this.state.approvals !== undefined) {

          defaultValues = response.data.filter(item => {
            return Array.isArray(this.state.approvals)
              ? this.state.approvals.indexOf(item.id) !== -1
              : this.state.approvals === item.id;
          });

          if (defaultValues.length > 0) {

            defaultValues = defaultValues.map(item => {
              return { value: item.id, label: item.name, key: item.id }
            });
          }
        }

        this.setState({
          approvals: response.data.map(item => {
            return { value: item.id, label: item.name, }
          }),
          selectedApprovalOption: defaultValues
        });

      }).catch((error: AxiosError) => {
        Util.notification("error", "Product categories could not be loaded ", 1500)
      });


    }

  buildUrlParams(query: any) {

    if (query !== {}) {
      return "?" + qs.stringify(query);
    } else return "";
  }

  render() {

    let autocompleteResults = this.state.autocompleteResultSet ? (
      <>
        {this.state.autocompleteResultSet["mill"].map(singleResult => (
          <li className="find" key={singleResult.mill_id} >
            <a className="millname" href={"/supplier/" + singleResult.supplier_id + "/mill/" + singleResult.mill_id}>
              {singleResult.mill}
            </a>
          </li>
        ))}
      </>
    ) : null;


    return (
      <>
        <Col lg="8" id="search-filter-box" className="d-flex align-items-center py-2">
          <InputGroup>
            <input type="text"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onQueryChange}
              placeholder="Mill search" aria-label="Search"
              className={`form-control header-search ${this.state.filters ? 'active' : ''}`}
            />
            <div className={`search-filter-button ${this.state.filters ? 'active' : ''}`} onClick={this.handleClick}>Add filters</div>
            <div className="input-group-append">
              <a href={Path.SEARCH + this.buildUrlParams(this.state.query)}

                color="header-search-button" id="basic-text"
                className={`input-group-text header-search-button ${this.state.filters ? 'active' : ''}`}>
                <i className="fas fa-search"></i>
                <FontAwesomeIcon icon={faSearch} />
              </a>
            </div>
          </InputGroup>

          <div id="search-find" className="search-find">
            <ul>
              {autocompleteResults}
            </ul>
          </div>

          <div className={`search-filters ${this.state.filters ? 'show-search-filters ' : ''}`}>

            <Row>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="e1">Material</Label>
                  <Select
                    isClearable
                    inputId="e1"
                    styles={reactSelectStyle}
                    value={this.state.selectedMaterialOption}
                    onChange={this.handleMaterialChange}
                    options={this.state.materials}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label for="e2">Product Category</Label>
                  <Select
                    isClearable
                    inputId="e2"
                    styles={reactSelectStyle}
                    value={this.state.selectedPCOption}
                    onChange={this.handleProductCategoryChange}
                    options={this.state.products}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label for="e3">Standards&certificates</Label>
                  <Select
                    isClearable
                    inputId="e3"
                    styles={reactSelectStyle}
                    value={this.state.selectedCertificateOption}
                    onChange={this.handleCertificateChange}
                    options={this.state.certificates}
                  />
                </FormGroup>
              </Col>

              <Col md="2">
                <FormGroup>
                  <NumberInput
                    label="OD min"
                    inputId="e4a"
                    styles={reactSelectStyle}
                    onChange={this.handleMinChange}
                  />
                </FormGroup>
              </Col>

              <Col md="2">
                <FormGroup>
                  <NumberInput
                    label="OD max"
                    inputId="e4b"
                    styles={reactSelectStyle}
                    onChange={this.handleMaxChange}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label for="e5">Approvals</Label>
                  <Select
                    isClearable
                    inputId="e5"
                    styles={reactSelectStyle}
                    value={this.state.selectedApprovalOption}
                    onChange={this.handleApprovalChange}
                    options={this.state.approvals}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label for="e7">Country</Label>
                  <Select
                    isClearable
                    inputId="e7"
                    styles={reactSelectStyle}
                    value={this.state.selectedCountryOption}
                    onChange={this.handleCountryChange}
                    options={this.state.countries}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label for="e6">VL AML</Label>
                  <CustomInput type="checkbox"

                    onChange={this.handleVLAMLChange}
                    id="e6" className="searchvlaml"
                    label="Yes" /> {/* Must have classname : searchvlaml */}
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label for="e9">AML Class</Label>
                  <Select
                    isClearable
                    inputId="e9"
                    styles={reactSelectStyle}
                    value={this.state.selectedACOption}
                    onChange={this.handleACChange}
                    options={this.state.amlClasses}
                  />
                </FormGroup>
              </Col>

            </Row>

          </div>
        </Col>
      </>
    );
  }
}

export default Search
