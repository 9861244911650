import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Container, Row, Col, FormGroup } from 'reactstrap';
import Util from '../custom/Util';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Identity from '../user/Identity';
import { DataProvider } from '../../common/model/DataProvider';
import { ICertificateIssuer, CertificateIssuer } from '../models/CertificateIssuer';
import { ICertificate, Certificate } from '../models/Certificate';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import { IDestroy } from '../../common/components/pages/IDestroy';
import DataTable from '../custom/DataTable';
import { DropdownInput } from '../../common/components/widgets/form/input/DropdownInput';
import { AxiosResponse, AxiosError } from 'axios';
import { ProductCategory, IProductCategory } from '../models/ProductCategory';
import { QualityStatus, IQualityStatus } from '../models/QualityStatus';
import { ColorInput } from '../../common/components/widgets/form/input/ColorInput';
import { MaterialDefaultGrade, IMaterialDefaultGrade } from '../models/MaterialDefaultGrade';
import { IMaterialGradeCategory, MaterialGradeCategory } from '../models/MaterialGradeCategory';

@withRouter(Path.GENERAL_SETTINGS.toString(), PageContainer)
@withSecurity([Role.ADMIN.toString()], Identity, Path.HOMEPAGE)
export default class GeneralSettingsPage extends VlbPage {

    private certificateIssuerModel: CertificateIssuer = new CertificateIssuer();
    private materialGradeCategoryModel: MaterialGradeCategory = new MaterialGradeCategory();

    private dataTableCI;
    private dataTableAC;
    private dataTableC;
    private dataTablePC;
    private dataTableQS;
    private dataTableMGC;
    private dataTableMDG;

    private materialDefaultGradeInputs: { [attr: string]: React.RefObject<any> } = {
        material_grade_category_id: React.createRef(),
        name: React.createRef(),
        abbreviation: React.createRef()
    };

    private materialGradeCategoryInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        abbreviation: React.createRef()
    };

    private qualityStatusInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        color_hex: React.createRef()
    };

    private certificateIssuerInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef()
    };

    private certificateInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        certificate_issuer_id: React.createRef()
    };

    private productCategoryInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        abbreviation: React.createRef()
    };

    constructor(props: any) {
        super(props);

        this.state = {
            modalDelete: false,
            currentModelDelete: null,
            modalAdd: false,
            currentModelAdd: null,

            typeoftable: "",

            certificateIssuerList: [],
            certificateIssuerListLoaded: false,

            materialGradeCategoryList: [],
            materialGradeCategoryListLoaded: false,
        };
    }

    pageTitle() {
        return "General settings" + super.pageTitle();
    }

    protected getDestroyableMembers(): IDestroy[] {
        return [];
    }

    componentDidMount() {

        this.certificateIssuerModel.getListPlain({})
            .then((response: AxiosResponse) => {

                let certificateIssuerList = [];

                response.data.map(certificateIssuer => {
                    return certificateIssuerList.push({ id: certificateIssuer.id, name: certificateIssuer.name });
                })

                this.setState({
                    certificateIssuerList,
                    certificateIssuerListLoaded: true
                });


            }).catch((e: AxiosError) => {
                Util.notification("error", "Certificate issuer cannot be loaded", 1500);
            });

        this.materialGradeCategoryModel.getListPlain({})
            .then((response: AxiosResponse) => {

                let materialGradeCategoryList = [];

                response.data.map(materialGradeCategory => {
                    return materialGradeCategoryList.push({ id: materialGradeCategory.id, name: materialGradeCategory.name });
                })

                this.setState({
                    materialGradeCategoryList,
                    materialGradeCategoryListLoaded: true
                });


            }).catch((e: AxiosError) => {
                Util.notification("error", "Material grade category cannot be loaded", 1500);
            });

        super.componentDidMount();
    }

    renderContent() {

        const certificateIssuerColumns: any[] = [
            new DataColumn('Name', 'name', 80, new BasicSorter(), null), new ActionColumn(null,
                (row: Object) => {
                    this.dataTableCI.delete(row)
                },
                (row: Object) => {
                    this.dataTableCI.edit(row)
                })
        ];

        const certificateColumns: any[] = [
            new DataColumn('Name', 'name', 45, new BasicSorter(), null),
            new DataColumn('Certificate issuer', 'certificate_issuer.name', 35, new BasicSorter(), null),
            new ActionColumn(null,
                (row: Object) => {
                    this.dataTableC.delete(row)
                },
                (row: Object) => {
                    this.dataTableC.edit(row)
                })
        ];

        const productCategoryColumns: any[] = [
            new DataColumn('Name', 'name', 60, new BasicSorter(), null),
            new DataColumn('Abbreviation', 'abbreviation', 20, new BasicSorter(), null),
            new ActionColumn(null,
                (row: Object) => {
                    this.dataTablePC.delete(row)
                },
                (row: Object) => {
                    this.dataTablePC.edit(row)
                })
        ];

        const qualityStatusColumns: any[] = [
            new DataColumn('Name', 'name', 50, new BasicSorter(), null),
            new DataColumn('Color hex', 'color_hex', 30, new BasicSorter(), null),
            new ActionColumn(null,
                (row: Object) => {
                    this.dataTableQS.delete(row)
                },
                (row: Object) => {
                    this.dataTableQS.edit(row)
                })
        ];

        const materialGradeCategoryColumns: any[] = [
            new DataColumn('Name', 'name', 50, new BasicSorter(), null),
            new DataColumn('Abbreviation', 'abbreviation', 30, new BasicSorter(), null),
            new ActionColumn(null,
                (row: Object) => {
                    this.dataTableMGC.delete(row)
                },
                (row: Object) => {
                    this.dataTableMGC.edit(row)
                })
        ];

        const materialDefaultGradeColumns: any[] = [
            new DataColumn('Grade category', 'material_grade_category.name', 40, new BasicSorter(), null),
            new DataColumn('Name', 'name', 40, new BasicSorter(), null),
            new ActionColumn(null,
                (row: Object) => {
                    this.dataTableMDG.delete(row)
                },
                (row: Object) => {
                    this.dataTableMDG.edit(row)
                })
        ];

        return (
            <>
                <Container fluid className="page-content" >

                    <div className="title-box">
                        <h1 className="page-title">General settings</h1>
                    </div>

                    <Row>
                        <Col xl="6">

                            <DataTable
                                onRef={ref => (this.dataTableCI = ref)}
                                model={new CertificateIssuer(false)}
                                dataProvider={new DataProvider<ICertificateIssuer>()}
                                titleAttr={"name"}
                                loader={[2, 4]}
                                columns={certificateIssuerColumns}
                                title="Certificate issuer"
                                inputs={this.certificateIssuerInputs}
                                add={true}
                                formElements={
                                    <FormGroup>
                                        <TextInput
                                            label="Name"
                                            id="ci_name"
                                            placeholder="Certificate issuer name"
                                            ref={this.certificateIssuerInputs.name}
                                        />
                                    </FormGroup>
                                }
                            />

                        </Col>

                        <Col xl="6">
                            <DataTable
                                onRef={ref => (this.dataTableC = ref)}
                                model={new Certificate(false)}
                                dataProvider={new DataProvider<ICertificate>()}
                                titleAttr={"name"}
                                loader={[3, 6]}
                                columns={certificateColumns}
                                title="Certificate"
                                inputs={this.certificateInputs}
                                add={true}
                                formElements={
                                    <>
                                        <FormGroup>
                                            <TextInput
                                                label="Name"
                                                id="c_name"
                                                placeholder="Certificate name"
                                                ref={this.certificateInputs.name}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <DropdownInput
                                                listItems={this.state.certificateIssuerList}
                                                label="Certificate issuer"
                                                id="c_issuer"
                                                placeholder="Select certificate issuer"
                                                ref={this.certificateInputs.certificate_issuer_id}
                                            />
                                        </FormGroup>
                                    </>
                                }
                            />
                        </Col>

                        <Col xl="6">
                            <DataTable
                                onRef={ref => (this.dataTablePC = ref)}
                                model={new ProductCategory(false)}
                                dataProvider={new DataProvider<IProductCategory>()}
                                titleAttr={"name"}
                                loader={[3, 6]}
                                columns={productCategoryColumns}
                                title="Product category"
                                inputs={this.productCategoryInputs}
                                add={true}
                                formElements={
                                    <>
                                        <FormGroup>
                                            <TextInput
                                                label="Name"
                                                id="pc_name"
                                                placeholder="Product category name"
                                                ref={this.productCategoryInputs.name}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <TextInput
                                                label="Abbreviation"
                                                id="pc_abbreviation"
                                                placeholder="Product category abbreviation"
                                                ref={this.productCategoryInputs.abbreviation}
                                            />
                                        </FormGroup>
                                    </>
                                }
                            />
                        </Col>

                        <Col xl="6">
                            <DataTable
                                onRef={ref => (this.dataTableQS = ref)}
                                model={new QualityStatus(false)}
                                dataProvider={new DataProvider<IQualityStatus>()}
                                titleAttr={"name"}
                                loader={[3, 3]}
                                columns={qualityStatusColumns}
                                title="Quality status"
                                inputs={this.qualityStatusInputs}
                                add={true}
                                formElements={
                                    <>
                                        <FormGroup>
                                            <TextInput
                                                label="Name"
                                                id="qs_name"
                                                placeholder="Quality status name"
                                                ref={this.qualityStatusInputs.name}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <ColorInput
                                                label="Color hex"
                                                id="qs_color_hex"
                                                placeholder="Quality status color hex"
                                                ref={this.qualityStatusInputs.color_hex}
                                            />
                                        </FormGroup>
                                    </>
                                }
                            />
                        </Col>

                        <Col xl="6">
                            <DataTable
                                onRef={ref => (this.dataTableMGC = ref)}
                                model={new MaterialGradeCategory(false)}
                                dataProvider={new DataProvider<IMaterialGradeCategory>()}
                                titleAttr={"name"}
                                loader={[3, 6]}
                                columns={materialGradeCategoryColumns}
                                title="Material grade category"
                                inputs={this.materialGradeCategoryInputs}
                                add={true}
                                formElements={
                                    <>
                                        <FormGroup>
                                            <TextInput
                                                label="Name"
                                                id="mgc_name"
                                                placeholder="Material grade category name"
                                                ref={this.materialGradeCategoryInputs.name}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <TextInput
                                                label="Abbreviation"
                                                id="mgc_abbreviation"
                                                placeholder="Material grade category abbreviation"
                                                ref={this.materialGradeCategoryInputs.abbreviation}
                                            />
                                        </FormGroup>
                                    </>
                                }
                            />
                        </Col>

                        <Col xl="6">
                            <DataTable
                                onRef={ref => (this.dataTableMDG = ref)}
                                model={new MaterialDefaultGrade(false)}
                                dataProvider={new DataProvider<IMaterialDefaultGrade>()}
                                titleAttr={"name"}
                                loader={[3, 6]}
                                columns={materialDefaultGradeColumns}
                                title="Material default grade"
                                inputs={this.materialDefaultGradeInputs}
                                add={true}
                                formElements={
                                    <>
                                        <FormGroup>
                                            <TextInput
                                                label="Grade category"
                                                id="mdg_name"
                                                placeholder="Grade category name"
                                                ref={this.materialDefaultGradeInputs.name}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <DropdownInput
                                                listItems={this.state.materialGradeCategoryList}
                                                label="Material grade category"
                                                id="mdg_material_grade_category"
                                                placeholder="Select material grade category"
                                                ref={this.materialDefaultGradeInputs.material_grade_category_id}
                                            />
                                        </FormGroup>
                                    </>
                                }
                            />
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}
