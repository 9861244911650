import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Container, FormGroup, Button, Modal, ModalHeader, ModalBody, Form, ModalFooter} from 'reactstrap';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import Util from '../custom/Util';
import { User, IUser } from '../models/User';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import { DataProvider } from '../../common/model/DataProvider';
import { Role } from '../user/Role';
import { DropdownInput } from '../../common/components/widgets/form/input/DropdownInput';
import { PasswordInput } from '../../common/components/widgets/form/input/PasswordInput';
import { BasicFilter } from '../../common/components/widgets/table/filter/BasicFilter';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { AxiosError, AxiosResponse } from 'axios';
import { BasicPaging } from '../../common/components/widgets/table/paging/BasicPaging';
import { EmailInput } from '../../common/components/widgets/form/input/EmailInput';
import Identity from '../user/Identity';
import { withSecurity } from '../../common/security/Security';
import { TablePage } from '../../common/components/widgets/table/TablePage';


@withRouter(Path.USERS.toString(), PageContainer)
@withSecurity([Role.USER, Role.ADMIN, Role.CATEGORY_MANAGER], Identity, Path.LOGIN)
export default class UsersPage extends VlbPage {

    private submitted = false;
    private modalTitle = "Add new";
    private model = new User(false);
    private dataProvider = new DataProvider<IUser>();

    private usersInputs: { [attr: string]: React.RefObject<any> } = {
        username: React.createRef(),
        email: React.createRef(),
        full_name: React.createRef(),
        item_name: React.createRef(),
        abbreviation: React.createRef(),
        password: React.createRef(),
    };

    constructor(props: any) {
        super(props);

        this.state = {
            modalDelete: false,
            currentModelDelete: {},
            modalFormOpen: false,
            modalEdit: false,
            reload: false,
            isCreate: true,
        };
    }

    pageTitle() {
        return "Users" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
        ];
    }

    delete(row?: any) {
        this.setState(prevState => ({
            modalDelete: !prevState.modalDelete,
            currentModelDelete: row
        }));
    }

    add() {

        this.modalTitle = "Add new ";
        this.model.changeScenario(User.SCENARIO.CREATE);

        this.setState(prevState => ({
            modalFormOpen: !prevState.modalFormOpen,
            isCreate: true
        }));
    }

    edit(row?: any) {

        this.modalTitle = "Edit ";
        this.model.changeScenario(User.SCENARIO.UPDATE);

        this.model.loadById(row.id)
            .then((response: any) => {
                this.model.setFromPlainObject(response);
                this.setInputValues(response);

            })
            .catch((error: AxiosError) => {
                Util.notification("error", "Data could not be loaded", 1500);
            });

        this.setState(prevState => ({
            isCreate: false,
            modalFormOpen: !prevState.modalFormOpen
        }));
    }


    setInputValues(values: any) {

        let inputs = this.usersInputs;

        for (let key in values) {
            if (inputs[key] && inputs[key].current) {
                inputs[key].current.setValue(values[key]);
            }
        }
    }

    protected handleSubmit(event: React.MouseEvent<HTMLElement>): void {
        let promise: Promise<AxiosResponse>;
        this.submitted = true;

        if (this.validate(event)) {

            if (this.state.isCreate) {
                promise = this.model.createNew();
            } else {
                promise = this.model.update(this.model);
            }

            promise.then((response: AxiosResponse) => {

                Util.notification("success", "Success.", 2000);
                this.setState({ reload: true, modalFormOpen: false, modalEdit: false })

            })
                .catch((error: any) => {
                    const aError: any = error;

                    switch (aError.response.status) {
                        case 422:

                            if (aError.response && aError.response.data && aError.response.data.errors) {
                                const respErrors: any[] = aError.response.data.errors;
                                const errors: { [attr: string]: string } = {};

                                respErrors.forEach((error: any) => {
                                    errors[error.fieldName] = error.errorMessage;
                                });

                                this.fillInputsWithErrors(errors, this.usersInputs);
                            }
                            break;
                        default:

                            Util.notification("error", "Error.", 2000);
                            break;
                    }

                    this.setState({ reload: true })
                });
        }
    }


    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key) && inputs[key] != undefined && inputs[key].current) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    private validate(event: any): boolean {
        this.readInputs();

        let inputs = this.usersInputs;
        const valid = this.model.validate();

        if (this.submitted && !valid) {
            const errors: { [attr: string]: string } = this.model.getErrors();
            console.log(errors, 'errori')
            this.fillInputsWithErrors(errors, inputs);
        }

        event.preventDefault();

        return valid;
    }

    private readInputs(): any {

        const plainObject = {};
        const inputs = this.usersInputs;

        for (const key in inputs) {
            if (inputs.hasOwnProperty(key) && inputs[key].current) {
                plainObject[key] = inputs[key].current.getValue();
                inputs[key].current.removeError();
            }
        }

        this.model.setFromPlainObject({ ...this.model.asPlainObject(), ...plainObject });

        return plainObject;
    }

    renderContent() {

        let rolesList = Object.values(Role).map((single) => (
            { id: single, name: single }
        ));
        const removeElement = rolesList.shift()

        let roleFilter: any = Role
        delete roleFilter.GUEST;

        const usersColumns: any[] = [
            new DataColumn('Username', 'username', 20, new BasicSorter(), new BasicFilter(false, 'Search user', 4)), //isnumber, placeholder, colsize
            new DataColumn('Email', 'email', 25, new BasicSorter(), new BasicFilter(false, 'Search email', 5)),
            new DataColumn('Full name', 'full_name', 20, new BasicSorter()),
            new DataColumn('Role', 'item_name', 15, new BasicSorter()),
            new DataColumn('Abbreviation', 'abbreviation', 15, new BasicSorter(), new BasicFilter(false, 'Search abbreviation', 3)),
        ];

        if (Identity.role === Role.ADMIN.toLowerCase()) {
            usersColumns.push(
                new ActionColumn(null,
                    (row: Object) => {
                        this.delete(row)
                    },
                    (row: Object) => {
                        this.edit(row)
                    })
            )
        }

        const paging = new BasicPaging({
            pageSize: 15
        });

        let deleteModal;
        deleteModal = (
            <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.delete}>
                <ModalHeader toggle={this.delete}>{this.state.currentModelDelete["username"]}</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this?
                          </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalDelete: !this.state.modalDelete })}>Cancel</Button>
                    <Button outline color="primary" onClick={() =>
                        (this.model).del(this.state.currentModelDelete["id"]).then(() => {
                            this.setState(prevState => ({
                                modalDelete: !prevState.modalDelete,
                            }));
                        })

                    }>Delete</Button>
                </ModalFooter>
            </Modal>
        )

        let modalForm;
        modalForm = (
            <>
                <ModalHeader toggle={() => this.add()}>{this.modalTitle} user</ModalHeader>
                <ModalBody>
                    <Form>
                        {
                            <>
                                <FormGroup>
                                    <TextInput
                                        label="Username"
                                        id="u_username"
                                        placeholder="Username"
                                        ref={this.usersInputs.username}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <EmailInput
                                        label="Email"
                                        id="u_email"
                                        placeholder="Email"
                                        ref={this.usersInputs.email}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextInput
                                        label="Full name"
                                        id="u_fullname"
                                        placeholder="Full name"
                                        ref={this.usersInputs.full_name}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <DropdownInput
                                        listItems={rolesList}
                                        label="Role"
                                        id="u_role"
                                        placeholder="Role"
                                        ref={this.usersInputs.item_name}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextInput
                                        label="Abbreviation"
                                        id="u_abbreviation"
                                        placeholder="Abbreviation"
                                        ref={this.usersInputs.abbreviation}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <PasswordInput
                                        label="Password"
                                        id="u_password"
                                        placeholder="Password"
                                        ref={this.usersInputs.password}
                                    />
                                </FormGroup>
                            </>
                        }
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalFormOpen: !this.state.modalFormOpen })}>Cancel</Button>
                    <Button outline color="primary" onClick={this.handleSubmit.bind(this)}>{this.state.isCreate ? "Add" : "Update"}</Button>
                </ModalFooter>
            </>
        )

        let modal;
        modal = (
            <Modal centered size="md" isOpen={this.state.modalFormOpen} fade={false} toggle={() => this.add()}>
                {modalForm}
            </Modal>
        )

        let add;
        if (Role.ADMIN.toString() === Identity.role) {
            add = <div className="add-new"><Button color="primary" onClick={() => this.add()}><FontAwesomeIcon icon={faPlus} /> Add user</Button></div>
        }

        return (
            <Container fluid className="page-content userspage" >


                <div className="title-box">
                    <h1 className="page-title">Users</h1>
                    {add}
                </div>

                <div className="element-box">

                    <ReduxComponentWrapper component={TablePage} componentPropsCallback={(state: any) => ({
                        provider: this.dataProvider,
                        loader: [6, 15],
                        searchCallback: (params: IRestDataSourceParams): void => {
                            this.dataProvider.refreshWithHeaders(
                                this.model.getListPlain({...params, sort : {
                                    attr: "users.created_at",
                                    ascending: false
                                }})
                            )
                        },
                        columns: usersColumns,
                        paging: paging
                    })} />


                    {modal}
                    {deleteModal}

                </div>
            </Container>

        );
    }
}
