import * as React from 'react';
import { DataProvider, IDataProviderListInfo } from "../../../../model/DataProvider";
import { Col } from 'reactstrap';

export interface IBasicPagingParams {
    pageSize: number,
}

export class BasicPaging {
    public pageNumber: number = 1;
    public pageSize: number = 10;

    protected provider: DataProvider<any> = new DataProvider<any>();
    private reloadData: () => void = () => { };

    public constructor(params: IBasicPagingParams) {
        this.pageSize = params.pageSize;
        this.pageNumber = 1;
    }

    public addReload(cb: () => void): void {
        this.reloadData = cb;
    }

    public addProvider(provider: DataProvider<any>) {
        this.provider = provider;
    }

    public reset(): void {
        this.pageNumber = 1;
    }

    private pageUp(event: MouseEvent): void {
        this.pageNumber++;
        this.reloadData();

        event.preventDefault();
    }

    private pageDown(event: MouseEvent): void {
        if (this.pageNumber > 1) {
            this.pageNumber--;
            this.reloadData();
        }

        event.preventDefault();
    }

    private pageN(pageNumber: number, event: MouseEvent): void {
        this.pageNumber = pageNumber;
        this.reloadData();

        event.preventDefault();
    }

    private pageLast(event: MouseEvent): void {
        if (this.provider.listInfo) {
            const listInfo: IDataProviderListInfo = this.provider.listInfo;
            this.pageNumber = listInfo.pageMax;
        }

        this.reloadData();

        event.preventDefault();
    }

    private isFirstPage(): boolean {
        return this.pageNumber === 1;
    }

    private isLastPage(): boolean {
        const listInfo: IDataProviderListInfo | undefined = this.provider.listInfo;

        let result: boolean = false;
        if (listInfo) {
            result = listInfo.pageMax === this.pageNumber;
        } else {
            result = this.provider.items.length !== this.pageSize;
        }

        return result;
    }

    protected createPaginationButton(label: string | number, isDisabled: boolean,
        clickHandle: (e: any) => void): React.ReactNode {
        return <li key={label} className={"page-item" + ((isDisabled) ? " disabled" : "")}>
            <a className="page-link" href="#" onClick={clickHandle}>{label}</a></li>
    }

    protected createSummaryLine(beginIndex: number, endIndex: number, listInfo: IDataProviderListInfo): React.ReactNode {
        return (<Col xl="6" className="summaryline" style={{ textAlign: 'right'}}>
            Displaying <b>{beginIndex} - {endIndex}</b> <i>({this.provider.items.length})</i> entries out
                of {listInfo.itemsCount}
        </Col>);
    }

    public render(): React.ReactNode {

        let summary: React.ReactNode = (<div />);

        const paginationButtons: React.ReactNode[] = [
            this.createPaginationButton('Previous', this.isFirstPage(), this.pageDown.bind(this))
        ];

        if (this.provider.listInfo) {
            const listInfo: IDataProviderListInfo = this.provider.listInfo;

            let beginIndex: number = (listInfo.pageNumber - 1) * listInfo.pageSize + 1;
            const lastTableVisibleIndex: number = (listInfo.pageNumber * listInfo.pageSize);

            const endIndex: number = (lastTableVisibleIndex < listInfo.itemsCount) ?
                lastTableVisibleIndex : listInfo.itemsCount;

            if (beginIndex > listInfo.itemsCount) {
                beginIndex = listInfo.itemsCount;
            }

            summary = this.createSummaryLine(beginIndex, endIndex, listInfo);

            const pagesCount: number = listInfo.pageMax;

            paginationButtons.push(this.createPaginationButton("First", this.isFirstPage(), this.pageN.bind(this, 1)));

            for (let pageNumber = this.pageNumber - 2; pageNumber <= this.pageNumber + 2; pageNumber++) {
                if (pageNumber > 0 && pageNumber <= pagesCount) {
                    const isCurrentPage: boolean = pageNumber === this.pageNumber;
                    paginationButtons.push(
                        this.createPaginationButton(pageNumber, isCurrentPage, this.pageN.bind(this, pageNumber))
                    );
                }
            }

            paginationButtons.push(this.createPaginationButton("Last", this.isLastPage(), this.pageLast.bind(this)));
        }

        paginationButtons.push(this.createPaginationButton("Next", this.isLastPage(), this.pageUp.bind(this)));

        const pagination: React.ReactNode = (
            <Col xl="6" className="pagination-col">
                <ul className="pagination">
                    {paginationButtons}
                </ul>
            </Col>);

        return (<div className="row pagsum">
            {pagination}
            {summary}
        </div>);
    }
}
