import React, { Fragment } from 'react';
import VlbPage from './VlbPage';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Container, FormGroup, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Spinner } from 'reactstrap';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import Util from '../custom/Util';
import { Supplier } from '../models/Supplier';
import { DataProvider } from '../../common/model/DataProvider';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import { AxiosResponse, AxiosError } from 'axios';
import { DropdownInput } from '../../common/components/widgets/form/input/DropdownInput';
import { BasicFilter } from '../../common/components/widgets/table/filter/BasicFilter';
import { DropdownFilter } from '../../common/components/widgets/table/filter/DropdownFilter';
import { Role } from '../user/Role';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { TablePage } from '../../common/components/widgets/table/TablePage';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import { BasicPaging } from '../../common/components/widgets/table/paging/BasicPaging';
import Identity from '../user/Identity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faFile } from '@fortawesome/free-solid-svg-icons';
import { News, INews } from '../models/News';
import { CustomActionColumn } from '../custom/CustomActionColumn';
import { File as FileModel } from '../models/File';
import { withSecurity } from '../../common/security/Security';


@withRouter(Path.NEWS.toString(), PageContainer)
@withSecurity([Role.USER, Role.ADMIN, Role.CATEGORY_MANAGER], Identity, Path.LOGIN)
export default class SuppliersPage extends VlbPage {
    private submitted = false;
    private modalTitle = "Add new";

    private supplierModel: Supplier = new Supplier();
    private model: News = new News(false);
    private fileModel: FileModel = new FileModel();

    private dataProvider = new DataProvider<INews>();

    private newsInputs: { [attr: string]: React.RefObject<any> } = {
        title: React.createRef(),
        attachment: React.createRef(),
        supplier_id: React.createRef(),
        created_by: React.createRef(),
    };

    constructor(props: any) {
        super(props);

        this.delete = this.delete.bind(this);
        this.file = this.file.bind(this);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.readInputs = this.readInputs.bind(this);
        this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setInputValues = this.setInputValues.bind(this);
        this.addFile = this.addFile.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.searchCallback = this.searchCallback.bind(this);

        this.state = {
            supplierList: null,
            usersList: null,
            titleAttr: "title",
            singularTitle: "news",
            addPrivileges: [Role.CATEGORY_MANAGER, Role.USER],
            loader: [6, 15],

            modalDelete: false,
            currentModelDelete: {},
            modalFormOpen: false,
            modalEdit: false,
            reload: false,
            isCreate: true,

            modalFile: false,
            currentModelFile: {},

            files: [],
            fileUrl: null,

            modalFileDelete: false,
            modalFileDeleteRef: "",
            modalFileDeleteEvent: null,

            waitForUpload: false,
        };
    }

    pageTitle() {
        return "News" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
        ];
    }

    delete(row?: any) {
        this.setState(prevState => ({
            modalDelete: !prevState.modalDelete,
            currentModelDelete: row
        }));
    }

    fileDelete(ref: React.RefObject<any>, e: React.MouseEvent<HTMLElement>) {
        const inputElement: HTMLInputElement = document.getElementById(ref.current.props.id) as HTMLInputElement

        if (inputElement.value === "") {
            Util.notification("info", "No file to delete", 1500);
        } else {
            this.setState(prevState => ({
                modalFileDelete: !prevState.modalFileDelete,
                modalFileDeleteRef: ref,
                modalFileDeleteEvent: e,
            }));
        }
    }

    file(row?: any) {
        if (row.attachment) {
            this.setState(prevState => ({
                modalFile: !prevState.modalFile,
                currentModelFile: row
            }));
        } else {
            Util.notification("info", "No attachment", 1500)
        }

    }

    add() {

        this.modalTitle = "Add new ";
        this.model.changeScenario(this.state.scenarios ? this.state.scenarios.create : "default");

        this.setState(prevState => ({
            modalFormOpen: !prevState.modalFormOpen,
            isCreate: true
        }));
    }

    edit(row?: any) {
        this.modalTitle = "Edit ";
        this.model.changeScenario(this.state.scenarios ? this.state.scenarios.update : "default");

        this.model.loadById(row.id)
            .then((response: any) => {
                this.model.setFromPlainObject(response);
                this.setInputValues(response);
            })
            .catch((error: AxiosError) => {
                Util.notification("error", "Data could not be loaded", 1500);
            });

        this.setState(prevState => ({
            isCreate: false,
            modalFormOpen: !prevState.modalFormOpen
        }));
    }

    setInputValues(values: any) {
        let inputs = this.newsInputs;

        for (let key in values) {
            if (inputs[key] && inputs[key].current) {
                inputs[key].current.setValue(values[key]);
            }
        }
    }

    protected handleSubmit(event: React.MouseEvent<HTMLElement>): void {

        this.submitted = true;

        if (this.validate(event)) {
            if (this.state.files.length > 0) {

                this.uploadFilesToServer();
                this.setState({
                    waitForUpload: true
                })

                setTimeout(() => {

                    this.model.attachment = this.state.fileUrl;

                    this.sendReq()
                }, 1000);
            } else {
                this.sendReq()
            }
        }
    }

    sendReq() {
        let promise: Promise<AxiosResponse>;
        if (this.state.isCreate) {
            promise = this.model.createNew();
        } else {
            promise = this.model.update(this.model);
        }

        promise.then((response: AxiosResponse) => {

            this.setState({
                waitForUpload: false
            })

            Util.notification("success", "Success.", 2000);
            this.setState({ reload: true, modalFormOpen: false, modalEdit: false })

        })
            .catch((error: any) => {
                const aError: any = error;

                switch (aError.response.status) {
                    case 422:

                        if (aError.response && aError.response.data && aError.response.data.errors) {
                            const respErrors: any[] = aError.response.data.errors;
                            const errors: { [attr: string]: string } = {};

                            respErrors.forEach((error: any) => {
                                errors[error.fieldName] = error.errorMessage;
                            });

                            this.fillInputsWithErrors(errors, this.newsInputs);
                        }
                        break;
                    default:

                        Util.notification("error", "Error.", 2000);
                        break;
                }

                this.setState({ reload: true, waitForUpload: false })
            });
    }


    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key) && inputs[key] !== undefined && inputs[key].current) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    private validate(event: any): boolean {
        this.readInputs();

        let inputs = this.newsInputs;
        const valid = this.model.validate();

        if (this.submitted && !valid) {
            const errors: { [attr: string]: string } = this.model.getErrors();
            this.fillInputsWithErrors(errors, inputs);
        }

        event.preventDefault();

        return valid;
    }

    private readInputs(): any {

        const plainObject = {};
        const inputs = this.newsInputs;

        for (const key in inputs) {
            if (inputs.hasOwnProperty(key) && inputs[key].current) {
                plainObject[key] = inputs[key].current.getValue();
                inputs[key].current.removeError();
            }
        }

        this.model.setFromPlainObject({ ...this.model.asPlainObject(), ...plainObject });

        return plainObject;
    }

    componentDidMount() {
        this.supplierModel.getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    supplierList: response.data,
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Country list cannot be loaded", 1500);
            });

        super.componentDidMount();
    }


    uploadFilesToServer() {
        let data = new FormData();

        //Append files to form data
        let files = this.state.files;

        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                data.append('files', files[i], files[i].name);
            }

            this.fileModel.createNew(data).then((response: AxiosResponse) => {
                let files: string[] = response.data;
                this.setState({
                    fileUrl: files[0]
                })

            })
        }
    }


    addFile = (ref: React.RefObject<any>, event) => {

        let newFilesList = [];
        let files = event.target.files;

        for (var i = 0; i < files.length; i++) {
            let fileGood = true;
            var regex = new RegExp("(.*?)\.(pdf)$");

            if (!(regex.test(files[i].type))) {
                Util.notification("error", "Document format is not valid. Allowed: pdf", 10000);
                fileGood = false;
            }

            if (fileGood) {
                newFilesList.push(files[i]);
                ref.current.setValue(files[i].name)
            }
        }


        newFilesList.concat(...this.state.files)
        this.setState({ files: newFilesList })

    }

    removeFile(ref: React.RefObject<any>, e) {

        const inputElement: HTMLInputElement = document.getElementById(ref.current.props.id) as HTMLInputElement

        inputElement.value = ''

        ref.current.setValue(null);
        this.model.attachment = null;

        this.model.update(this.model).then((response: AxiosResponse) => {
            Util.notification("success", "News successfully updated.", 10000);
            this.setState({
                modalFileDelete: false,
                reload: true,
                modalFormOpen: false,
                modalEdit: false
            })
        });
    }

    searchCallback() {
        if (!this.state.modalEdit) {
            return (params: IRestDataSourceParams): void => {
                this.dataProvider.refreshWithHeaders(
                    this.model.getListPlain({
                        ...params,
                        sort: {
                            attr: "created_at",
                            ascending: false
                        }
                    })
                )
            }
        }
    }

    renderContent() {

        let supplierFilterList = {};
        if (this.state.supplierList) {
            for (let i = 0; i < this.state.supplierList.length; i++) {
                supplierFilterList[this.state.supplierList[i].id] = this.state.supplierList[i].name;
            }
        }

        let supplierList = [];
        if (this.state.supplierList) {
            for (let i = 0; i < this.state.supplierList.length; i++) {
                supplierList.push({
                    id: this.state.supplierList[i].id,
                    name: this.state.supplierList[i].name,
                });
            }
            supplierList.sort((a, b) => a.name.localeCompare(b.name));
        }

        const paging = new BasicPaging({
            pageSize: 15
        });

        let formElements = (
            <>
                <FormGroup>
                    <TextInput
                        label="Title"
                        id="n_title"
                        placeholder="Title"
                        ref={this.newsInputs.title}
                    />
                </FormGroup>
                <FormGroup>
                    <DropdownInput
                        listItems={supplierList}
                        label="Supplier"
                        id="n_supplier"
                        placeholder="Supplier"
                        ref={this.newsInputs.supplier_id}
                    />
                </FormGroup>
                <Row>
                    <Col>
                        <FormGroup>
                            <TextInput
                                label="Attachment"
                                id="attachment"
                                placeholder="Attachment"
                                ref={this.newsInputs.attachment}
                                disabled
                                class="upload-text-input"
                            />

                            <label className="uploadButton" onChange={(e) => this.addFile(this.newsInputs.attachment, e)}>
                                <input type="file" accept="application/pdf" />
                                <FontAwesomeIcon icon={faPlus} />
                            </label>

                            <label className="deleteButton" onClick={(e) => this.fileDelete(this.newsInputs.attachment, e)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </label>
                        </FormGroup>
                    </Col>
                </Row>
            </>

        )

        let deleteModal;
        deleteModal = (
            <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.delete}>
                <ModalHeader toggle={this.delete}>{this.state.currentModelDelete[this.state.titleAttr]}</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this?
                    </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalDelete: !this.state.modalDelete })}>Cancel</Button>
                    <Button outline color="primary" onClick={() =>
                        (this.model).del(this.state.currentModelDelete["id"]).then(() => {
                            this.setState(prevState => ({
                                modalDelete: !prevState.modalDelete,
                            }));
                        })

                    }>Delete</Button>
                </ModalFooter>
            </Modal>
        )

        let fileDeleteModal;
        fileDeleteModal = (
            <Modal centered size="md" isOpen={this.state.modalFileDelete} fade={false} toggle={() => this.fileDelete}>
                <ModalHeader toggle={() => this.setState({ modalFileDelete: !this.state.modalFileDelete })}>Delete file</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this?
                    </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalFileDelete: !this.state.modalFileDelete })}>Cancel</Button>
                    <Button outline color="primary" onClick={(e) => this.removeFile(this.state.modalFileDeleteRef, this.state.modalFileDeleteEvent)}>Delete</Button>
                </ModalFooter>
            </Modal>
        )

        let pdfFile;
        if (this.state.currentModelFile.attachment) {
            pdfFile = <embed src={this.state.currentModelFile.attachment} type="application/pdf" />
        } else {
            pdfFile = this.state.currentModelFile[this.state.titleAttr] + " missing pdf file"
        }

        let fileModal;
        fileModal = (
            <Modal centered size="xl" isOpen={this.state.modalFile} fade={false} toggle={() => this.setState({ modalFile: !this.state.modalFile })}>
                <ModalHeader toggle={() => this.setState({ modalFile: !this.state.modalFile })}>{this.state.currentModelFile[this.state.titleAttr]}</ModalHeader>
                <ModalBody>
                    {pdfFile}
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalFile: !this.state.modalFile })}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )

        let submitButton;
        if (!this.state.waitForUpload) {
            submitButton = <Button outline color="primary" onClick={this.handleSubmit}> {this.state.isCreate ? "Add" : "Update"}</Button>
        } else {
            submitButton = <Button outline color="primary" disabled><Spinner color="secondary" size="sm" className="sppiner-button" /> {this.state.isCreate ? "Add" : "Update"}</Button>
        }

        let modalForm;
        modalForm = (
            <>
                <ModalHeader toggle={() => this.add()}>{this.modalTitle} {this.state.singularTitle}</ModalHeader>
                <ModalBody>
                    <Form>
                        {formElements}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalFormOpen: !this.state.modalFormOpen })}>Cancel</Button>
                    {submitButton}
                </ModalFooter>
            </>
        )

        let modal;
        modal = (
            <Modal centered size="md" isOpen={this.state.modalFormOpen} fade={false} toggle={() => this.add()}>
                {modalForm}
            </Modal>
        )

        let add;
        if (this.state.addPrivileges.indexOf(Identity.role) !== -1) {
            add = <div className="add-new"><Button color="primary" onClick={() => this.add()}><FontAwesomeIcon icon={faPlus} /> Add {this.state.singularTitle}</Button></div>
        }


        const newsColumns: any[] = [
            new DataColumn('Date', 'published_at', 25, new BasicSorter()),
            new DataColumn('Supplier', 'supplier_id', 20, new BasicSorter(), new DropdownFilter(supplierFilterList, 'All Suppliers', 4)),
            new DataColumn('Supplier', 'supplier.name', 20, new BasicSorter()),
            new DataColumn('User', 'user.username', 20, new BasicSorter()),
            new DataColumn('Title', 'title', 25, new BasicSorter(), new BasicFilter(false, 'Search title', 8)),
            new ActionColumn("", null, null, 10,
                [{
                    operationCb: (row: any) => {
                        this.file(row)
                    },
                    iconClassName: "table-edit",
                    message: null,
                    icon: <FontAwesomeIcon icon={faFile} />,
                    iconStyle: {}
                }],
                "File"
            ),
            new CustomActionColumn(
                null,
                (row: Object) => {
                    this.delete(row)
                },
                (row: Object) => {
                    this.edit(row)
                },
                20,
                true
            )
        ];


        return (
            <>
                <Container fluid className="page-content newspage" >
                    <Fragment>
                        <div className="title-box">
                            <h1 className="page-title">News</h1>
                            {add}
                        </div>

                        <div className="element-box">

                            <ReduxComponentWrapper component={TablePage} componentPropsCallback={(state: any) => ({
                                provider: this.dataProvider,
                                loader: this.state.loader,
                                searchCallback: this.searchCallback(),
                                columns: newsColumns,
                                paging: paging,
                            })} />




                            {modal}
                            {deleteModal}
                            {fileModal}
                            {fileDeleteModal}

                        </div>
                    </Fragment>
                </Container >
            </>
        );
    }
}
