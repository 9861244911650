import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

interface IPopoverItem {
  popoverOpen: boolean,
}

interface IPopoverItemProps {
  id: string | number,
  item: any,
  icon?: any,
  titleP?: string,
  contentP?: string,
  modifiers?: boolean,
  isLink?: boolean,
  iconColor?: string
}

export default class PopoverItem extends React.Component<IPopoverItemProps, IPopoverItem> {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  render() {

    let icon = <FontAwesomeIcon icon={this.props.icon} className={this.props.iconColor ? this.props.iconColor : ''}/>

    return (
      <span>
        <i id={'popover-' + this.props.id} className={'custompopover popover-' + this.props.id} >
           {icon} {this.props.item.text ? this.props.item.text : null}
        </i>
        <Popover placement={this.props.item.placement} isOpen={this.state.popoverOpen} target={'popover-' + this.props.id} toggle={this.toggle} trigger="legacy" modifiers={this.props.modifiers ? { flip: { behavior: ['bottom'] } } : null}>
          <PopoverHeader>{this.props.titleP}</PopoverHeader>
          <PopoverBody>{this.props.contentP} {this.props.isLink ? <a href={this.props.contentP} target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} /></a> : null}</PopoverBody>
        </Popover>
      </span>
    );
  }
}