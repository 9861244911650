import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Container, Row, Col, Table, ModalHeader, ModalBody, Modal, ModalFooter, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxes, faTruckLoading, faUsers, faFile, faExternalLinkSquareAlt, faKey } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import samplePdf from '../../assets/pdf/sample-pdf.jpg';
import { Role } from '../user/Role';
import Identity from '../user/Identity';
import { withSecurity } from '../../common/security/Security';
import { Supplier } from '../models/Supplier';
import { AxiosResponse, AxiosError } from 'axios';
import Util from '../custom/Util';
import { Mill, IMill, IFavoriteMill } from '../models/Mill';
import { User } from '../models/User';
import { Search } from '../models/Search';
import { News, INews } from '../models/News';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { Table as TableModel } from '../../common/components/widgets/table/Table';
import { DataProvider } from '../../common/model/DataProvider';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import { SupplierMeeting, ISupplierMetting } from '../models/SupplierMeeting';
import moment from 'moment';

@withRouter(Path.HOMEPAGE.toString(), PageContainer)
@withSecurity([Role.ADMIN.toString(), Role.CATEGORY_MANAGER.toString(), Role.USER.toString()], Identity, Path.LOGIN)
export default class DashboardPage extends VlbPage {

    private totalSuppliersModel: Supplier = new Supplier();
    private totalMillsModel: Mill = new Mill();
    private totalUsersModel: User = new User();
    private searchModel: Search = new Search();
    private newsModel: News = new News();
    private favMillDP = new DataProvider<IFavoriteMill>();
    private meetingsDP = new DataProvider<ISupplierMetting>();
    private newsDP = new DataProvider<INews>();
    private mill = new Mill(false);
    private news = new News(false);
    private supplierMeetings = new SupplierMeeting(false);

    constructor(props: any) {
        super(props);

        this.view = this.view.bind(this);

        this.state = {
            modalView: false,
            currentModelView: null,

            totalSupplierNumber: null,
            totalMillsNumber: null,
            totalUsersNumber: null,
            totalMillsAmlClassNumber: null,
            searchHistoryMills: [],
            newsList: [],
            modalFiel: false,
            currentModelFile: null,

            isLoading: true
        };
    }

    pageTitle() {
        return "Dashboard" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
        ];
    }

    componentDidMount() {

        this.totalSuppliersModel.getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    totalSupplierNumber: response.data.length
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Suppliers cannot be loaded", 1500);
            });

        this.totalMillsModel.getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    totalMillsNumber: response.data.length,
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Mills cannot be loaded", 1500);
            });

        this.totalUsersModel.getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    totalUsersNumber: response.data.length,
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Users cannot be loaded", 1500);
            });

        this.totalMillsModel.loadByAmlClass("A")
            .then((response: IMill[] | any) => {

                const aError: any = response as AxiosError;
                const mills: IMill[] = response as IMill[];

                if (!aError.response) {

                    this.setState({
                        totalMillsAmlClassNumber: mills.length
                    });
                }


            }).catch((e: AxiosError) => {
                Util.notification("error", "Mills cannot be loaded", 1500);
            });


        this.searchModel.history()
            .then((response: AxiosResponse) => {

                this.setState({
                    searchHistoryMills: response.data,
                    isLoading: false
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Search history cannot be loaded", 1500);
            });

        this.newsModel.getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    newsList: response.data
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Recent updates cannot be loaded", 1500);
            });

        super.componentDidMount();

    }

    view(modelView?) {
        this.setState(prevState => ({
            modalView: !prevState.modalView,
            currentModelView: modelView
        }));
    }

    renderContent() {
        const cols: any[] = [
            new DataColumn('Mill', 'mill.name', 30, null, null),
            new DataColumn('Country', 'mill.country.name', 20, null, null),
            new DataColumn('City', 'mill.city', 20, null, null),
            new DataColumn('Supplier', 'mill.supplier.name', 20, null, null),
            new ActionColumn("", null, null, 10,
                [{
                    operationCb: (row: any) => {
                        window.location.pathname = "/supplier/" + row.mill.supplier_id + "/mill/" + row.mill.id
                    },
                    iconClassName: "table-edit",
                    message: null,
                    icon: <FontAwesomeIcon icon={faExternalLinkSquareAlt} />,
                    iconStyle: {}
                }],
                "Link"
            ),
        ];

        const colsMeeting: any[] = [
            new DataColumn('Supplier', 'supplier.name', 30, null, null),
            new DataColumn('Subject', 'subject', 20, null, null),
            new DataColumn('Location', 'meeting_location', 20, null, null),
            new ActionColumn("", null, null, 10,
                [{
                    operationCb: (row: any) => {
                      if(row.attachment){
                        this.setState({
                            currentModelFile: row,
                            modalFile: true
                        })

                      }else{
                          Util.notification("info","No attachments",1500)
                      }
                    },
                    iconClassName: "table-edit",
                    message: null,
                    icon: <FontAwesomeIcon icon={faFile} />,
                    iconStyle: {}
                }],
                "File"
            ),
        ];

        const colsNews: any[] = [
            new DataColumn('Date', 'published_at', 30, null, null),
            new DataColumn('News', 'title', 20, null, null),
            new ActionColumn("", null, null, 10,
                [{
                    operationCb: (row: any) => {
                      if(row.attachment){
                        this.setState({
                            currentModelFile: row,
                            modalFile: true
                        })

                      }else{
                          Util.notification("info","No attachments",1500)
                      }
                    },
                    iconClassName: "table-edit",
                    message: null,
                    icon: <FontAwesomeIcon icon={faFile} />,
                    iconStyle: {}
                }],
                "File"
            ),
        ];

        let searchHistoryLoading = []
        for (let i = 1; i <= 5; i++) {
            searchHistoryLoading.push(
                <tr key={i}>
                    <td><i className="content-loader w-75" /></td>
                    <td><i className="content-loader w-50" /></td>
                    <td><i className="content-loader w-icon" /></td>
                </tr>
            )
        }

        let searchHistory
        if (this.state.isLoading) {
            searchHistory = searchHistoryLoading
        } else {
            searchHistory = (
                this.state.searchHistoryMills.map((single, i) =>
                <tr key={i}>
                    <td>{single.mill}</td>
                    <td>{single.mill_location}</td>
                    <td><Link to={"/supplier/" + single.supplier_id + "/mill/" + single.mill_id}><FontAwesomeIcon icon={faExternalLinkSquareAlt} /></Link></td>
                </tr>
                )
            )
        }

        let fileModal;
        fileModal = (
            <Modal centered size="xl" isOpen={this.state.modalFile} fade={false} toggle={() => this.setState({ modalFile: !this.state.modalFile })}>
                <ModalHeader toggle={() => this.setState({ modalFile: !this.state.modalFile })}>{this.state.currentModelFile ? this.state.currentModelFile.subject : null}</ModalHeader>
                <ModalBody>
                <embed src={this.state.currentModelFile ? this.state.currentModelFile.attachment : null} type="application/pdf" />
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalFile: !this.state.modalFile })}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )

        return (
            <Container fluid className="page-content">

                <div className="title-box">
                    <h1 className="page-title">Dashboard</h1>
                </div>

                <Row>
                    <Col md="3">
                        <div className="element-box statistic">
                            <Row>
                                <Col xs="12">
                                    <h2>Total suppliers</h2>
                                </Col>
                                <Col xs="6">
                                    <FontAwesomeIcon icon={faBoxes} />
                                </Col>
                                <Col xs="6">
                                    <div className="count">{this.state.totalSupplierNumber ? this.state.totalSupplierNumber : <i className="content-loader w-50 loader-cs" />}</div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md="3">
                        <div className="element-box statistic">
                            <Row>
                                <Col xs="12">
                                    <h2>Total mills</h2>
                                </Col>
                                <Col xs="6">
                                    <FontAwesomeIcon icon={faTruckLoading} />
                                </Col>
                                <Col xs="6">
                                    <div className="count">{this.state.totalMillsNumber ? this.state.totalMillsNumber : <i className="content-loader w-50 loader-cs" />}</div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md="3">
                        <div className="element-box statistic">
                            <Row>
                                <Col xs="12">
                                    <h2>Key suppliers</h2>
                                </Col>
                                <Col xs="6">
                                    <FontAwesomeIcon icon={faKey} />
                                </Col>
                                <Col xs="6">
                                    <div className="count">{this.state.totalMillsAmlClassNumber ? this.state.totalMillsAmlClassNumber : <i className="content-loader w-50 loader-cs" />}</div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md="3">
                        <div className="element-box statistic">
                            <Row>
                                <Col xs="12">
                                    <h2>Total users</h2>
                                </Col>
                                <Col xs="6">
                                    <FontAwesomeIcon icon={faUsers} />
                                </Col>
                                <Col xs="6">
                                    <div className="count">{this.state.totalUsersNumber ? this.state.totalUsersNumber : <i className="content-loader w-50 loader-cs" />}</div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xl="6">
                        <div className="element-box">
                            <h2>News</h2>
                            <ReduxComponentWrapper component={TableModel} componentPropsCallback={(state: any) => ({
                                    provider: this.newsDP,
                                    searchCallback: (params: IRestDataSourceParams): void => {
                                        this.newsDP.refreshWithHeaders(
                                            this.news.getListPlain({
                                                paging: {
                                                    pageNumber: 1,
                                                    pageSize: 5
                                                },
                                                sort: {
                                                    attr: "published_at",
                                                    ascending: false
                                                }
                                            })
                                        )
                                    },
                                    columns: colsNews,
                                    loader:[3,10]
                                })} />
                        </div>
                    </Col>

                    <Col xl="6">
                        <div className="element-box">
                            <h2>Upcoming meetings</h2>
                            <ReduxComponentWrapper component={TableModel} componentPropsCallback={(state: any) => ({
                                    provider: this.meetingsDP,
                                    searchCallback: (params: IRestDataSourceParams): void => {
                                        this.meetingsDP.refreshWithHeaders(
                                            this.supplierMeetings.getListPlain({
                                                paging: {
                                                    pageNumber: 1,
                                                    pageSize: 5
                                                },
                                                sort: {
                                                    attr: "meeting_date",
                                                    ascending: false
                                                },
                                                filters: [
                                                    {
                                                        attr: "meeting_date",
                                                        operator: "gte",
                                                        val: moment().format("YYYY-MM-DD")
                                                    }
                                                ]
                                            })
                                        )
                                    },
                                    columns: colsMeeting,
                                    loader:[4,10]
                                })} />
                        </div>
                    </Col>

                    <div className="col-xl-4">
                        <div className="element-box">
                            <h2>Mills search history</h2>

                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th scope="col">Mill</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Link</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {searchHistory}

                                </tbody>
                            </Table>

                        </div>
                    </div>

                    <div className="col-xl-8">
                        <div className="element-box">
                            <h2>Saved mills</h2>
                            <ReduxComponentWrapper component={TableModel} componentPropsCallback={(state: any) => ({
                                    provider: this.favMillDP,
                                    searchCallback: (params: IRestDataSourceParams): void => {
                                        this.favMillDP.refreshWithHeaders(
                                            this.mill.getFavoriteMills(params)
                                        )
                                    },
                                    columns: cols,
                                    loader:[5,2]
                                })} />
                        </div>
                    </div>

                </Row>

                <Modal centered size="lg" isOpen={this.state.modalView} fade={false} toggle={this.view}>
                    <ModalHeader toggle={this.view}>{this.state.currentModelView ? this.state.currentModelView.news : null}</ModalHeader>
                    <ModalBody>
                        <img className="img-fluid" alt="sample-pdf" src={samplePdf} />
                    </ModalBody>
                </Modal>
                {fileModal}

            </Container>

        );
    }
}
