import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import moment from "moment";

export interface IMillApproval {
    id?: number,
    mill_id?: number,
    name?: string,
    start_date?: string,
    end_date?: string,
    attachment?: string
}


export class MillApproval extends DataModel<IMillApproval> {

    public resourceName = "mill-approval";
    public resourceNamePlural = "mill-approvals";

    private _id: number;
    private _mill_id?: number;
    private _start_date?: string;
    private _end_date?: string;
    private _name?: string;
    private _attachment?: string;


    protected getDefaultValues(): IMillApproval {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IMillApproval> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IMillApproval> {
        return new DataModelValidator<IMillApproval>({
            name: [new RequiredValidator(), new LengthValidator({ max: 100 })]
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IMillApproval>, action: AnyAction): IDataModelState<IMillApproval> {
        return state;
    }

    public loadMillApprovals(): Promise<IMillApproval[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }

    public loadByMillId(id: number): Promise<IMillApproval[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural+ "?filter=mill_id:eq:"+id)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IMillApproval | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<IMillApproval | AxiosError> {

        return this.createDataSource(this.resourceName)
            .postOperation({
                name: this._name,
                mill_id: +this._mill_id,
                start_date: moment().format("YYYY-MM-DD"),
                end_date: this._end_date,
                attachment: this._attachment
            });
    }

    public update(data: IMillApproval): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                name: this._name,
                mill_id: +this._mill_id,
                start_date: this._start_date,
                end_date: this._end_date,
                attachment: this._attachment
            });
    }



    getStoreKey(): string {
        return "MILL-APPROVAL";
    }

    protected setFromObj(data: IMillApproval): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._mill_id = DataModel.safeGet(data.mill_id, this._mill_id);
        this._start_date = DataModel.safeGet(data.start_date, this._start_date);
        this._end_date = DataModel.safeGet(data.end_date, this._end_date);
        this._attachment = DataModel.safeGet(data.attachment, this._attachment);
    }


    protected toObj(): IMillApproval {
        return {
            id: this._id,
            name: this._name,
            mill_id: this._mill_id,
            start_date: this._start_date,
            end_date: this._end_date,
            attachment: this._attachment,
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }


    get mill_id() {
        return this._mill_id;
    }


    set mill_id(id: number) {
        this._mill_id = id;
    }

    set start_date(date: string) {
        this._start_date = date;
    }


    get start_date() {
        return this._start_date;
    }


    get end_date() {
        return this._end_date;
    }


    get attachment() {
        return this._attachment;
    }


    set attachment(path: string) {
        this._attachment = path;
    }


}
