import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";

export interface ISupplierClassification {
    id?: number,
    name?: string
}


export class SupplierClassification extends DataModel<ISupplierClassification> {

    public resourceName = "supplier/classifications";
    public resourceNamePlural = "supplier/classifications";

    private _id: number;
    private _name: string;

    protected getDefaultValues(): ISupplierClassification {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<ISupplierClassification> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ISupplierClassification> {
        return new DataModelValidator<ISupplierClassification>({
            name: [new RequiredValidator(), new LengthValidator({ min: 1 })],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<ISupplierClassification>, action: AnyAction): IDataModelState<ISupplierClassification> {
        return state;
    }

    public loadCountries(): Promise<ISupplierClassification[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<ISupplierClassification | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource()
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<ISupplierClassification | AxiosError> {

        return this.createDataSource(this.resourceName)
            .postOperation({ name: this._name });
    }

    public update(data: ISupplierClassification): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({ name: data.name });
    }



    getStoreKey(): string {
        return "SUPPLIER_CLASSIFICATION";
    }

    protected setFromObj(data: ISupplierClassification): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
    }


    protected toObj(): ISupplierClassification {
        return {
            id: this._id,
            name: this._name
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }

}
