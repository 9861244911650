import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import { IUser } from "./User";
import { ISupplier } from "./Supplier";
import { ICountry } from "./Country";
import { IQualityStatus, QualityStatus } from "./QualityStatus";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";
import Util from "../custom/Util";

export interface IMill {
    id?: number,
    supplier_id?: number,
    supplier?: ISupplier,
    country_id?: number,
    country?: ICountry,
    quality_status_id?: number,
    quality_status?: IQualityStatus,
    aml_class?: string,
    type?: string,
    name?: string,
    city?: string,
    note?: string,
    aml_since?: string,
    last_audit_date?: string,
    last_audit_attachment?: string,
    next_audit_date?: string,
    additional_services?: string
}

export interface IFavoriteMill {
    user_id?: number,
    mill_id?: number,
    mill?: IMill,
    user?: IUser
}


export class Mill extends DataModel<IMill> {

    public resourceName = "mill";
    public resourceNamePlural = "mills";

    private _id: number;
    private _name: string;
    private _supplier_id: number
    private _supplier: ISupplier
    private _country_id: number
    private _country: ICountry
    private _quality_status_id: number
    private _quality_status: IQualityStatus
    private _aml_class: string
    private _type: string
    private _city: string
    private _note: string
    private _aml_since: string
    private _last_audit_date: string
    private _last_audit_attachment: string
    private _next_audit_date: string
    private _additional_services: string

    public static SCENARIO = {
        STEP1: 'step1',
        STEP2: 'step2',
        STEP3: 'step3',
    };

    protected getDefaultValues(): IMill {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IMill> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IMill> {
        switch (scenario) {
            case Mill.SCENARIO.STEP1:
                return new DataModelValidator<IMill>({
                    name: [new RequiredValidator(), new LengthValidator({ min: 6 })],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
                break;
            case Mill.SCENARIO.STEP2:
                return new DataModelValidator<IMill>({
                    type: [new LengthValidator({ min: 3 })],
                    quality_status_id: [new RequiredValidator()],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
                break;
            case Mill.SCENARIO.STEP3:
                return new DataModelValidator<IMill>({
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
                break;
            default:
                return new DataModelValidator<IMill>({
                    name: [new RequiredValidator(), new LengthValidator({ min: 6 })],
                    type: [new LengthValidator({ min: 3 })],
                    city: [new RequiredValidator()],
                    quality_status_id: [new RequiredValidator()],
                    country_id: [new RequiredValidator()],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
        }
    }

    protected modelReducer(state: IDataModelState<IMill>, action: AnyAction): IDataModelState<IMill> {
        return state;
    }

    public loadMills(custom_filter: string = ""): Promise<IMill[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .addCustomPath("?filter=status_id:neq:2" + custom_filter)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadByAmlClass(className: string): Promise<IMill[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural + "?filter=aml_class:eq:" + className)
            .getList({});
    }

    public loadById(id: number): Promise<IMill | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }

    public delFav(id: number): Promise<void> {
        return this.createDataSource("user/favorite-mill")
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<IMill | AxiosError> {

        return this.createDataSource(this.resourceName)
            .postOperation(this.asPlainObject());
    }

    public update(data: IMill): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation(data);
    }

    public getFavoriteMills(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource("user/favorite-mill")
            .getListPlain(RestParamsQueryBuilder.buildRestParams(params));
    }

    public static amlClassDropdownItems(): IDropdownListItem[] {

        return [
            { id: "A", name: "A" },
            { id: "B", name: "B" },
            { id: "C", name: "C" },
            { id: "D", name: "D" },
            { id: "E", name: "E" },
        ]
    }

    public static typeDropdownItems(): IDropdownListItem[] {

        return [
            { id: "key", name: "Key supplier" },
            { id: "regular", name: "Regular supplier" }
        ]
    }


    getStoreKey(): string {
        return "MILL";
    }

    protected setFromObj(data: IMill): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._supplier_id = DataModel.safeGet(data.supplier_id, this._supplier_id);
        this._supplier = DataModel.safeGet(data.supplier, this._supplier);
        this._country_id = DataModel.safeGet(data.country_id, this._country_id);
        this._country = DataModel.safeGet(data.country, this._country);
        this._quality_status_id = DataModel.safeGet(data.quality_status_id, this._quality_status_id);
        this._quality_status = DataModel.safeGet(data.quality_status, this._quality_status);
        this._aml_class = DataModel.safeGet(data.aml_class, this._aml_class);
        this._type = DataModel.safeGet(data.type, this._type);
        this._city = DataModel.safeGet(data.city, this._city);
        this._note = DataModel.safeGet(data.note, this._note);
        this._aml_since = Util.formatDatetime(DataModel.safeGet(data.aml_since, this._aml_since));
        this._last_audit_date = Util.formatDatetime(DataModel.safeGet(data.last_audit_date, this._last_audit_date));
        this._last_audit_attachment = DataModel.safeGet(data.last_audit_attachment, this._last_audit_attachment);
        this._next_audit_date = Util.formatDatetime(DataModel.safeGet(data.next_audit_date, this._next_audit_date));
        this._additional_services = DataModel.safeGet(data.additional_services, this._additional_services);
    }


    protected toObj(): IMill {
        return {
            id: this._id,
            name: this._name,
            supplier_id: Number(this._supplier_id),
            supplier: this._supplier,
            country: this._country,
            country_id: Number(this._country_id),
            quality_status_id: Number(this._quality_status_id),
            quality_status: this._quality_status,
            aml_class: this._aml_class,
            type: this._type,
            city: this._city,
            note: this._note,
            aml_since: this._aml_since ? Util.formatDatetimeTimestamp(this._aml_since).toString() : null,
            last_audit_date: this._last_audit_date ? Util.formatDatetimeTimestamp(this._last_audit_date).toString() : null,
            last_audit_attachment: this._last_audit_attachment,
            next_audit_date: this._next_audit_date ? Util.formatDatetimeTimestamp(this._next_audit_date).toString() : null,
            additional_services: this._additional_services,
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    set supplier_id(id: number) {
        this._supplier_id = id;
    }

    get name() {
        return this._name;
    }


    get supplier_id() {
        return this._supplier_id;
    }

    get supplier() {
        return this._supplier_id;
    }

    get country_id() {
        return this._country;
    }

    get country() {
        return this._country;
    }

    get quality_status_id() {
        return this._quality_status_id;
    }

    get quality_status() {
        return this._quality_status;
    }


    get aml_class() {
        return this._aml_class;
    }


    get type() {
        return this._type;
    }


    get city() {
        return this._city;
    }


    get note() {
        return this._note;
    }


    get aml_since() {
        return this._aml_since;
    }


    get last_audit_date() {
        return this._last_audit_date;
    }


    get last_audit_attachment() {
        return this._last_audit_attachment;
    }

    set last_audit_attachment(url:string) {
        this._last_audit_attachment = url;
    }


    get next_audit_date() {
        return this._next_audit_date;
    }


    get additional_services() {
        return this._additional_services;
    }
}
