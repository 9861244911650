import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
export interface IFile {
    name?: string
}


export class File extends DataModel<IFile> {

    public resourceName = "file";
    public resourceNamePlural = "file";

    private _name: string;

    protected getDefaultValues(): IFile {
        return {
        };
    }

    public createDataSource(resourceName: string = this.resourceName): RestDataSource<IFile> {
        const requestHeaders = UserIdentity.getRequestHeaders();
        delete requestHeaders["Content-Type"];
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, requestHeaders);
    }

    protected createValidator(scenario: string): IDataModelValidator<IFile> {
        return new DataModelValidator<IFile>({
            name: [new RequiredValidator()],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IFile>, action: AnyAction): IDataModelState<IFile> {
        return state;
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public createNew(data): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .postFormOperation(data);
    }

    public del(path: string): Promise<void> {
        return this.createDataSource()
            .addCustomPath('/' + path)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "FILE";
    }

    protected setFromObj(data: IFile): void {
        this._name = DataModel.safeGet(data.name, this._name);
    }


    protected toObj(): IFile {
        return {
            name: this._name,
        };
    }

    get name() {
        return this._name;
    }

}
