import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";

export interface ISearch {
    id?: number,
    query?: IQuery,
    user_id?: number,
    result?: any,
    s?: string,
    page?: number,
    mill_id?: number,
    mill?: string,
    supplier_id?: number,
    supplier?: string,
    mill_location?: string,
    aml?: string
}


export interface ISearchResults {
    mill_id?: number,
    mill?: string,
    supplier_id?: number,
    supplier?: string,
    mill_location?: string,
    aml?: string
}


export interface IQuery {
    material?: number[],
    od_min?: number[],
    od_max?: number[],
    product_category?: number[],
    approval?: number[],
    aml_class?: string[],
    certificate?: number[],
    country?: number[],
    vl_aml?: boolean,
    query?: string
}


export class Search extends DataModel<ISearch> {

    public resourceName = "search";

    private _id: number;
    private _query: IQuery;
    private _user_id: number;
    private _result: any;
    private _page: number;


    protected getDefaultValues(): ISearch {
        return { result: [] };
    }


    public createDataSource(resourceName: string = this.resourceName): RestDataSource<ISearch> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ISearch> {
        return new DataModelValidator<ISearch>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<ISearch>, action: AnyAction): IDataModelState<ISearch> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getResultListWithFilters(params: string): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
        .addCustomPath(params)
        .getListPlain(RestParamsQueryBuilder.buildRestParams({}))
    }

    public loadById(id: number): Promise<ISearch | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public static search(data: IQuery,params: IRestDataSourceParams): Promise<AxiosResponse> {

        let query: IQuery = {
        };

        /*

    od?: number, ???????????
        */

        if (data.material != null) { query.material = data.material }
        if (data.product_category != null) { query.product_category = data.product_category }
        if (data.aml_class != null) { query.aml_class = data.aml_class }
        if (data.certificate != null) { query.certificate = data.certificate }
        if (data.country != null) { query.country = data.country }
        if (data.vl_aml != null) { query.vl_aml = data.vl_aml }
        if (data.od_min != null) { query.od_min = data.od_min }
        if (data.od_max != null) { query.od_max = data.od_max }

        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + 'search', UserIdentity.getRequestHeaders())
        .plainGetOperation(query);
    }

    public autocomplete(searchTerm: string): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + '/autocomplete')
            .plainGetOperation({ s: searchTerm });
    }

    public history(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + '/history')
            .plainGetOperation({});
    }

    public static toURLParams(obj: Object): string {
        return Object.keys(obj).map(function (key) {
            return key + '=' + obj[key];
        }).join('&');
    }


    getStoreKey(): string {
        return "SEARCH";
    }

    protected setFromObj(data: ISearch): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._user_id = DataModel.safeGet(data.user_id, this._user_id) as number;
        this._query = DataModel.safeGet(data.query, this._query);
        this._result = DataModel.safeGet(data.result, this._result);
    }


    protected toObj(): ISearch {
        return {
            id: this._id,
            user_id: this._user_id,
            query: this._query,
            result: this._result
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get user_id() {
        return this._user_id;
    }


    get query() {
        return this._query;
    }


    set query(query: IQuery) {
        this._query = query;
    }


    get result() {
        return this._result;
    }


    set result(result: any) {
        this._result = result;
    }

}
