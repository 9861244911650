import * as React from 'react';
import { TextInput, ITextInputProps } from "./TextInput";

export interface ICheckboxInputProps extends ITextInputProps {
    disabled?: boolean;
}

export class CheckboxInput<T extends ICheckboxInputProps = ICheckboxInputProps> extends TextInput<T> {

    protected fullFieldRender(): React.ReactNode {
        return (
            <div className="d-block">
                <div className="custom-checkbox custom-control">
                    {this.renderInput()}
                    {this.renderLabel()}
                </div>
                <div className="d-block">
                    {this.renderError()}
                </div>
            </div>
        );
    }

    protected renderInput(): React.ReactNode {
        const additionalProps: any = {};

        if (this.props.disabled) {
            additionalProps['disabled'] = this.props.disabled;
        }

        if (this.props.tabIndex) {
            additionalProps['tabIndex'] = this.props.tabIndex;
        }

        return (<input type="checkbox"
            id={this.props.id}
            ref={this.inputRef}
            onBlur={(this.props.onBlur) ? this.props.onBlur : () => {
            }}
            {...additionalProps}
            onChange={(this.props.onChange) ? this.props.onChange : () => {
            }}
            className={"custom-control-input " + this.getInputErrorClass()} />);
    }


    protected renderLabel(): React.ReactNode {
        return (<label htmlFor={this.props.id} className={"custom-control-label " + (this.getErrorClass())}>{this.props.label}</label>
        );
    }

    public getValue(): any {
        return (this.inputRef.current) ? !!(this.inputRef.current.checked) : false;
    }

    public setValue(initialValue: any) {
        this.inputRef.current.checked = !!initialValue;
    }
}
