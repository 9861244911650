import React from 'react';
import { Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Path } from '../container/Path';

interface IErrorBoundary {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<{}, IErrorBoundary> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="forbidden-page">
                    <div className="info-page-content d-flex justify-content-center align-items-center">
                        <Container >
                            <h1>Something went wrong</h1>
                            <p>We're working on it and we'll get fixed as soon as we can.</p>

                            <Button tag={Link} to={Path.HOMEPAGE} color="primary">Dashboard</Button>
                        </Container>
                    </div>
                </div >
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;