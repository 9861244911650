import React, { Component } from 'react';
import { Container, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, ModalFooter, Label, CustomInput, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faStar, faEdit, faTrash, faPlus, faInfoCircle, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarNone } from '@fortawesome/free-regular-svg-icons';
import Util from '../../custom/Util';
import { TextInput } from '../../../common/components/widgets/form/input/TextInput';
import { DropdownInput } from '../../../common/components/widgets/form/input/DropdownInput';
import { DateInput } from '../../../common/components/widgets/form/input/DateInput';
import { TextAreaInput } from '../../../common/components/widgets/form/input/TextAreaInput';
import PopoverItem from '../../custom/PopoverItem';
import { MillProduct, IMillProduct } from '../../models/MillProduct';
import { Mill as MillModel, IMill } from '../../models/Mill';
import { MillCertificate, IMillCertificate } from '../../models/MillCertificate';
import { MillContact, IMillContact } from '../../models/MillContact';
import { MillApproval, IMillApproval } from '../../models/MillApproval';
import { MillInformation, IMillInformation } from '../../models/MillInformation';
import { AxiosError, AxiosResponse } from 'axios';
import { MillProductGrade, IMillProductGrade } from '../../models/MillProductGrade';
import { Country } from '../../models/Country';
import { QualityStatus } from '../../models/QualityStatus';
import { User } from '../../models/User';
import samplePdf from '../../../assets/pdf/sample-pdf.jpg';
import { FormHandlingUtil } from '../../../common/components/widgets/form/util/FormHandlingUtil';
import { File as FileModel } from '../../models/File';
import { Certificate } from '../../models/Certificate';
import { ProductCategory } from '../../models/ProductCategory';
import { MaterialGradeCategory } from '../../models/MaterialGradeCategory';
import { MaterialDefaultGrade } from '../../models/MaterialDefaultGrade';
import Identity from '../../user/Identity';
import { Path } from '../../container/Path';

interface IMillComponent {
    loaded: boolean;
    modalAdd: boolean;
    currentModelAdd: any;
    modalDelete: boolean;
    currentModelDelete: any;
    modalEdit: boolean;
    currentModelEdit: any;
    modalView: boolean;
    currentModelView: any;
    tableName: string,
    millContactsList: any[],
    millCertificatesList: any[],
    millInformationList: any[],
    millProductsList: any[],
    millApprovalList: any[],
    countries: any[],
    certificates: any[],
    productCategories: any[],
    materialGradeCategories: any[],
    materialDefaultGrades: any[],
    qStatuses: any[],
    files: any[],
    fileUrl: string,

    modalFile: boolean,
    currentModelFile: any,

    modalFileDelete: boolean,
    modalFileDeleteAttr: string,
    modalFileDeleteEvent: any,

    waitForUpload: boolean,


    modalSavedMill: boolean,
    modalSavedMillInfo: number,

    isMillSaved: boolean
}

export default class Mill extends Component<{ id: number; manager: number; }, IMillComponent> {

    private mill = new MillModel();
    private millProduct = new MillProduct();
    private millCertificate = new MillCertificate();
    private millContact = new MillContact();
    private millApproval = new MillApproval();
    private millInformation = new MillInformation();
    private millProductGrade = new MillProductGrade();
    private productCategory = new ProductCategory();
    private materialGradeCategory = new MaterialGradeCategory();
    private materialDefaultGrade = new MaterialDefaultGrade();
    private country = new Country();
    private certificate = new Certificate();
    private qualityStatus = new QualityStatus();
    private user = new User();
    private file = new FileModel();

    private submitted = false;


    private millInputs1: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        country_id: React.createRef(),
        city: React.createRef(),
        quality_status_id: React.createRef()
    };
    private millInputs2: { [attr: string]: React.RefObject<any> } = {
        aml_since: React.createRef(),
        aml_class: React.createRef(),
        type: React.createRef(),
        quality_status_id: React.createRef(),
        last_audit_attachment: React.createRef(),
        last_audit_date: React.createRef(),
        next_audit_date: React.createRef()
    };

    private millInputs3: { [attr: string]: React.RefObject<any> } = {
        note: React.createRef(),
    };

    private millContactInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        job_title: React.createRef(),
        email: React.createRef(),
        mobile_phone: React.createRef(),
        phone: React.createRef()
    };

    private millApprovalInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        end_date: React.createRef(),
        attachment: React.createRef()
    };

    private millCertificateInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        certificate_id: React.createRef(),
        end_date: React.createRef(),
        attachment: React.createRef()
    };

    private millInformationInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        attachment: React.createRef()
    };

    private millProductInputs: { [attr: string]: React.RefObject<any> } = {
        product_category_id: React.createRef(),
        material_grade_category_id: React.createRef(),
        od_min: React.createRef(),
        od_max: React.createRef(),
        wt_min: React.createRef(),
        wt_max: React.createRef(),
    };



    private formHandlingUtilContact: FormHandlingUtil;
    private formHandlingUtilMill1: FormHandlingUtil;
    private formHandlingUtilMill2: FormHandlingUtil;
    private formHandlingUtilMill3: FormHandlingUtil;
    private formHandlingUtilApproval: FormHandlingUtil;
    private formHandlingUtilCertificate: FormHandlingUtil;
    private formHandlingUtilInformation: FormHandlingUtil;
    private formHandlingUtilProduct: FormHandlingUtil;


    constructor(props: any) {
        super(props);

        this.saveMill = this.saveMill.bind(this);
        this.add = this.add.bind(this);
        this.delete = this.delete.bind(this);
        this.toggleDelete = this.toggleDelete.bind(this);
        this.edit = this.edit.bind(this);
        this.view = this.view.bind(this);
        this.loadData = this.loadData.bind(this);
        this.addFile = this.addFile.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.uploadFilesToServer = this.uploadFilesToServer.bind(this);
        this.sendApprovalRequestToServer = this.sendApprovalRequestToServer.bind(this);
        this.sendCertificateRequestToServer = this.sendCertificateRequestToServer.bind(this);
        this.sendInformationRequestToServer = this.sendInformationRequestToServer.bind(this);
        this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
        this.fileView = this.fileView.bind(this);

        this.state = {
            loaded: false,
            modalAdd: false,
            currentModelAdd: null,
            modalDelete: false,
            currentModelDelete: null,
            modalView: false,
            currentModelView: null,
            modalEdit: false,
            currentModelEdit: null,
            tableName: null,
            millContactsList: null,
            millCertificatesList: null,
            millInformationList: null,
            millProductsList: null,
            millApprovalList: null,
            countries: [],
            certificates: [],
            qStatuses: [],
            files: [],
            fileUrl: null,
            productCategories: [],
            materialGradeCategories: [],
            materialDefaultGrades: [],

            modalFile: false,
            currentModelFile: {},

            modalFileDelete: false,
            modalFileDeleteAttr: "",
            modalFileDeleteEvent: null,

            waitForUpload: false,

            modalSavedMill: false,
            modalSavedMillInfo: null,

            isMillSaved: true,
        }


        this.formHandlingUtilContact = new FormHandlingUtil(
            this.millContactInputs,
            this.handleSubmitContact.bind(this),
            [this.millContact]
        );

        this.formHandlingUtilMill1 = new FormHandlingUtil(
            this.millInputs1,
            this.handleSubmitMill.bind(this),
            [this.mill]
        );

        this.formHandlingUtilMill2 = new FormHandlingUtil(
            this.millInputs2,
            this.handleSubmitMill.bind(this),
            [this.mill]
        );

        this.formHandlingUtilMill3 = new FormHandlingUtil(
            this.millInputs3,
            this.handleSubmitMill.bind(this),
            [this.mill]
        );

        this.formHandlingUtilApproval = new FormHandlingUtil(
            this.millApprovalInputs,
            this.handleSubmitMillApproval.bind(this),
            [this.millApproval]
        );

        this.formHandlingUtilCertificate = new FormHandlingUtil(
            this.millCertificateInputs,
            this.handleSubmitCertificate.bind(this),
            [this.millCertificate]
        );

        this.formHandlingUtilInformation = new FormHandlingUtil(
            this.millInformationInputs,
            this.handleSubmitInformation.bind(this),
            [this.millInformation]
        );

        this.formHandlingUtilProduct = new FormHandlingUtil(
            this.millProductInputs,
            this.handleSubmitProduct.bind(this),
            [this.millProduct]
        );
    }


    protected handleSubmitContact(event: any): void {

        this.setState({
            waitForUpload: true
        })

        let promise: Promise<AxiosResponse | IMillContact>;
        this.submitted = true;
        this.millContact.mill_id = this.props.id;

        if (this.state.modalEdit) {
            promise = this.millContact.update(this.millContact.asPlainObject());

        } else {
            promise = this.millContact.createNew();
        }

        promise.then((response: IMillContact) => {
            Util.notification("success", "Mill contact saved sucessfully", 1500);
            this.setState({ modalAdd: false, modalEdit: false, waitForUpload: false })
            this.loadData(this.props.id)
        }).catch((error: any) => {
            switch (error.response.status) {
                case 422:
                    if (error.response.data.errors) {
                        const respErrors: any[] = error.response.data.errors;
                        const errors: { [attr: string]: string } = {};

                        respErrors.forEach((error: any) => {
                            errors[error.fieldName] = error.errorMessage;
                        });

                        this.fillInputsWithErrors(errors, this.millContactInputs)
                    }
            }

            this.setState({ waitForUpload: false })
        });


        this.submitted = false;
    }

    protected handleSubmitMillApproval(event: any): void {

        this.setState({
            waitForUpload: true
        })

        this.submitted = true;
        this.millApproval.mill_id = this.props.id;

        if (this.state.files.length > 0) {

            this.uploadFilesToServer();

            setTimeout(() => {

                this.millApproval.attachment = this.state.fileUrl;

                this.sendApprovalRequestToServer()
            }, 1000);
        } else {
            this.sendApprovalRequestToServer()
        }

        this.submitted = false;
    }

    protected handleSubmitCertificate(event: any): void {

        this.setState({
            waitForUpload: true
        })

        this.submitted = true;
        this.millCertificate.mill_id = this.props.id;

        if (this.state.files.length > 0) {

            this.uploadFilesToServer();

            setTimeout(() => {

                this.millCertificate.attachment = this.state.fileUrl;

                this.sendCertificateRequestToServer()
            }, 1000);
        } else {
            this.sendCertificateRequestToServer()
        }

        this.submitted = false;
    }

    protected handleSubmitInformation(event: any): void {

        this.setState({
            waitForUpload: true
        })

        this.submitted = true;
        this.millInformation.mill_id = this.props.id;

        if (this.state.files.length > 0) {

            this.uploadFilesToServer();

            setTimeout(() => {

                this.millInformation.attachment = this.state.fileUrl;
                this.sendInformationRequestToServer()
            }, 1000);
        } else {
            this.sendInformationRequestToServer()
        }

        this.submitted = false;
    }

    protected handleSubmitMill(event: any): void {

        this.setState({
            waitForUpload: true
        })

        this.submitted = true;

        if (this.state.files.length > 0) {

            this.uploadFilesToServer();

            setTimeout(() => {

                this.mill.last_audit_attachment = this.state.fileUrl;

                this.updateMill()
            }, 1000);
        } else {
            this.updateMill()
        }

        this.submitted = false;
    }

    protected handleSubmitProduct(event: any): void {

        this.setState({
            waitForUpload: true
        })

        this.submitted = true;
        let promise: Promise<AxiosResponse | IMillCertificate>;

        this.millProduct.mill_id = this.props.id;

        if (this.state.modalEdit) {
            promise = this.millProduct.update(this.millProduct.asPlainObject());

        } else {
            promise = this.millProduct.createNew();
        }

        promise.then((response: IMillProduct) => {
            Util.notification("success", "Mill product saved sucessfully", 1500);
            this.setState({ modalAdd: false, modalEdit: false, waitForUpload: false, files: [] })
            this.loadData(this.props.id)
        }).catch((error: any) => {
            switch (error.response.status) {
                case 422:
                    if (error.response.data.errors) {
                        const respErrors: any[] = error.response.data.errors;
                        const errors: { [attr: string]: string } = {};

                        respErrors.forEach((error: any) => {
                            errors[error.fieldName] = error.errorMessage;
                        });

                        this.fillInputsWithErrors(errors, this.millProductInputs)
                    }
            }

            this.setState({ waitForUpload: false })
        });
        this.submitted = false;
    }

    sendApprovalRequestToServer() {

        let promise: Promise<AxiosResponse | IMillApproval>;
        if (this.state.modalEdit) {
            promise = this.millApproval.update(this.millApproval.asPlainObject());

        } else {
            promise = this.millApproval.createNew();
        }

        promise.then((response: IMillApproval) => {
            Util.notification("success", "Mill approval saved sucessfully", 1500);
            this.setState({ modalAdd: false, modalEdit: false, waitForUpload: false, files: [] })
            this.loadData(this.props.id)
        }).catch((error: any) => {
            switch (error.response.status) {
                case 422:
                    if (error.response.data.errors) {
                        const respErrors: any[] = error.response.data.errors;
                        const errors: { [attr: string]: string } = {};

                        respErrors.forEach((error: any) => {
                            errors[error.fieldName] = error.errorMessage;
                        });

                        this.fillInputsWithErrors(errors, this.millApprovalInputs)
                    }
            }

            this.setState({ waitForUpload: false })
        });
    }

    sendInformationRequestToServer() {

        let promise: Promise<AxiosResponse | IMillApproval>;
        if (this.state.modalEdit) {
            promise = this.millInformation.update(this.millInformation.asPlainObject());

        } else {
            promise = this.millInformation.createNew();
        }

        promise.then((response: IMillInformation) => {
            Util.notification("success", "Mill information saved sucessfully", 1500);
            this.setState({ modalAdd: false, modalEdit: false, waitForUpload: false, files: [] })
            this.loadData(this.props.id)
        }).catch((error: any) => {
            switch (error.response.status) {
                case 422:
                    if (error.response.data.errors) {
                        const respErrors: any[] = error.response.data.errors;
                        const errors: { [attr: string]: string } = {};

                        respErrors.forEach((error: any) => {
                            errors[error.fieldName] = error.errorMessage;
                        });

                        this.fillInputsWithErrors(errors, this.millInformationInputs)
                    }
            }

            this.setState({ waitForUpload: false })
        });
    }

    sendCertificateRequestToServer() {

        let promise: Promise<AxiosResponse | IMillCertificate>;
        if (this.state.modalEdit) {
            promise = this.millCertificate.update(this.millCertificate.asPlainObject());

        } else {
            promise = this.millCertificate.createNew();
        }

        promise.then((response: IMillCertificate) => {
            Util.notification("success", "Mill certificate saved sucessfully", 1500);
            this.setState({ modalAdd: false, modalEdit: false, waitForUpload: false, files: [] })
            this.loadData(this.props.id)
        }).catch((error: any) => {
            switch (error.response.status) {
                case 422:
                    if (error.response.data.errors) {
                        const respErrors: any[] = error.response.data.errors;
                        const errors: { [attr: string]: string } = {};

                        respErrors.forEach((error: any) => {
                            errors[error.fieldName] = error.errorMessage;
                        });

                        this.fillInputsWithErrors(errors, this.millCertificateInputs)
                    }
            }

            this.setState({ waitForUpload: false })
        });
    }


    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key) && inputs[key] != undefined && inputs[key].current) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }


    uploadFilesToServer() {
        let data = new FormData();

        //Append files to form data
        let files = this.state.files;

        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                data.append('files', files[i], files[i].name);
            }

            this.file.createNew(data).then((response: AxiosResponse) => {
                let files: string[] = response.data;
                this.setState({
                    fileUrl: files[0]
                })

            })
        }
    }

    removeFile(attr, event) {
        const inputElement: HTMLInputElement = document.getElementById(attr) as HTMLInputElement;

        inputElement.value = ''


        // Dodati modal dialog
        if (this.state.modalEdit) {
            switch (attr) {
                case "mill":
                    this.mill.last_audit_attachment = null;
                    this.updateMill();
                    this.setState({
                        modalFileDelete: false,
                    })
                    break;
                case "approvals":
                    this.millApproval.attachment = null;
                    this.setState({
                        modalFileDelete: false,
                    })
                    this.sendApprovalRequestToServer();
                    break;
                case "certificatesStandards":
                    break;
            }
        }
    }

    deleteSavedMill(id) {
        this.mill.delFav(id)
            .then(() => {
                Util.notification("success", "Your have removed mill from saved mills.", 1000);
                this.setState(prevState => ({
                    modalSavedMill: !prevState.modalSavedMill,
                    isMillSaved: false,
                }));
            })
            .catch(() => {
                Util.notification("error", "Mill could not be removed.", 10000);
            });
    }

    addFile = (ref: React.RefObject<any>, event) => {

        let newFilesList = [];
        let files = event.target.files;

        for (var i = 0; i < files.length; i++) {
            let fileGood = true;
            var regex = new RegExp("(.*?)\.(pdf)$");

            if (!(regex.test(files[i].type))) {
                Util.notification("error", "Document format is not valid. Allowed: pdf", 10000);
                fileGood = false;
            }

            if (fileGood) {
                newFilesList.push(files[i]);
                ref.current.setValue(files[i].name)
            }
        }


        newFilesList.concat(...this.state.files)
        this.setState({ files: newFilesList })

    }

    updateMill() {
        this.mill.update(this.mill.asPlainObject()).then((response: AxiosResponse) => {
            Util.notification("success", "Mill updated sucessfully", 1500);

            this.setState({
                modalEdit: !this.state.modalEdit,
                files: [],
                waitForUpload: false
            });

            this.loadData(this.props.id)
        }).catch((error: any) => {

            switch (error.response.status) {
                case 422:
                    if (error.response.data.errors) {
                        const respErrors: any[] = error.response.data.errors;
                        const errors: { [attr: string]: string } = {};

                        respErrors.forEach((error: any) => {
                            errors[error.fieldName] = error.errorMessage;
                        });

                        this.fillInputsWithErrors(errors, this.millInputs1)
                        this.fillInputsWithErrors(errors, this.millInputs2)
                        this.fillInputsWithErrors(errors, this.millInputs3)
                    }
            }

            this.setState({ waitForUpload: false })
        });
    }

    saveMill() {
        this.user.addFavoriteMill(this.props.id)
            .then((response: AxiosResponse) => {
                Util.notification("success", "Mill saved successfully", 1500);
                this.setState({
                    isMillSaved: true
                })


            }).catch((e: any) => {
                Util.notification("error", "Mill cannot be saved", 1500);
            });
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.id && this.props.id && prevProps.id !== this.props.id) {
            this.loadData(this.props.id)
        }
    }

    componentDidMount() {

        //Contact form handling util
        this.formHandlingUtilContact.setToModelsState();
        this.formHandlingUtilContact.activateReturnButtonSubmit();

        //MIll first form handling util
        this.formHandlingUtilMill1.setToModelsState();
        this.formHandlingUtilMill1.activateReturnButtonSubmit();

        this.country.getListPlain({
            sort: {
                attr: "name",
                ascending: true
            }
        })
            .then((response: AxiosResponse) => {

                this.setState({
                    countries: response.data
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Countries cannot be loaded", 1500);
            });


        if (this.props.id) {
            this.loadData(this.props.id)
        }

        this.qualityStatus.getListPlain({})
            .then((response: AxiosResponse) => {
                this.setState({
                    qStatuses: response.data
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Countries cannot be loaded", 1500);
            });

        this.certificate.getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    certificates: response.data
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Certificates cannot be loaded", 1500);
            });

        this.productCategory.getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    productCategories: response.data
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Product categories cannot be loaded", 1500);
            });


        this.materialGradeCategory.getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    materialGradeCategories: response.data
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Material grade categories cannot be loaded", 1500);
            });

        this.materialDefaultGrade.getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    materialDefaultGrades: response.data
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Material default grades cannot be loaded", 1500);
            });
    }

    loadData(id: number) {

        this.mill.loadById(id)
            .then((response: IMill | any) => {
                const aError: any = response as AxiosError;
                const mill: IMill = response as IMill;

                if (!aError.response) {
                    this.mill.setFromPlainObject(mill as IMill);
                    this.setState({ loaded: true })
                } else {
                    Util.notification("error", "Mill cannot be loaded", 1500);
                    window.location.replace(Path.NOTFOUND);
                }

            })


        this.millContact.loadByMillId(id)
            .then((response: IMillContact[]) => {
                this.setState({ millContactsList: response.length > 0 ? response : null })

            }).catch((e: AxiosError) => {
                Util.notification("error", "Mill contacts cannot be loaded", 1500);
            });


        this.millInformation.loadByMillId(id)
            .then((response: IMillContact[]) => {

                this.setState({ millInformationList: response.length > 0 ? response : null })


            }).catch((e: AxiosError) => {
                Util.notification("error", "Mill contacts cannot be loaded", 1500);
            });


        this.millApproval.loadByMillId(id)
            .then((response: IMillContact[]) => {

                this.setState({ millApprovalList: response.length > 0 ? response : null })

            }).catch((e: AxiosError) => {
                Util.notification("error", "Mill contacts cannot be loaded", 1500);
            });

        this.millProduct.loadByMillId(id)
            .then((response: IMillContact[]) => {

                if (response.length > 0) {

                    let tempArray = response;

                    tempArray.forEach((element: IMillProduct) => {
                        this.millProductGrade.loadByMillId(element.product_category_id)
                            .then((response: IMillProductGrade[]) => {


                                if (response.length > 0) {
                                    let grades = response.map(pr => pr.grade)
                                    let stringgrades = grades.join(",");
                                    element["grades"] = stringgrades;
                                } else {
                                    element["grades"] = "";
                                }

                                this.setState({ millProductsList: tempArray })

                            }).catch((e: AxiosError) => {
                                Util.notification("error", "Mill contacts cannot be loaded", 1500);
                            });
                    });

                } else {
                    this.setState({ millProductsList: null })
                }

            }).catch((e: AxiosError) => {
                Util.notification("error", "Mill contacts cannot be loaded", 1500);
            });

        this.mill.getFavoriteMills({})
            .then((response: AxiosResponse) => {

                let millSavedList = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data) {
                        millSavedList.push(response.data[i].mill_id)
                    }
                }

                if (millSavedList.indexOf(+this.props.id) !== -1) {
                    this.setState({
                        isMillSaved: true
                    })
                } else {
                    this.setState({
                        isMillSaved: false
                    })
                }

            }).catch((e: AxiosError) => {
                Util.notification("error", "Mill saved list cannot be loaded", 1500);
            });

        this.millCertificate.loadByMillId(id)
            .then((response: IMillContact[]) => {


                this.setState({ millCertificatesList: response.length > 0 ? response : null })


            }).catch((e: AxiosError) => {
                Util.notification("error", "Mill contacts cannot be loaded", 1500);
            });

        this.forceUpdate()

    }

    add(modelAdd?, tablename?) {
        this.setState(prevState => ({
            modalAdd: !prevState.modalAdd,
            currentModelAdd: modelAdd,
            tableName: tablename
        }));
    }

    delete() {
        let tablename = this.state.tableName;
        let modelDelete = this.state.currentModelDelete;

        switch (tablename) {
            case "millContact":

                this.millContact.del(modelDelete.id)
                    .then(() => {
                        Util.notification("success", "Mill contact deleted", 1500);
                        window.location.reload();

                    }).catch((e: AxiosError) => {

                        Util.notification("error", "Mill contact could not be deleted", 1500);
                    });
                break;
            case "approvals":

                this.millApproval.del(modelDelete.id)
                    .then(() => {
                        Util.notification("success", "Mill approval deleted", 1500);
                        window.location.reload();

                    }).catch((e: AxiosError) => {

                        Util.notification("error", "Mill approval could not be deleted", 1500);
                    });
                break;
            case "millInfo":

                this.mill.del(this.mill.id)
                    .then(() => {
                        Util.notification("success", "Mill deleted", 1500);
                        window.location.reload();
                    }).catch((e: AxiosError) => {

                        Util.notification("error", "Mill could not be deleted", 1500);
                    });
                break;
            case "information":

                this.millInformation.del(modelDelete.id)
                    .then(() => {
                        Util.notification("success", "Mill information deleted", 1500);
                        window.location.reload();
                    }).catch((e: AxiosError) => {

                        Util.notification("error", "Mill information could not be deleted", 1500);
                    });
                break;
            case "millProducts":

                this.millProduct.del(modelDelete.id)
                    .then(() => {
                        Util.notification("success", "Mill product deleted", 1500);
                        window.location.reload();
                    }).catch((e: AxiosError) => {

                        Util.notification("error", "Mill product could not be deleted", 1500);
                    });
                break;
            case "certificatesStandards":

                this.millCertificate.del(modelDelete.id)
                    .then(() => {
                        Util.notification("success", "Mill certificate deleted", 1500);
                        window.location.reload();
                    }).catch((e: AxiosError) => {

                        Util.notification("error", "Mill certificate could not be deleted", 1500);
                    });
                break;
        };
    }

    fileDelete(attr: string, event: React.MouseEvent<HTMLElement>) {
        const inputElement: HTMLInputElement = document.getElementById(attr) as HTMLInputElement

        if (inputElement.value === "") {
            Util.notification("info", "No file to delete", 1500);
        } else {
            this.setState(prevState => ({
                modalFileDelete: !prevState.modalFileDelete,
                modalFileDeleteAttr: attr,
                modalFileDeleteEvent: event,
            }));
        }
    }

    savedMillDelete(id: number) {
        if (id) {
            this.setState(prevState => ({
                modalSavedMill: !prevState.modalSavedMill,
                modalSavedMillInfo: id
            }));
        }
    }

    fileView(filename?: any, title?: string) {
        if (filename) {
            this.setState(function(prevState) {
                return Object.assign({}, prevState, {modalFile: !prevState.modalFile, currentModelFile: {file: filename, title: title}});
            });
        } else {
            Util.notification("info", "No attachment", 1500)
        }

    }

    toggleDelete(modelDelete?, tablename?) {

        this.setState(prevState => ({
            modalDelete: !prevState.modalDelete,
            currentModelDelete: modelDelete,
            tableName: tablename
        }));
    }

    edit(modelEdit?, tablename?) {

        switch (tablename) {
            case "millContact":

                this.millContact.loadById(modelEdit.id)
                    .then((response: IMillContact) => {
                        this.millContact.setFromPlainObject(response)
                        this.formHandlingUtilContact.setToModelsState();


                    }).catch((e: AxiosError) => {
                        Util.notification("error", "Mill contacts cannot be loaded", 1500);
                    });
                break;
            case "approvals":

                this.millApproval.loadById(modelEdit.id)
                    .then((response: IMillApproval) => {
                        this.millApproval.setFromPlainObject(response)
                        this.formHandlingUtilApproval.setToModelsState();


                    }).catch((e: AxiosError) => {
                        Util.notification("error", "Mill approvals cannot be loaded", 1500);
                    });
                break;
            case "certificatesStandards":

                this.millCertificate.loadById(modelEdit.id)
                    .then((response: IMillApproval) => {
                        this.millCertificate.setFromPlainObject(response)
                        this.formHandlingUtilCertificate.setToModelsState();

                    }).catch((e: AxiosError) => {
                        Util.notification("error", "Mill certificates cannot be loaded", 1500);
                    });
                break;
            case "millProducts":

                this.millProduct.loadById(modelEdit.id)
                    .then((response: IMillProduct) => {
                        this.millProduct.setFromPlainObject(response)
                        this.formHandlingUtilProduct.setToModelsState();
                    }).catch((e: AxiosError) => {
                        Util.notification("error", "Mill products cannot be loaded", 1500);
                    });
                break;
            case "information":

                this.millInformation.loadById(modelEdit.id)
                    .then((response: IMillInformation) => {
                        this.millInformation.setFromPlainObject(response)
                        this.formHandlingUtilInformation.setToModelsState();
                    }).catch((e: AxiosError) => {
                        Util.notification("error", "Mill information cannot be loaded", 1500);
                    });
                break;
            case "millInfo":
                this.mill.changeScenario(MillModel.SCENARIO.STEP1)
                this.mill.loadById(this.mill.id)
                    .then((response: IMill) => {
                        this.mill.setFromPlainObject(response)
                        this.formHandlingUtilMill1.setToModelsState();


                    }).catch((e: AxiosError) => {
                        console.log(e)
                    });
                break;
            case "qualityAudits":
                this.mill.changeScenario(MillModel.SCENARIO.STEP2)
                this.mill.loadById(this.mill.id)
                    .then((response: IMill) => {
                        this.mill.setFromPlainObject(response)
                        this.formHandlingUtilMill2.setToModelsState();


                    }).catch((e: AxiosError) => {
                        console.log(e)
                    });
                break;
            case "notes":
                this.mill.changeScenario(MillModel.SCENARIO.STEP3)

                this.mill.loadById(this.mill.id)
                    .then((response: IMill) => {
                        this.mill.setFromPlainObject(response)
                        this.formHandlingUtilMill3.setToModelsState();


                    }).catch((e: AxiosError) => {
                        console.log(e)
                    });
                break;
        };

        this.setState(prevState => ({
            modalEdit: !prevState.modalEdit,
            currentModelEdit: modelEdit,
            tableName: tablename
        }));
    }

    view(modelView?) {
        this.setState(prevState => ({
            modalView: !prevState.modalView,
            currentModelView: modelView
        }));
    }

    render() {

        const tableLoader = <i className="content-loader w-75" />;

        let viewModal;
        viewModal = (
            <Modal centered size="lg" isOpen={this.state.modalView} fade={false} toggle={this.view}>
                <ModalHeader toggle={this.view}>{this.state.currentModelView ? this.state.currentModelView.news : null}</ModalHeader>
                <ModalBody>
                    <img className="img-fluid" alt="sample-pdf" src={samplePdf} />
                </ModalBody>
            </Modal>
        )

        let millContact;
        if (this.state.loaded) {
            millContact = (
                this.state.millContactsList ? this.state.millContactsList.map(single => (
                    <tr key={single.id}>
                        <td>{single.name}</td>
                        <td>{single.job_title}</td>
                        <td>{single.email}</td>
                        <td>{single.mobile_phone}</td>
                        <td>{single.phone}</td>
                        <td>
                            {Identity.id === this.props.manager ? <> <a onClick={() => this.edit(single, 'millContact')}
                             className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></a>
                             <a onClick={() => this.toggleDelete(single, 'millContact')}
                             className="table-edit delete"><FontAwesomeIcon icon={faTrash} /></a> </> : null}
                        </td>
                    </tr >
                )) : <tr><td colSpan={6} className="font-weight-bold">No results.</td></tr>
            )
        } else {
            millContact = Util.tableLoader(5, 5);
        }


        let millApprovals;
        if (this.state.loaded) {
            millApprovals = (
                this.state.millApprovalList ? this.state.millApprovalList.map(single => (
                    <tr key={single.id}>
                        <td>
                            {single.attachment ? (<Link to="#" onClick={() => this.fileView(single.attachment, single.name)}><FontAwesomeIcon icon={faFile} /> </Link>) : null}
                        </td>
                        <td>{single.name}</td>
                        <td>{single.end_date}</td>
                        <td>
                            {Identity.id === this.props.manager ? <> <a
                            onClick={() => this.edit(single, 'approvals')}
                              className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></a>
                              <a onClick={() => this.toggleDelete(single, 'approvals')}
                              className="table-edit delete"><FontAwesomeIcon icon={faTrash} /></a> </> : null}
                        </td>
                    </tr>
                )) : <tr><td colSpan={4} className="font-weight-bold">No results.</td></tr>
            )
        } else {
            millApprovals = Util.tableLoader(4, 5);
        }


        let millInformation;
        if (this.state.loaded) {
            millInformation = (
                this.state.millInformationList !== null ? this.state.millInformationList.map(single => (
                    <tr key={single.id}>
                        <td>
                            {single.attachment ? (<Link to="#" onClick={() => this.fileView(single.attachment, single.name)}><FontAwesomeIcon icon={faFile} /> </Link>) : null}
                        </td>
                        <td>{single.name}</td>
                        <td>{Util.formatDatetime(single.created_at)}</td>
                        <td>
                            {Identity.id === this.props.manager ? <> <a onClick={() => this.edit(single, 'information')}
                             className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></a> <a
                             onClick={() => this.toggleDelete(single, 'information')} className="table-edit delete">
                                 <FontAwesomeIcon icon={faTrash} /></a> </> : null}
                        </td>
                    </tr>
                )) : <tr><td colSpan={4} className="font-weight-bold">No results.</td></tr>
            )
        } else {
            millInformation = Util.tableLoader(4, 5);
        }


        let millCertificatesStandards;
        if (this.state.loaded) {
            millCertificatesStandards = (
                this.state.millCertificatesList ? this.state.millCertificatesList.map(single => (
                    <tr key={single.id}>
                        <td>
                            {single.attachment ? (<Link to="#" onClick={() => this.fileView(single.attachment, single.certificate.name)}><FontAwesomeIcon icon={faFile} /> </Link>) : null}
                        </td>
                        <td>{single.certificate ? single.certificate.name : ""}</td>
                        <td>{single.end_date}</td>
                        <td>
                            {Identity.id === this.props.manager ? <> <a onClick={() => this.edit(single, 'certificatesStandards')}
                             className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></a> <a
                              onClick={() => this.toggleDelete(single, 'certificatesStandards')}
                              className="table-edit delete"><FontAwesomeIcon icon={faTrash} /></a> </> : null}
                        </td>
                    </tr>
                )) : <tr><td colSpan={4} className="font-weight-bold">No results.</td></tr>
            )
        } else {
            millCertificatesStandards = Util.tableLoader(4, 5);
        }


        let millProducts;
        if (this.state.loaded) {
            millProducts = (
                this.state.millProductsList ? this.state.millProductsList.map(single => (
                    <tr key={single.id}>
                        <td>{single.product_category ? single.product_category.name : ""}</td>
                        <td>{single.od_min + "-" + single.od_max}</td>
                        <td>{single.wt_min + " - " + single.wt_max}</td>
                        <td>{single.material_grade_category ? single.material_grade_category.abbreviation : ""}</td>
                        <td>{single.grades ? single.grades : ""}</td>
                        <td>
                            {Identity.id === this.props.manager ? <> <a onClick={() => this.edit(single, 'millProducts')}
                             className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></a> <a
                             onClick={() => this.toggleDelete(single, 'millProducts')}
                              className="table-edit delete"><FontAwesomeIcon icon={faTrash} /></a> </> : null}
                        </td>
                    </tr>
                )) : <tr><td colSpan={6} className="font-weight-bold">No results.</td></tr>
            )
        } else {
            millProducts = Util.tableLoader(6, 5);
        }


        let addModal;
        if (this.state.tableName === 'millContact') {
            let millContactButton;
            if (!this.state.waitForUpload) {
                millContactButton = (<Button outline color="primary" onClick={this.formHandlingUtilContact.getSubmitHandle()}>Add</Button>)
            } else {
                millContactButton = (<Button outline color="primary"><Spinner color="secondary" size="sm" className="sppiner-button" /> Add</Button>)
            }
            addModal = (
                <Modal centered size="md" isOpen={this.state.modalAdd} fade={false} toggle={this.add}>
                    <ModalHeader toggle={this.add}>Add mill contact</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <TextInput
                                    label="Name"
                                    id="name"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.millContactInputs.name}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Job Title"
                                    id="jobtitle"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.millContactInputs.job_title}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="E-mail"
                                    id="email"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.millContactInputs.email}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Mobile phone"
                                    id="mobilephone"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.millContactInputs.mobile_phone}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Landline"
                                    id="landline"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.millContactInputs.phone}
                                />
                            </FormGroup>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.add}>Cancel</Button>
                            {millContactButton}
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )
        } else if (this.state.tableName === 'approvals') {
            let millApprovalsButton;
            if (!this.state.waitForUpload) {
                millApprovalsButton = (<Button outline color="primary" onClick={this.formHandlingUtilApproval.getSubmitHandle()}>Add</Button>)
            } else {
                millApprovalsButton = (<Button outline color="primary" ><Spinner color="secondary" size="sm" className="sppiner-button" /> Add</Button>)
            }
            addModal = (
                <Modal centered size="md" isOpen={this.state.modalAdd} fade={false} toggle={this.add}>
                    <ModalHeader toggle={this.add}>Add mill approvals</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Col xl="12">
                                <FormGroup>
                                    <TextInput
                                        label="Name"
                                        id="name"
                                        onBlur={this.formHandlingUtilApproval.getValidateHandle()}
                                        ref={this.millApprovalInputs.name}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xl="12">
                                <FormGroup>
                                    <DateInput
                                        label="Expiry"
                                        id="expiry"
                                        onBlur={this.formHandlingUtilApproval.getValidateHandle()}
                                        ref={this.millApprovalInputs.end_date}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xl="12">
                                <FormGroup>
                                    <TextInput
                                        label="Add file"
                                        id="approvals"
                                        placeholder="Approval attachment"
                                        ref={this.millApprovalInputs.attachment}
                                        disabled
                                        class="upload-text-input-single"
                                    />

                                    <label className="uploadButton uploadButton-single" onChange={(e) => this.addFile(this.millApprovalInputs.attachment, e)}>
                                        <input type="file" accept="application/pdf" />
                                        <FontAwesomeIcon icon={faPlus} />
                                    </label>

                                </FormGroup>
                            </Col>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.add}>Cancel</Button>
                            {millApprovalsButton}
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )
        } else if (this.state.tableName === 'information') {
            let millInformationButton;
            if (!this.state.waitForUpload) {
                millInformationButton = (<Button outline color="primary" onClick={this.formHandlingUtilInformation.getSubmitHandle()}>Add</Button>)
            } else {
                millInformationButton = (<Button outline color="primary" ><Spinner color="secondary" size="sm" className="sppiner-button" /> Add</Button>)
            }
            addModal = (
                <Modal centered size="md" isOpen={this.state.modalAdd} fade={false} toggle={this.add}>
                    <ModalHeader toggle={this.add}>Add mill information</ModalHeader>
                    <ModalBody>
                        <Form>

                            <Col xl="12">
                                <FormGroup>
                                    <TextInput
                                        label="Name"
                                        id="name"
                                        onBlur={this.formHandlingUtilInformation.getValidateHandle()}
                                        ref={this.millInformationInputs.name}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xl="12">
                                <FormGroup>
                                    <TextInput
                                        label="Add file"
                                        id="approval_attachment"
                                        placeholder="Attachment"
                                        ref={this.millInformationInputs.attachment}
                                        disabled
                                        class="upload-text-input-single"
                                    />

                                    <label className="uploadButton uploadButton-single" onChange={(e) => this.addFile(this.millInformationInputs.attachment, e)}>
                                        <input type="file" accept="application/pdf" />
                                        <FontAwesomeIcon icon={faPlus} />
                                    </label>
                                </FormGroup>
                            </Col>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.add}>Cancel</Button>
                            {millInformationButton}
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )
        } else if (this.state.tableName === 'certificatesStandards') {
            let millCertificatesStandardsButton;
            if (!this.state.waitForUpload) {
                millCertificatesStandardsButton = (<Button outline color="primary" onClick={this.formHandlingUtilCertificate.getSubmitHandle()}>Add</Button>)
            } else {
                millCertificatesStandardsButton = (<Button outline color="primary" ><Spinner color="secondary" size="sm" className="sppiner-button" /> Add</Button>)
            }
            addModal = (
                <Modal centered size="md" isOpen={this.state.modalAdd} fade={false} toggle={this.add}>
                    <ModalHeader toggle={this.add}>Add mill certificates & standards</ModalHeader>
                    <ModalBody>
                        <Form>

                            <Col xl="12">
                                <FormGroup>
                                    <DropdownInput

                                        listItems={this.state.certificates}
                                        label="Certificate"
                                        id="certificate_id"
                                        onBlur={this.formHandlingUtilCertificate.getValidateHandle()}
                                        ref={this.millCertificateInputs.certificate_id}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xl="12">
                                <FormGroup>
                                    <DateInput
                                        label="Expiry"
                                        id="expiry"
                                        onBlur={this.formHandlingUtilCertificate.getValidateHandle()}
                                        ref={this.millCertificateInputs.end_date}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xl="12">
                                <FormGroup>
                                    <TextInput
                                        label="Add file"
                                        id="certificate_attachment"
                                        placeholder="Certificate attachment"
                                        ref={this.millCertificateInputs.attachment}
                                        disabled
                                        class="upload-text-input-single"
                                    />

                                    <label className="uploadButton uploadButton-single" onChange={(e) => this.addFile(this.millCertificateInputs.attachment, e)}>
                                        <input type="file" accept="application/pdf" />
                                        <FontAwesomeIcon icon={faPlus} />
                                    </label>

                                </FormGroup>
                            </Col>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.add}>Cancel</Button>
                            {millCertificatesStandardsButton}
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )
        } else if (this.state.tableName === 'millProducts') {
            addModal = (
                <Modal centered size="md" isOpen={this.state.modalAdd} fade={false} toggle={this.add}>
                    <ModalHeader toggle={this.add}>Add mill products</ModalHeader>
                    <ModalBody>
                        <Form>

                            <FormGroup>
                                <DropdownInput

                                    label="Products category"
                                    id="productcategory"
                                    listItems={this.state.productCategories}
                                    onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                    ref={this.millProductInputs.product_category_id}
                                />
                            </FormGroup>

                            <Row>
                                <Col xs="6">
                                    <FormGroup>
                                        <TextInput
                                            label="OD MIN"
                                            id="od-min"
                                            onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                            ref={this.millProductInputs.od_min}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup>
                                        <TextInput
                                            label="OD MAX"
                                            id="od-max"
                                            onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                            ref={this.millProductInputs.od_max}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="6">
                                    <FormGroup>
                                        <TextInput
                                            label="WT MIN"
                                            id="wt-min"
                                            onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                            ref={this.millProductInputs.wt_min}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup>
                                        <TextInput
                                            label="WT MAX"
                                            id="wt-max"
                                            onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                            ref={this.millProductInputs.wt_max}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <DropdownInput
                                    label="Material grade categories"
                                    id="materialgradecategories"
                                    listItems={this.state.materialGradeCategories}
                                    onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                    ref={this.millProductInputs.material_grade_category_id}

                                />
                            </FormGroup>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.add}>Cancel</Button>
                            <Button outline color="primary" onClick={this.formHandlingUtilProduct.getSubmitHandle()}>Add</Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )
        }

        let deleteModal;
        if (this.state.tableName === 'millInfo') {
            deleteModal = (
                <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.toggleDelete}>
                    <ModalHeader toggle={this.toggleDelete}>Mill name</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this mill?
                </ModalBody>
                    <ModalFooter>
                        <Button outline color="secondary" onClick={this.toggleDelete}>Cancel</Button>
                        <Button outline color="primary" onClick={this.delete}>Delete</Button>
                    </ModalFooter>
                </Modal>
            )
        } else if (this.state.tableName === 'millContact') {
            deleteModal = (
                <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.toggleDelete}>
                    <ModalHeader toggle={this.toggleDelete}>{this.state.currentModelDelete ? this.state.currentModelDelete.name : null}</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this mill contact?
                </ModalBody>
                    <ModalFooter>
                        <Button outline color="secondary" onClick={this.toggleDelete}>Cancel</Button>
                        <Button outline color="primary" onClick={this.delete}>Delete</Button>
                    </ModalFooter>
                </Modal>
            )
        } else if (this.state.tableName === 'approvals') {
            deleteModal = (
                <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.toggleDelete}>
                    <ModalHeader toggle={this.toggleDelete}>{this.state.currentModelDelete ? this.state.currentModelDelete.name : null}</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this mill approval?
                </ModalBody>
                    <ModalFooter>
                        <Button outline color="secondary" onClick={this.toggleDelete}>Cancel</Button>
                        <Button outline color="primary" onClick={this.delete}>Delete</Button>
                    </ModalFooter>
                </Modal>
            )
        } else if (this.state.tableName === 'information') {
            deleteModal = (
                <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.toggleDelete}>
                    <ModalHeader toggle={this.toggleDelete}>{this.state.currentModelDelete ? this.state.currentModelDelete.name : null}</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this mill information?
                </ModalBody>
                    <ModalFooter>
                        <Button outline color="secondary" onClick={this.toggleDelete}>Cancel</Button>
                        <Button outline color="primary" onClick={this.delete}>Delete</Button>
                    </ModalFooter>
                </Modal>
            )
        } else if (this.state.tableName === 'certificatesStandards') {
            deleteModal = (
                <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.toggleDelete}>
                    <ModalHeader toggle={this.toggleDelete}>{this.state.currentModelDelete ? this.state.currentModelDelete.name : null}</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this mill certificate or standard?
                </ModalBody>
                    <ModalFooter>
                        <Button outline color="secondary" onClick={this.toggleDelete}>Cancel</Button>
                        <Button outline color="primary" onClick={this.delete}>Delete</Button>
                    </ModalFooter>
                </Modal>
            )
        } else if (this.state.tableName === 'millProducts') {
            deleteModal = (
                <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.toggleDelete}>
                    <ModalHeader toggle={this.toggleDelete}>{this.state.currentModelDelete ? this.state.currentModelDelete.category : null}</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this mill product?
                </ModalBody>
                    <ModalFooter>
                        <Button outline color="secondary" onClick={this.toggleDelete}>Cancel</Button>
                        <Button outline color="primary" onClick={this.delete}>Delete</Button>
                    </ModalFooter>
                </Modal>
            )
        }

        let fileDeleteModal;
        fileDeleteModal = (
            <Modal centered size="md" isOpen={this.state.modalFileDelete} fade={false} toggle={() => this.fileDelete}>
                <ModalHeader toggle={() => this.setState({ modalFileDelete: !this.state.modalFileDelete })}>Delete file</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this?
                    </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalFileDelete: !this.state.modalFileDelete })}>Cancel</Button>
                    <Button outline color="primary" onClick={(e) => this.removeFile(this.state.modalFileDeleteAttr, this.state.modalFileDeleteEvent)}>Delete</Button>
                </ModalFooter>
            </Modal>
        )

        let savedMillModal;
        savedMillModal = (
            <Modal centered size="md" isOpen={this.state.modalSavedMill} fade={false} toggle={() => this.savedMillDelete}>
                <ModalHeader toggle={() => this.setState({ modalSavedMill: !this.state.modalSavedMill })}>Removed mill from saved mills</ModalHeader>
                <ModalBody>
                    Are you sure you want to remove this?
                    </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalSavedMill: !this.state.modalSavedMill })}>Cancel</Button>
                    <Button outline color="primary" onClick={(e) => this.deleteSavedMill(this.state.modalSavedMillInfo)}>Delete</Button>
                </ModalFooter>
            </Modal>
        )

        let fileModal;
        let pdfFile = <embed src={this.state.currentModelFile.file} type="application/pdf" />

        fileModal = (
            <Modal centered size="xl" isOpen={this.state.modalFile} fade={false} toggle={() => this.setState({ modalFile: !this.state.modalFile })}>
                <ModalHeader toggle={() => this.setState({ modalFile: !this.state.modalFile })}>{this.state.currentModelFile.title ? this.state.currentModelFile.title : 'Attachment'}</ModalHeader>
                <ModalBody>
                    {pdfFile}
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalFile: !this.state.modalFile })}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )


        let editModal;
        if (this.state.tableName === 'millInfo') {
            let millInfoButton;
            if (!this.state.waitForUpload) {
                millInfoButton = (<Button outline color="primary" onClick={this.formHandlingUtilMill1.getSubmitHandle()}>Save</Button>)
            } else {
                millInfoButton = (<Button outline color="primary" disable ><Spinner color="secondary" size="sm" className="sppiner-button" /> Save</Button>)
            }
            editModal = (
                <Modal centered size="md" isOpen={this.state.modalEdit} fade={false} toggle={this.edit}>
                    <ModalHeader toggle={this.edit}>Edit mill</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <TextInput
                                    label="Name"
                                    id="Name"
                                    onBlur={this.formHandlingUtilMill1.getValidateHandle()}
                                    ref={this.millInputs1.name}
                                />
                            </FormGroup>
                            <FormGroup>
                                <DropdownInput

                                    listItems={this.state.countries}
                                    label="Country"
                                    id="Country"
                                    onBlur={this.formHandlingUtilMill1.getValidateHandle()}
                                    ref={this.millInputs1.country_id}
                                />

                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="City"
                                    id="City"
                                    onBlur={this.formHandlingUtilMill1.getValidateHandle()}
                                    ref={this.millInputs1.city}
                                />
                            </FormGroup>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.edit}>Cancel</Button>
                            {millInfoButton}
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )
        } else if (this.state.tableName === 'millContact') {
            let millContactSaveButton;
            if (!this.state.waitForUpload) {
                millContactSaveButton = (<Button outline color="primary" onClick={this.formHandlingUtilContact.getSubmitHandle()}>Save</Button>)
            } else {
                millContactSaveButton = (<Button outline color="primary" disabled><Spinner color="secondary" size="sm" className="sppiner-button" /> Save</Button>)
            }
            editModal = (
                <Modal centered size="lg" isOpen={this.state.modalEdit} fade={false} toggle={this.edit}>
                    <ModalHeader toggle={this.edit}>{this.state.currentModelEdit ? this.state.currentModelEdit.name : null}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <TextInput
                                    label="Name"
                                    id="name"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.millContactInputs.name}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Job Title"
                                    id="jobtitle"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.millContactInputs.job_title}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="E-mail"
                                    id="email"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.millContactInputs.email}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Mobile phone"
                                    id="mobilephone"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.millContactInputs.mobile_phone}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Landline"
                                    id="landline"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.millContactInputs.phone}
                                />
                            </FormGroup>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.edit}>Cancel</Button>
                            {millContactSaveButton}
                        </ModalFooter>
                    </ModalBody>
                </Modal >
            )
        } else if (this.state.tableName === 'qualityAudits') {
            let millQualityAudits;
            if (!this.state.waitForUpload) {
                millQualityAudits = (<Button outline color="primary" onClick={this.formHandlingUtilMill2.getSubmitHandle()}>Save</Button>)
            } else {
                millQualityAudits = (<Button outline color="primary" disabled ><Spinner color="secondary" size="sm" className="sppiner-button" /> Save</Button>)
            }
            editModal = (
                <Modal centered size="md" isOpen={this.state.modalEdit} fade={false} toggle={this.edit}>
                    <ModalHeader toggle={this.edit}>Edit mill quality & audits</ModalHeader>
                    <ModalBody>
                        <Form>

                            <Row>
                                <Col md="12" className="formcolor1">
                                    <FormGroup>
                                        <DateInput
                                            label="VL AML"
                                            id="vlaml"
                                            onBlur={this.formHandlingUtilMill2.getValidateHandle()}
                                            ref={this.millInputs2.aml_since}
                                        />
                                    </FormGroup>
                                    <Row>
                                        <Col xl="6">
                                            <FormGroup>
                                                <DropdownInput
                                                    listItems={MillModel.amlClassDropdownItems()}
                                                    label="AML CLASS"
                                                    id="amlclass"
                                                    onBlur={this.formHandlingUtilMill2.getValidateHandle()}
                                                    ref={this.millInputs2.aml_class}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl="6">
                                            <FormGroup>
                                                <DropdownInput

                                                    listItems={this.state.qStatuses}
                                                    label="QUALITY STATUS"
                                                    id="qualitystatus"
                                                    placeholder="Quality status"
                                                    onBlur={this.formHandlingUtilMill2.getValidateHandle()}
                                                    ref={this.millInputs2.quality_status_id}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl="12" className="fromcolor3">
                                    <Row>
                                        <Col xl="6">
                                            <FormGroup>
                                                <DropdownInput
                                                    listItems={MillModel.typeDropdownItems()}
                                                    label="TYPE"
                                                    id="type"
                                                    onBlur={this.formHandlingUtilMill2.getValidateHandle()}
                                                    ref={this.millInputs2.type}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xl="6">
                                            <FormGroup>
                                                <DateInput
                                                    label="LAST AUDIT Date"
                                                    id="lastaudit-date"
                                                    onBlur={this.formHandlingUtilMill2.getValidateHandle()}
                                                    ref={this.millInputs2.last_audit_date}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xl="6">
                                            <FormGroup>
                                                <Label for="lastaudit-file">LAST AUDIT File</Label>

                                                <TextInput
                                                    id="mill"
                                                    placeholder="Last audit atatchment"
                                                    ref={this.millInputs2.last_audit_attachment}
                                                    disabled
                                                    class="upload-text-input"
                                                />

                                                <label className="uploadButton" onChange={(e) => this.addFile(this.millInputs2.last_audit_attachment, e)}>
                                                    <input type="file" accept="application/pdf" />
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </label>

                                                <label className="deleteButton" onClick={(e) => this.removeFile("mill", e)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </label>
                                            </FormGroup>
                                        </Col>
                                        <Col xl="6">
                                            <FormGroup>
                                                <DateInput
                                                    label="FIRST AUDIT TO COME Date"
                                                    id="firstaudittocome-date"
                                                    onBlur={this.formHandlingUtilMill2.getValidateHandle()}
                                                    ref={this.millInputs2.next_audit_date}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.edit}>Cancel</Button>
                            {millQualityAudits}
                        </ModalFooter>
                    </ModalBody>
                </Modal >
            )
        } else if (this.state.tableName === 'approvals') {
            let millApproval;
            if (!this.state.waitForUpload) {
                millApproval = (<Button outline color="primary" onClick={this.formHandlingUtilApproval.getSubmitHandle()}>Save</Button>)
            } else {
                millApproval = (<Button outline color="primary"><Spinner color="secondary" size="sm" className="sppiner-button" /> Save</Button>)
            }
            editModal = (
                <Modal centered size="md" isOpen={this.state.modalEdit} fade={false} toggle={this.edit}>
                    <ModalHeader toggle={this.edit}>{this.state.currentModelEdit ? this.state.currentModelEdit.name : null}</ModalHeader>
                    <ModalBody>
                        <Form>

                            <Col xl="12">
                                <FormGroup>
                                    <TextInput
                                        label="Name"
                                        id="name"
                                        onBlur={this.formHandlingUtilApproval.getValidateHandle()}
                                        ref={this.millApprovalInputs.name}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl="12">
                                <FormGroup>
                                    <DateInput
                                        label="Expiry"
                                        id="expiry"
                                        onBlur={this.formHandlingUtilApproval.getValidateHandle()}
                                        ref={this.millApprovalInputs.end_date}
                                    />
                                </FormGroup>
                            </Col>


                            <Col xl="12">
                                <FormGroup>
                                    <TextInput
                                        label="Add file"
                                        id="approvals"
                                        placeholder="Approval attachment"
                                        ref={this.millApprovalInputs.attachment}
                                        disabled
                                        class="upload-text-input-single"
                                    />

                                    <label className="uploadButton uploadButton-single" onChange={(e) => this.addFile(this.millApprovalInputs.attachment, e)}>
                                        <input type="file" accept="application/pdf" />
                                        <FontAwesomeIcon icon={faPlus} />
                                    </label>

                                </FormGroup>
                            </Col>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.edit}>Cancel</Button>
                            {millApproval}
                        </ModalFooter>
                    </ModalBody>
                </Modal >
            )
        } else if (this.state.tableName === 'information') {
            let millInformation;
            if (!this.state.waitForUpload) {
                millInformation = (<Button outline color="primary" onClick={this.formHandlingUtilInformation.getSubmitHandle()}>Save</Button>)
            } else {
                millInformation = (<Button outline color="primary"><Spinner color="secondary" size="sm" className="sppiner-button" /> Save</Button>)
            }
            editModal = (
                <Modal centered size="md" isOpen={this.state.modalEdit} fade={false} toggle={this.edit}>
                    <ModalHeader toggle={this.edit}>{this.state.currentModelEdit ? this.state.currentModelEdit.name : null}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Col xl="12">
                                <FormGroup>
                                    <TextInput
                                        label="Name"
                                        id="name"
                                        onBlur={this.formHandlingUtilInformation.getValidateHandle()}
                                        ref={this.millInformationInputs.name}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xl="12">
                                <FormGroup>
                                    <TextInput
                                        label="Add file"
                                        id="approval_attachment"
                                        placeholder="Approval attachment"
                                        ref={this.millInformationInputs.attachment}
                                        disabled
                                        class="upload-text-input-single"
                                    />

                                    <label className="uploadButton uploadButton-single" onChange={(e) => this.addFile(this.millInformationInputs.attachment, e)}>
                                        <input type="file" accept="application/pdf" />
                                        <FontAwesomeIcon icon={faPlus} />
                                    </label>
                                </FormGroup>
                            </Col>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.edit}>Cancel</Button>
                            {millInformation}
                        </ModalFooter>
                    </ModalBody>
                </Modal >
            )
        } else if (this.state.tableName === 'certificatesStandards') {
            let millCertificatesStandards;
            if (!this.state.waitForUpload) {
                millCertificatesStandards = (<Button outline color="primary" onClick={this.formHandlingUtilCertificate.getSubmitHandle()}>Save</Button>)
            } else {
                millCertificatesStandards = (<Button outline color="primary"><Spinner color="secondary" size="sm" className="sppiner-button" /> Save</Button>)
            }
            editModal = (
                <Modal centered size="md" isOpen={this.state.modalEdit} fade={false} toggle={this.edit}>
                    <ModalHeader toggle={this.edit}>{this.state.currentModelEdit && this.state.currentModelEdit.certificate ? this.state.currentModelEdit.certificate.name : null}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Col xl="12">
                                <FormGroup>
                                    <DropdownInput

                                        listItems={this.state.certificates}
                                        label="Certificate"
                                        id="certificate_id"
                                        onBlur={this.formHandlingUtilCertificate.getValidateHandle()}
                                        ref={this.millCertificateInputs.certificate_id}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xl="12">
                                <FormGroup>
                                    <DateInput
                                        label="Expiry"
                                        id="expiry"
                                        onBlur={this.formHandlingUtilCertificate.getValidateHandle()}
                                        ref={this.millCertificateInputs.end_date}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xl="12">
                                <FormGroup>
                                    <TextInput
                                        label="Add file"
                                        id="certificate_attachment"
                                        placeholder="Certificate attachment"
                                        ref={this.millCertificateInputs.attachment}
                                        disabled
                                        class="upload-text-input-single"
                                    />

                                    <label className="uploadButton uploadButton-single" onChange={(e) => this.addFile(this.millCertificateInputs.attachment, e)}>
                                        <input type="file" accept="application/pdf" />
                                        <FontAwesomeIcon icon={faPlus} />
                                    </label>
                                </FormGroup>
                            </Col>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.edit}>Cancel</Button>
                            {millCertificatesStandards}
                        </ModalFooter>
                    </ModalBody>
                </Modal >
            )
        } else if (this.state.tableName === 'notes') {
            editModal = (
                <Modal centered size="md" isOpen={this.state.modalEdit} fade={false} toggle={this.edit}>
                    <ModalHeader toggle={this.edit}>Notes</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <TextAreaInput
                                    label="Notes"
                                    id="notes"
                                    onBlur={this.formHandlingUtilMill3.getValidateHandle()}
                                    ref={this.millInputs3.note}
                                />
                            </FormGroup>

                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.edit}>Cancel</Button>
                            <Button outline color="primary" onClick={this.formHandlingUtilMill3.getSubmitHandle()}>Save</Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal >
            )
        } else if (this.state.tableName === 'millProducts') {
            editModal = (
                <Modal centered size="md" isOpen={this.state.modalEdit} fade={false} toggle={this.edit}>
                    <ModalHeader toggle={this.edit}>Mill products</ModalHeader>
                    <ModalBody>
                        <Form>

                            <FormGroup>
                                <DropdownInput

                                    label="Products category"
                                    id="productcategory"
                                    listItems={this.state.productCategories}
                                    onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                    ref={this.millProductInputs.product_category_id}
                                />
                            </FormGroup>

                            <Row>
                                <Col xs="6">
                                    <FormGroup>
                                        <TextInput
                                            label="OD MIN"
                                            id="od-min"
                                            onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                            ref={this.millProductInputs.od_min}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup>
                                        <TextInput
                                            label="OD MAX"
                                            id="od-max"
                                            onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                            ref={this.millProductInputs.od_max}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="6">
                                    <FormGroup>
                                        <TextInput
                                            label="WT MIN"
                                            id="wt-min"
                                            onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                            ref={this.millProductInputs.wt_min}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup>
                                        <TextInput
                                            label="WT MAX"
                                            id="wt-max"
                                            onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                            ref={this.millProductInputs.wt_max}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <DropdownInput
                                    label="Material grade categories"
                                    id="materialgradecategories"
                                    listItems={this.state.materialGradeCategories}
                                    onBlur={this.formHandlingUtilProduct.getValidateHandle()}
                                    ref={this.millProductInputs.material_grade_category_id}

                                />
                            </FormGroup>

                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.edit}>Cancel</Button>
                            <Button outline color="primary" onClick={this.formHandlingUtilProduct.getSubmitHandle()}>Save</Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal >
            )
        }

        let millInfoActions;
        if (Identity.id === this.props.manager) {
            millInfoActions = (
                <>
                    <li><Link to="#" onClick={() => this.edit(null, 'millInfo')} className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></Link></li>
                    <li><Link to="#" onClick={() => this.toggleDelete(this.mill, 'millInfo')} className="table-edit delete"><FontAwesomeIcon icon={faTrash} /></Link></li>
                </>
            )
        }

        let millContactActions;
        if (Identity.id === this.props.manager) {
            millContactActions = (<li><Link to="#" onClick={() => this.add(null, 'millContact')} className="table-edit add"><FontAwesomeIcon icon={faPlus} /></Link></li>)
        }

        let millQualityAuditsActions;
        if (Identity.id === this.props.manager) {
            millQualityAuditsActions = (<li><Link to="#" onClick={() => this.edit(null, 'qualityAudits')} className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></Link></li>)
        }

        let millApprovalActions;
        if (Identity.id === this.props.manager) {
            millApprovalActions = (<li><Link to="#" onClick={() => this.add(null, 'approvals')} className="table-edit add"><FontAwesomeIcon icon={faPlus} /></Link></li>)
        }

        let millCertificatesStandardsActions;
        if (Identity.id === this.props.manager) {
            millCertificatesStandardsActions = (<li><Link to="#" onClick={() => this.add(this.millCertificate, 'certificatesStandards')} className="table-edit add"><FontAwesomeIcon icon={faPlus} /></Link></li>)
        }

        let millInformationActions;
        if (Identity.id === this.props.manager) {
            millInformationActions = (<li><Link to="#" onClick={() => this.add(null, 'information')} className="table-edit add"><FontAwesomeIcon icon={faPlus} /></Link></li>)
        }

        let millNotesActions;
        if (Identity.id === this.props.manager) {
            millNotesActions = (<li><Link to="#" onClick={() => this.edit(this.mill, 'notes')} className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></Link></li>)
        }

        let millProductActions;
        if (Identity.id === this.props.manager) {
            millProductActions = (<li><Link to="#" onClick={() => this.add(this.millProduct, 'millProducts')} className="table-edit add"><FontAwesomeIcon icon={faPlus} /></Link></li>)
        }


        let millSaveAction;
        if (this.state.isMillSaved) {
            millSaveAction = <li><Link to="#" onClick={() => this.savedMillDelete(this.props.id)} className="table-edit savemill"><FontAwesomeIcon icon={faStar} /></Link></li>
        } else {
            millSaveAction = <li><Link to="#" onClick={this.saveMill} className="table-edit savemill"><FontAwesomeIcon icon={faStarNone} /></Link></li>
        }




        return (
            <>

                {viewModal}
                {addModal}
                {editModal}
                {deleteModal}
                {fileDeleteModal}
                {fileModal}
                {savedMillModal}
                <Col xl="4">
                    <div className="element-box">
                        <h2 className="mill">Mill info</h2>
                        <ul className="options">
                            {millSaveAction}
                            {millInfoActions}
                        </ul>
                        <Table hover responsive className="table table-hover primary">
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>{this.state.loaded ? this.mill.name : tableLoader}</td>
                                </tr>
                                <tr>
                                    <th>Country</th>
                                    <td>{this.mill.country ? this.mill.country.name : tableLoader}</td>
                                </tr>
                                <tr>
                                    <th>City</th>
                                    <td>{this.state.loaded ? this.mill.city : tableLoader}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
                <Col xl="8">
                    <div className="element-box">
                        <h2 className="mill">Mill contact</h2>
                        <ul className="options">
                            {millContactActions}
                        </ul>
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Job Title</th>
                                    <th scope="col">E-mail</th>
                                    <th scope="col">Mobile phone</th>
                                    <th scope="col">Landline</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {millContact}
                            </tbody>
                        </Table>
                    </div>
                </Col>

                <Col xl="3">
                    <div className="element-box quality-audits">
                        <h2 className="mill">Quality and Audits</h2>
                        <ul className="options">
                            {millQualityAuditsActions}
                        </ul>
                        <Row>
                            <Col xl="12">
                                <Table hover responsive>
                                    <tbody>
                                        <tr>
                                            <th>VL AML</th>
                                            <td>{this.mill.aml_class ? "Y" : "N"}</td>
                                            <td>{this.mill.aml_since ? this.mill.aml_since : "/"}</td>
                                        </tr>
                                        <tr>
                                            <th>AML CLASS</th>
                                            <td>{this.mill.aml_class}</td>
                                            <td>{(this.mill.type ? this.mill.type.toUpperCase() : "UNKNOWN ") + " SUPPLIER"} </td>
                                        </tr>
                                        <tr>
                                            <th>QUALITY STATUS</th>
                                            <td><PopoverItem key={1} item={{ placement: 'top' }} icon={faInfoCircle} id="qualitystatus" titleP="Quality status" contentP={this.mill.quality_status ? this.mill.quality_status.name : ""} modifiers={true} iconColor="qsc-1" /></td>
                                            <td><i className="status qs-1" style={{ background: this.mill.quality_status ? this.mill.quality_status.color_hex : "transparent" }}></i></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>

                            <Col xl="12" className="mt-1">
                                <div className="audit">
                                    <Table hover responsive>
                                        <tbody>
                                            <tr>
                                                <th>LAST AUDIT</th>
                                                {this.mill.last_audit_attachment ?
                                                    <td> <Link to="#" onClick={() => {this.fileView(this.mill.last_audit_attachment, 'LAST AUDIT')}} ><FontAwesomeIcon icon={faFile} /></Link></td>

                                                    : <td></td>}
                                                <td>{this.mill.last_audit_date}</td>
                                            </tr>
                                            <tr>
                                                <th>FIRST AUDIT TO COME</th>
                                                <td>{null}</td>

                                                <td>{this.mill.next_audit_date}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>

                        </Row>
                    </div>

                </Col>

                <Col xl="3">
                    <div className="element-box">
                        <h2 className="mill">Approvals</h2>
                        <ul className="options">
                            {millApprovalActions}
                        </ul>
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Expiry</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>

                                {millApprovals}

                            </tbody>

                        </Table>
                    </div>
                </Col>

                <Col xl="3">
                    <div className="element-box">
                        <h2 className="mill">Certificates & Standards</h2>
                        <ul className="options">
                            {millCertificatesStandardsActions}
                        </ul>
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Expiry</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {millCertificatesStandards}
                            </tbody>
                        </Table>
                    </div>
                </Col>

                <Col xl="3">
                    <div className="element-box">
                        <h2 className="mill">Mill information</h2>
                        <ul className="options">
                            {millInformationActions}
                        </ul>
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Date created</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>

                                {millInformation}

                            </tbody>

                        </Table>
                    </div>
                </Col>

                <Col xl="12">
                    <div className="element-box">
                        <h2 className="mill">Notes</h2>
                        <ul className="options">
                            {millNotesActions}
                        </ul>
                        <p className="mb-0">{this.mill.note ? this.mill.note : "There are no notes."}</p>
                    </div>
                </Col>


                <Col xl="12">
                    <div className="element-box">
                        <h2 className="mill">Mill products</h2>
                        <ul className="options">
                            {millProductActions}
                        </ul>
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th scope="col">Product category</th>
                                    <th scope="col">OD</th>
                                    <th scope="col">WT</th>
                                    <th scope="col">Material grade categories</th>
                                    <th scope="col">Grades</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {millProducts}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </>
        )
    }
}
