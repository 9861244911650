import * as React from 'react';
import { FormInput, IFormInputProps } from "../FormInput";
import { string } from 'prop-types';
import { Label } from 'reactstrap';

export interface ITextInputProps extends IFormInputProps {
    label?: string,
    placeholder?: string,
    error?: string,
    maxLength?: number,
    id?: string,
    tabIndex?: number,
    autoFocus?: boolean,
    autoComplete?: string,
    disabled?: boolean,
    class?: string,
}

export class TextInput<T extends ITextInputProps = ITextInputProps> extends FormInput<T> {

    protected type: string = "text";

    protected renderLabel(): React.ReactNode {
        return (<Label className={`form-control-label ${!this.props.label ? 'd-none ' : ''}` + this.getErrorClass()} for={this.props.id}>{this.props.label}</Label>
        );

    }

    protected renderInput(): React.ReactNode {
        const additionalProps: any = {};

        if (this.props.maxLength) {
            additionalProps['maxLength'] = this.props.maxLength;
        }

        if (this.props.tabIndex) {
            additionalProps['tabIndex'] = this.props.tabIndex;
        }

        if (this.props.autoFocus) {
            additionalProps['autoFocus'] = this.props.autoFocus;
        }

        if (this.props.autoComplete) {
            additionalProps['autoComplete'] = this.props.autoComplete;
        }

        if (this.props.disabled) {
            additionalProps['disabled'] = true;
        }

        return (<input ref={this.inputRef}
            id={this.props.id}
            placeholder={this.props.placeholder ? this.props.placeholder : ""}
            onBlur={(this.props.onBlur) ? this.props.onBlur : () => {
            }}
            {...additionalProps}
            value={this.props.initialValue}
            onChange={(this.props.onChange) ? this.props.onChange : () => {
            }}
            className={`form-control ${this.props.class ? this.props.class : ''}` + this.getInputErrorClass()} type={this.type} />);
    }

    protected renderError(): React.ReactNode {
        return (<div className={this.getErrorTextErrorClass()}>{(this.error !== null) ? this.error : ""}</div>);
    }

    public getValue(): any {
        return (this.inputRef.current) ? this.inputRef.current.value : "";
    }

    public getReadOnly(val: any): any {
        return val;
    }
}
