import {ValidatorError} from "../../common/components/validators/errors/ValidatorError";

export class ErrorMessages {
    public static getDefaultErrorMessageCallbacks(): {[key: number]: (field: string, additionalParams: any) => string} {
        return {
            [ValidatorError.REQUIRED]: (field: string, additionalParams: any):string => {
                return "Value is required."
            },

            [ValidatorError.FORMAT_IS_NOT_VALID]: (field: string, additionalParams: any):string => {
                return "Format of the value is not valid."
            },

            [ValidatorError.ADEQUATE_VALUE_IS_NOT_SELECTED]: (field: string, additionalParams: any):string => {
                return "Adequate value is not selected."
            },

            [ValidatorError.VALUE_DOES_NOT_MATCH]: (field: string, additionalParams: any):string => {
                return "Value does not match " + additionalParams.attrName + ".";
            },

            [ValidatorError.LENGTH_NOT_IN_BETWEEN]: (field: string, additionalParams: any):string => {
                return "String length must be between " + additionalParams.min + " and " + additionalParams.max + ".";
            },

            [ValidatorError.LENGTH_IS_BELLOW_ALLOWED]: (field: string, additionalParams: any):string => {
                return "Value has to have at least " + additionalParams.min + " character.";
            },

            [ValidatorError.LENGTH_IS_ABOVE_ALLOWED]: (field: string, additionalParams: any):string => {
                return "Value can't have more than" + additionalParams.max + " characters.";
            }
        }
    }
}
