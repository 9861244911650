import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";
import { IUserIdentityParams } from "../../common/identity/UserIdentity";
import Identity from "../user/Identity";
import { EmailValidator } from "../../common/components/validators/EmailValidator";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import { MatchValidator } from "../../common/components/validators/MatchValidator";

export interface IUser {
    id?: number,
    auth_key?: string,
    username?: string,
    full_name?: string,
    abbreviation?: string,
    email?: string,
    status_id?: number,
    password?: string
    password_confirmed?: string;
    password_reset_token?: string;
    remember_me?: boolean;
    item_name?: string;
}


export class User extends DataModel<IUser> {

    private resourceName = "user";
    private resourceNamePlural = "users";

    private _id: number;
    private _status_id: number;
    private _auth_key: string;
    private _username: string;
    private _full_name: string;
    private _abbreviation: string;
    private _email: string;
    private _password?: string;
    private _password_confirmed?: string;
    private _password_reset_token: string;
    private _remember_me: boolean = false;
    private _item_name: string;

    public static SCENARIO = {
        LOGIN: 'login',
        CREATE: 'create',
        UPDATE: 'update',
        FORGOTTEN_PASSWORD: 'forgottenPassword',
        CHANGE_PASSWORD_EMAIL: 'changePasswordEmail',
        CHANGE_PASSWORD: 'changePassword'
    };
    protected getDefaultValues(): IUser {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IUser> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IUser> {
        switch (scenario) {
            case User.SCENARIO.LOGIN:
                return new DataModelValidator<IUser>({
                    username: [new RequiredValidator(), new LengthValidator({ min: 4 })],
                    password: [new RequiredValidator(), new LengthValidator({ min: 7 })]
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case User.SCENARIO.CREATE:
                return new DataModelValidator<IUser>({
                    username: [new RequiredValidator(), new LengthValidator({ min: 4 })],
                    email: [new RequiredValidator(), new EmailValidator()],
                    full_name: [new RequiredValidator()],
                    item_name: [new RequiredValidator()],
                    abbreviation: [new RequiredValidator(), new LengthValidator({ min: 3 })],
                    password: [new RequiredValidator(), new LengthValidator({ min: 7 })],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case User.SCENARIO.UPDATE:
                return new DataModelValidator<IUser>({
                    username: [new RequiredValidator(), new LengthValidator({ min: 4 })],
                    email: [new RequiredValidator(), new EmailValidator()],
                    full_name: [new RequiredValidator()],
                    item_name: [new RequiredValidator()],
                    abbreviation: [new RequiredValidator(), new LengthValidator({ min: 3 })],
                    password: [ new LengthValidator({ min: 7 })],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());

            case User.SCENARIO.FORGOTTEN_PASSWORD:
                return new DataModelValidator<IUser>({
                    email: [new RequiredValidator(), new EmailValidator()]
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case User.SCENARIO.CHANGE_PASSWORD_EMAIL:
                return new DataModelValidator<IUser>({
                    email: [
                        new RequiredValidator(),
                        new EmailValidator()
                    ],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());

            case User.SCENARIO.CHANGE_PASSWORD:
                return new DataModelValidator<IUser>({
                    password: [
                        new RequiredValidator(),
                        new LengthValidator({
                            min: 7
                        }),
                        new MatchValidator(
                            {
                                toBeMatchedWithCallback: (data: IUser) => {
                                    return data.password_confirmed;
                                },
                                attrName: "Confirm password"
                            }
                        )
                    ],
                    password_confirmed: [
                        new RequiredValidator(),
                        new LengthValidator({
                            min: 7
                        }),
                        new MatchValidator(
                            {
                                toBeMatchedWithCallback: (data: IUser) => {
                                    return data.password;
                                },
                                attrName: "Password"
                            }
                        )
                    ]
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            default:
                return new DataModelValidator<IUser>({
                    username: [new RequiredValidator(), new LengthValidator({ min: 4 })],
                    email: [new RequiredValidator(), new EmailValidator()],
                    full_name: [new RequiredValidator()],
                    item_name: [new RequiredValidator()],
                    abbreviation: [new RequiredValidator(), new LengthValidator({ min: 3 })],
                    password: [ new LengthValidator({ min: 7 })],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
        }
    }

    protected modelReducer(state: IDataModelState<IUser>, action: AnyAction): IDataModelState<IUser> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource('users').getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getCurrentUser(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + '/current').getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const users: IUser[] = response.data as IUser[];

                const result: IDropdownListItem[] = [];

                users.forEach((user: IUser) => {
                    result.push({
                        id: user.id,
                        name: user.username
                    });
                });

                return result;
            });
    }

    public loadById(id: number): Promise<IUser | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public loadByUsername(username: string): Promise<IUser | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + username + "/username")
            .getOperation({});
    }

    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                username: this._username,
                email: this._email,
                password: this._password,
                full_name: this._full_name,
                abbreviation: this._abbreviation,
                item_name: this._item_name,
            });
    }


    public login(data: IUser): Promise<any> {
        this.setFromPlainObject(data);

        return this.createDataSource(this.resourceName)
            .addCustomPath("/login")
            .plainPostOperation({
                username: data.username,
                password: data.password

            })
            .then((userInfo: any) => {

                userInfo.data.role = userInfo.data.item_name;

                if (userInfo.data.auth_key_expires_at) {
                    userInfo.data.auth_key_expires_at = new Date(userInfo.data.auth_key_expires_at);

                }

                return userInfo.data;
            })
            .then((userInfo: IUserIdentityParams | AxiosError) => {

                const userIdentity: IUserIdentityParams = userInfo as IUserIdentityParams;

                userIdentity.remember_me = this.remember_me;

                if (userIdentity.auth_key) {
                    UserIdentity.setUserIdentity(userIdentity);
                    return userInfo;
                } else {
                    return userInfo as AxiosError;
                }
            });
    }

    public verifyToken(token: string): Promise<AxiosError | AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath("/verify-data/check-token/" + token)
            .plainGetOperation({});
    }


    public logout(): Promise<AxiosError | IUser> {
        return this.createDataSource(this.resourceName)
            .addCustomPath("/logout")
            .postOperation({});
    }

    public addFavoriteMill(millId: number | string): Promise<AxiosError | AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath("/favorite-mill/" + millId)
            .plainPostOperation({});
    }

    public favorites(): Promise<AxiosError | AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath("/favorite-mill")
            .plainGetOperation({});
    }


    public delFavorite(millId: number | string): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath("/favorite-mill/" + millId)
            .plainDelOperation();
    }

    public updatePassword(data: IUser): Promise<AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath('/current/change-password')
            .plainPatchOperation({
                password: data.password
            });
    }

    public uploadImage(data: FormData): Promise<AxiosResponse> {

        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + this.resourceName,
            {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + Identity.auth_key
            })
            .addCustomPath('/upload-image')
            .plainPostOperation(data);
    }

    public saveImage(data: IUser): Promise<AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id + '/save-image')
            .plainPostOperation(data);
    }

    public forgottenPasswordRequest(data: IUser): Promise<AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath('/reset-password/request')
            .plainPostOperation({ email: data.email });
    }

    public resetPassword(token: string): Promise<AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath('/reset-password/set-password')
            .plainPostOperation({
                password: this.password,
                password_reset_token: token
            });
    }

    public forgottenPasswordCheckToken(token: string): Promise<AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath('/reset-password/check-token/' + token)
            .plainGetOperation({});
    }

    public update(data: IUser): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                username: data.username,
                email: data.email,
                password: data.password,
                full_name: data.full_name,
                abbreviation: data.abbreviation,
                item_name: data.item_name,
            });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "USER";
    }

    protected setFromObj(data: IUser): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._username = DataModel.safeGet(data.username, this._username);
        this._full_name = DataModel.safeGet(data.full_name, this._full_name);
        this._abbreviation = DataModel.safeGet(data.abbreviation, this._abbreviation);
        this._password_confirmed = DataModel.safeGet(data.password_confirmed, this._password_confirmed);
        this._password = DataModel.safeGet(data.password, this._password);
        this._email = DataModel.safeGet(data.email, this._email);
        this._remember_me = DataModel.safeGet(data.remember_me, this._remember_me);
        this._item_name = DataModel.safeGet(data.item_name, this._item_name);
    }


    protected toObj(): IUser {
        return {
            id: this._id,
            username: this._username,
            full_name: this._full_name,
            abbreviation: this._abbreviation,
            password: this._password,
            password_confirmed: this._password_confirmed,
            email: this._email,
            remember_me: this._remember_me,
            item_name: this._item_name,
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get username() {
        return this._username;
    }

    get password() {
        return this._password;
    }

    get password_confirmed() {
        return this._password_confirmed;
    }

    get auth_key() {
        return this._auth_key;
    }

    get email() {
        return this._email;
    }

    get full_name() {
        return this._full_name;
    }

    get abbreviation() {
        return this._abbreviation;
    }

    get status_id() {
        return this._status_id;
    }

    set status_id(status_id: number) {
        this._status_id = status_id;
    }

    get remember_me() {
        return this._remember_me;
    }

    get item_name() {
        return this._item_name;
    }

}
