import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";

export interface ICountry {
    id?: number,
    name?: string
}


export class Country extends DataModel<ICountry> {

    public resourceName = "country";
    public resourceNamePlural = "countries";

    private _id: number;
    private _name: string;

    protected getDefaultValues(): ICountry {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<ICountry> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ICountry> {
        return new DataModelValidator<ICountry>({
            name: [new RequiredValidator(), new LengthValidator({ min: 6 })],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<ICountry>, action: AnyAction): IDataModelState<ICountry> {
        return state;
    }

    public loadCountries(): Promise<ICountry[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<ICountry | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource()
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<ICountry | AxiosError> {

        return this.createDataSource(this.resourceName)
            .postOperation({ name: this._name });
    }

    public update(data: ICountry): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({ name: data.name });
    }



    getStoreKey(): string {
        return "COUNTRY";
    }

    protected setFromObj(data: ICountry): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
    }


    protected toObj(): ICountry {
        return {
            id: this._id,
            name: this._name
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }

}
