import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import { NumberValidator } from "../../common/components/validators/NumberValidator";
import { ICertificateIssuer } from "./CertificateIssuer";

export interface ICertificate {
    id?: number,
    name?: string,
    certificate_issuer_id?: number
    certificate_issuer?: ICertificateIssuer
}


export class Certificate extends DataModel<ICertificate> {

    public resourceName = "certificate";
    public resourceNamePlural = "certificates";

    private _id: number;
    private _name: string;
    private _certificate_issuer_id: number;
    private _certificate_issuer: ICertificateIssuer;

    protected getDefaultValues(): ICertificate {
        return {
            certificate_issuer: {}
        };
    }

    public createDataSource(resourceName: string = this.resourceName): RestDataSource<ICertificate> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ICertificate> {
        return new DataModelValidator<ICertificate>({
            name: [new RequiredValidator(), new LengthValidator({ min: 4 })],
            certificate_issuer_id: [new RequiredValidator(), new NumberValidator()],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<ICertificate>, action: AnyAction): IDataModelState<ICertificate> {
        return state;
    }

    public loadCertificateIssuers(): Promise<ICertificate[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<ICertificate | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public createNew(): Promise<ICertificate | AxiosError> {
        return this.createDataSource(this.resourceName)
            .postOperation({ 
                name: this._name,
                certificate_issuer_id: Number(this._certificate_issuer_id)
            });
    }
 
    public del(id: number): Promise<void> {
        return this.createDataSource()
            .addCustomPath('/' + id)
            .plainDelOperation();
    }

    public update(data: ICertificate): Promise<AxiosResponse> {
        return this.createDataSource()
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                name: data.name,
                certificate_issuer_id: Number(data.certificate_issuer_id)
            }
        );
    }


    getStoreKey(): string {
        return "CERTIFICATE";
    }

    protected setFromObj(data: ICertificate): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._certificate_issuer_id = DataModel.safeGet(data.certificate_issuer_id, this._certificate_issuer_id);
        this._certificate_issuer = DataModel.safeGet(data.certificate_issuer, this._certificate_issuer);
    }


    protected toObj(): ICertificate {
        return {
            id: this._id,
            name: this._name,
            certificate_issuer_id: this._certificate_issuer_id,
            certificate_issuer: this._certificate_issuer,
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }

    get certificate_issuer_id() {
        return this._certificate_issuer_id
    }

    get certificate_issuer() {
        return this._certificate_issuer
    }

}
