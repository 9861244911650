import React, { Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, ModalFooter } from 'reactstrap';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { Table } from '../../common/components/widgets/table/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { AxiosError, AxiosResponse } from 'axios';
import Util from './Util';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';

interface IDataTable {
  modalDelete?: boolean,
  currentModelDelete?: Object,
  modalFormOpen?: boolean,
  modalEdit?: boolean,
  reload: boolean,
  isCreate: boolean,
}

interface IDataTableProps {
  add?: boolean,
  title: string,
  model: any,
  dataProvider: any,
  titleAttr: string,
  columns: any[],
  loader: Object,
  formElements: any,
  inputs: { [attr: string]: React.RefObject<any> },
  onRef?: any
}

export default class DataTable extends React.Component<IDataTableProps, IDataTable> {
  private submitted = false;
  private modalTitle = "Add new";

  constructor(props) {
    super(props);

    this.delete = this.delete.bind(this);
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.readInputs = this.readInputs.bind(this);
    this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setInputValues = this.setInputValues.bind(this);

    this.state = {
      modalDelete: false,
      currentModelDelete: {},
      modalFormOpen: false,
      modalEdit: false,
      reload: false,
      isCreate: true,

    };
  }

  delete(row?: any) {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
      currentModelDelete: row
    }));
  }

  add() {

    this.modalTitle = "Add new ";

    this.setState(prevState => ({
      modalFormOpen: !prevState.modalFormOpen,
      isCreate: true
    }));
  }

  edit(row?: any) {

    this.modalTitle = "Edit ";

    this.props.model.loadById(row.id)
      .then((response: any) => {
        this.props.model.setFromPlainObject(response);
        this.setInputValues(response);

      })
      .catch((error: AxiosError) => {
        Util.notification("error", "Data could not be loaded", 1500);
      });

    this.setState(prevState => ({
      isCreate: false,
      modalFormOpen: !prevState.modalFormOpen
    }));
  }


  componentDidMount() {

    this.props.onRef(this)
  }

  setInputValues(values: any) {

    let inputs = this.props.inputs;

    for (let key in values) {
      if (inputs[key] && inputs[key].current) {
        inputs[key].current.setValue(values[key]);
      }
    }
  }


  protected handleSubmit(event: React.MouseEvent<HTMLElement>): void {
    let promise: Promise<AxiosResponse>;
    this.submitted = true;

    if (this.validate(event)) {

      if (this.state.isCreate) {
        promise = this.props.model.createNew();
      } else {
        promise = this.props.model.update(this.props.model);
      }

      promise.then((response: AxiosResponse) => {

        Util.notification("success", "Success.", 2000);
        this.setState({ reload: true, modalFormOpen: false, modalEdit: false })

        if (this.state.reload) {
          setTimeout(function () {
            window.location.reload();
          }.bind(this), 2500)
        }

      })
        .catch((error: any) => {
          const aError: any = error;

          switch (aError.response.status) {
            case 422:

              if (aError.response && aError.response.data && aError.response.data.errors) {
                const respErrors: any[] = aError.response.data.errors;
                const errors: { [attr: string]: string } = {};

                respErrors.forEach((error: any) => {
                  errors[error.fieldName] = error.errorMessage;
                });

                this.fillInputsWithErrors(errors, this.props.inputs);
              }
              break;
            default:

              Util.notification("error", "Error.", 2000);
              break;
          }

          this.setState({ reload: true })
        });
    }
  }


  private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

    for (const key in errors) {
      if (errors.hasOwnProperty(key) && inputs[key] != undefined && inputs[key].current) {
        inputs[key].current.setError(errors[key]);
      }
    }
  }

  private validate(event: any): boolean {
    this.readInputs();

    let inputs = this.props.inputs;
    const valid = this.props.model.validate();

    if (this.submitted && !valid) {
      const errors: { [attr: string]: string } = this.props.model.getErrors();
      this.fillInputsWithErrors(errors, inputs);
    }

    event.preventDefault();

    return valid;
  }

  private readInputs(): any {

    const plainObject = {};
    const inputs = this.props.inputs;

    for (const key in inputs) {
      if (inputs.hasOwnProperty(key) && inputs[key].current) {
        plainObject[key] = inputs[key].current.getValue();
        inputs[key].current.removeError();
      }
    }

    this.props.model.setFromPlainObject({ ...this.props.model.asPlainObject(), ...plainObject });

    return plainObject;
  }

  render() {

    let deleteModal;
    deleteModal = (
      <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.delete}>
        <ModalHeader toggle={this.delete}>{this.state.currentModelDelete[this.props.titleAttr]}</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this?
                    </ModalBody>
        <ModalFooter>
          <Button outline color="secondary" onClick={() => this.setState({ modalDelete: !this.state.modalDelete })}>Cancel</Button>
          <Button outline color="primary" onClick={() =>
            (this.props.model).del(this.state.currentModelDelete["id"]).then(() => {
              this.setState(prevState => ({
                modalDelete: !prevState.modalDelete,
              }));
            })

          }>Delete</Button>
        </ModalFooter>
      </Modal>
    )

    let modalForm;
    modalForm = (
      <>
        <ModalHeader toggle={() => this.add()}>{this.modalTitle} {this.props.title}</ModalHeader>
        <ModalBody>
          <Form>
            {this.props.formElements}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button outline color="secondary" onClick={() => this.setState({ modalFormOpen: !this.state.modalFormOpen })}>Cancel</Button>
          <Button outline color="primary" onClick={this.handleSubmit}>{this.state.isCreate ? "Add" : "Update"}</Button>
        </ModalFooter>
      </>
    )

    let modal;
    modal = (
      <Modal centered size="md" isOpen={this.state.modalFormOpen} fade={false} toggle={() => this.add()}>
        {modalForm}
      </Modal>
    )

    return (
      <Fragment>
        <div className="element-box">
          <h2>{this.props.title}</h2>

          {this.props.add
            ? (<ul className="options">
              <li><a onClick={() => this.add()} className="table-edit add"><FontAwesomeIcon icon={faPlus} /></a></li>
            </ul>)
            : null

          }

          <ReduxComponentWrapper component={Table} componentPropsCallback={(state: any) => ({
            provider: this.props.dataProvider,
            loader: this.props.loader,
            searchCallback: (params: IRestDataSourceParams): void => {
              this.props.dataProvider.refreshWithHeaders(
                this.props.model.getListPlain(params)
              )
            },
            columns: this.props.columns,
          })} />
        </div>

        {modal}
        {deleteModal}
      </Fragment>
    );
  }
}

