/**
 * Paths enum to define page paths.
 */
export enum Path {
    HOMEPAGE = "/",

    MEETINGS = "/meetings",

    SUPPLIER_MEETINGS = "/meetings/:id",

    NEWS = "/news",

    SEARCH = "/search",

    SUPPLIER_SINGLE = "/supplier/:id",

    SUPPLIER_MILL = "/supplier/:id/mill/:millid",

    SUPPLIERS = "/suppliers",

    USERS = "/users",

    LOGIN = "/login",

    FORGOTTEN_PASSWORD = "/forgotten-password",

    NEW_PASSWORD = "/new-password/",

    FORGOTTEN_PASSWORD_CHANGE = "/new-password/:token",

    NOTFOUND = "/404-page",

    FORBIDDEN = "/forbidden",

    GENERAL_SETTINGS = "/general-settings",

    PROFILE_SETTINGS = "/profile-settings",

    LOADING = "/loading", //Test loadinga
}
