import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import Util from '../custom/Util';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import { Search, ISearch } from '../models/Search';
import * as qs from 'query-string';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { Table } from '../../common/components/widgets/table/Table';
import { DataProvider } from '../../common/model/DataProvider';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Identity from '../user/Identity';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';

@withRouter(Path.SEARCH.toString(), PageContainer)
@withSecurity([Role.ADMIN.toString(), Role.CATEGORY_MANAGER.toString(), Role.USER.toString()], Identity, Path.LOGIN)
export default class SearchPage extends VlbPage {

    private search: DataProvider<ISearch> = new DataProvider<ISearch>();
    constructor(props) {

        super(props);

        let query = {};

        if (window.location.search !== undefined) {
            query = qs.parse(window.location.search);
        }

        this.removeParam = this.removeParam.bind(this);

        this.state = {
            query
        }
    }


    pageTitle() {
        return "Search" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
        ];
    }

    componentDidMount() {
        Util.topFunction()
    }


    workingOn() {
        Util.notification("success", "Working on it", 1500);
    }

    paramsToObject(entries) {
        let result = {}
        for (let entry of entries) { // each 'entry' is a [key, value] tupple
            const [key, value] = entry;
            result[key] = value;
        }
        return result;
    }

    removeParam(key, sourceURL) {
        var rtn = sourceURL.split("?")[0],
            param,
            params_arr = [],
            queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
        if (queryString !== "") {
            params_arr = queryString.split("&");
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            rtn = rtn + "?" + params_arr.join("&");
        }

        window.history.replaceState({}, null, rtn);
        //window.location.reload()
        this.forceUpdate();
    }

    renderContent() {
        var urlPage = new URL(window.location.href);
        var searchFilters = urlPage.search.substring(1);

        const urlParams = new URLSearchParams(searchFilters);
        const entries = urlParams.entries();
        const listOfParams = this.paramsToObject(entries);


        var result = Object.entries(listOfParams).map(single => (
            <li onClick={() => this.removeParam(single[0], window.location.href )}> {single[0].charAt(0).toUpperCase() + single[0].slice(1).split('_').join(' ')} <FontAwesomeIcon icon={faTimesCircle} /></li>
        ))

        const cols: any[] = [
            new DataColumn('Mill id', 'mill_id', 10, new BasicSorter(), null),
            new DataColumn('Mill', 'mill', 20, new BasicSorter(), null),
            new DataColumn('Supplier', 'supplier', 20, new BasicSorter(), null),
            new DataColumn('Mill location', 'mill_location', 25, new BasicSorter(), null),
            new ActionColumn("", null, null, 10,
            [{
                operationCb: (row: any) => {
                    window.location.replace("supplier/"+row.supplier_id+"/mill/"+row.mill_id)
                },
                iconClassName: "table-edit",
                message: null,
                icon: <FontAwesomeIcon icon={faExternalLinkSquareAlt} />,
                iconStyle: {}
            }],
            "Link"
        ),
        ];
        return (
            <Container fluid className="page-content" >
                <h1 className="page-title">Search</h1>

                <div className="element-box">

                    <Row>
                        <Col xl="12">
                            <div className="filteronsearch">
                                <ul className="list-filteronsearch">
                                    {result}
                                </ul>
                            </div>
                        </Col>

                    </Row>


                    <ReduxComponentWrapper component={Table} componentPropsCallback={(state: any) => ({
                        provider: this.search,
                        searchCallback: (params: IRestDataSourceParams): void => {
                            this.search.refreshWithHeaders(
                                (new Search(false)).getResultListWithFilters(window.location.search)
                            )
                        },
                        columns: cols,
                        loader: [5,15],
                    })} />
                </div>

            </Container>

        );
    }
}
