import { Container } from "../../index";
import { PageType } from "../../common/components/pages/Page";
import { DataModel } from "../../common/model/DataModel";
import DashboardPage from "../pages/DashboardPage";
import MeetingsPage from "../pages/MeetingsPage";
import NewsPage from "../pages/NewsPage";
import SearchPage from "../pages/SearchPage"
import SupplierPage from "../pages/SupplierPage";
import SuppliersPage from "../pages/SuppliersPage";
import UsersPage from "../pages/UsersPage";
import LoginPage from "../pages/LoginPage";
import ForbiddenPage from "../pages/ForbiddenPage";
import GeneralSettingsPage from "../pages/GeneralSettingsPage";
import ProfileSettingsPage from "../pages/ProfileSettingsPage";
import { User } from "../models/User";
import ForgottenPasswordPage from "../pages/ForgottenPasswordPage";
import ForgottenPasswordChangePage from "../pages/ForgottenPasswordChangePage";
import { Certificate } from "../models/Certificate";
import { Country } from "../models/Country";
import { CertificateIssuer } from "../models/CertificateIssuer";
import { MaterialDefaultGrade } from "../models/MaterialDefaultGrade";
import { MaterialGradeCategory } from "../models/MaterialGradeCategory";
import { Mill } from "../models/Mill";
import { MillProduct } from "../models/MillProduct";
import { MillApproval} from "../models/MillApproval";
import { MillCertificate } from "../models/MillCertificate";
import { MillContact } from "../models/MillContact";
import { MillProductGrade } from "../models/MillProductGrade";
import { ProductCategory } from "../models/ProductCategory";
import { QualityStatus } from "../models/QualityStatus";
import { Search } from "../models/Search";
import { Supplier } from "../models/Supplier";
import { SupplierContact } from "../models/SupplierContact";
import { News } from "../models/News";
import { SupplierMeeting } from "../models/SupplierMeeting";


/**
 * Util static class for managing Container Elements.
 */
export abstract class ContainerElements {
    /**
     * Add all necessary items to container.
     */
    public static addAll() {
        this.addPages();
        this.addModels();
    }

    /**
     * Add needed pages to container.
     */
    private static addPages() {
        const pages: PageType[] = [
            DashboardPage,
            MeetingsPage,
            NewsPage,
            SearchPage,
            SupplierPage,
            SuppliersPage,
            UsersPage,
            LoginPage,
            ForbiddenPage,
            GeneralSettingsPage,
            ProfileSettingsPage,
            ForgottenPasswordPage,
            ForgottenPasswordChangePage
        ];

        pages.forEach((page: PageType) => {
            Container.addPage(page);
        });
    }

    /**
     * Add models to container.
     */
    private static addModels() {
        const models: DataModel<any>[] = [
            new User(false),
            new Certificate(false),
            new CertificateIssuer(false),
            new Country(false),
            new MaterialDefaultGrade(false),
            new MaterialGradeCategory(false),
            new Mill(false),
            new MillApproval(false),
            new MillCertificate(false),
            new MillContact(false),
            new MillProduct(false),
            new MillProductGrade(false),
            new ProductCategory(false),
            new QualityStatus(false),
            new Search(false),
            new Supplier(false),
            new SupplierContact(false),
            new News(false),
            new SupplierMeeting(false)

        ];

        models.forEach((model: DataModel<any>) => {
            Container.addModel(model);
        });
    }
}
