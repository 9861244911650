import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import { NumberValidator } from "../../common/components/validators/NumberValidator";

export interface IMillProductGrade {
    id?: number,
    mill_product_id?: number,
    grade?: string
}


export class MillProductGrade extends DataModel<IMillProductGrade> {

    public resourceName = "mill-product-grade";
    public resourceNamePlural = "mill-product-grades";

    private _id: number;
    private _mill_product_id?: number;
    private _grade?: string;


    protected getDefaultValues(): IMillProductGrade {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IMillProductGrade> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IMillProductGrade> {
        return new DataModelValidator<IMillProductGrade>({
            mill_product_id: [new RequiredValidator(), new NumberValidator()],
            grade: [new RequiredValidator(), new LengthValidator({ max: 50 })],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IMillProductGrade>, action: AnyAction): IDataModelState<IMillProductGrade> {
        return state;
    }

    public loadMillProducts(): Promise<IMillProductGrade[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }



    public loadByMillId(id: number): Promise<IMillProductGrade[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural+ "?filter=mill_product_id:eq:"+id)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IMillProductGrade | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource()
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<IMillProductGrade | AxiosError> {

        return this.createDataSource(this.resourceName)
            .postOperation({
                grade: this._grade,
                mill_product_id: +this._mill_product_id
            });
    }

    public update(data: IMillProductGrade): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                grade: this._grade,
                mill_product_id: +this._mill_product_id
            });
    }



    getStoreKey(): string {
        return "MILL-PRODUCT-GRADE";
    }

    protected setFromObj(data: IMillProductGrade): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._grade = DataModel.safeGet(data.grade, this._grade);
        this._mill_product_id = DataModel.safeGet(data.mill_product_id, this._mill_product_id);
    }


    protected toObj(): IMillProductGrade {
        return {
            id: this._id,
            grade: this._grade,
            mill_product_id: this._mill_product_id
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get grade() {
        return this._grade;
    }


    get mill_product_id() {
        return this._mill_product_id;
    }


}
