import React from 'react';
import logo from '../../../assets/img/logo.png'
import { Col } from 'reactstrap';
import { Path } from '../../container/Path';
import { Link } from 'react-router-dom';

function Logo() {
    return (
        <Col lg="4">
            <Link to={Path.HOMEPAGE} className="logo-box">
                <img className="logo my-2" alt="logo" src={logo}></img>
            </Link>
        </Col>
    );
}

export default Logo;