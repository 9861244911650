/**
 * User roles.
 */
export enum Role {

    GUEST = "guest",

    USER = "user",

    CATEGORY_MANAGER  = "category_manager",

    ADMIN  = "admin",
}
