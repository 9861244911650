import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { NumberValidator } from "../../common/components/validators/NumberValidator";
import { ICertificate } from "./Certificate";
import moment from "moment";

export interface IMillCertificate {
    id?: number,
    mill_id?: number,
    certificate_id?: number,
    certificate?: ICertificate,
    name?: string,
    start_date?: string,
    end_date?: string,
    attachment?: string
}


export class MillCertificate extends DataModel<IMillCertificate> {

    public resourceName = "mill-certificate";
    public resourceNamePlural = "mill-certificates";

    private _id: number;
    private _mill_id?: number;
    private _certificate_id?: number;
    private _certificate?: ICertificate;
    private _start_date?: string;
    private _end_date?: string;
    private _attachment?: string;


    protected getDefaultValues(): IMillCertificate {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IMillCertificate> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IMillCertificate> {
        return new DataModelValidator<IMillCertificate>({
            certificate_id: [new RequiredValidator(), new NumberValidator()],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IMillCertificate>, action: AnyAction): IDataModelState<IMillCertificate> {
        return state;
    }

    public loadMillCertificates(): Promise<IMillCertificate[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }


    public loadByMillId(id: number): Promise<IMillCertificate[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural+ "?filter=mill_id:eq:"+id)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IMillCertificate | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<IMillCertificate | AxiosError> {

        return this.createDataSource(this.resourceName)
            .postOperation({
                mill_id: +this._mill_id,
                certificate_id: +this._certificate_id,
                start_date: moment().format("YYYY-MM-DD"),
                end_date: this._end_date,
                attachment: this._attachment
            });
    }

    public update(data: IMillCertificate): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                mill_id: +this._mill_id,
                certificate_id: +this._certificate_id,
                start_date: this._start_date,
                end_date: this._end_date,
                attachment: this._attachment
            });
    }



    getStoreKey(): string {
        return "MILL-CERTIFICATE";
    }

    protected setFromObj(data: IMillCertificate): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._mill_id = DataModel.safeGet(data.mill_id, this._mill_id);
        this._certificate_id = DataModel.safeGet(data.certificate_id, this._certificate_id);
        this._certificate = DataModel.safeGet(data.certificate, this._certificate);
        this._start_date = DataModel.safeGet(data.start_date, this._start_date);
        this._end_date = DataModel.safeGet(data.end_date, this._end_date);
        this._attachment = DataModel.safeGet(data.attachment, this._attachment);
    }


    protected toObj(): IMillCertificate {
        return {
            id: this._id,
            mill_id: this._mill_id,
            certificate_id: this._certificate_id,
            certificate: this._certificate,
            start_date: this._start_date,
            end_date: this._end_date,
            attachment: this._attachment,
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    set mill_id(id: number) {
        this._mill_id = id;
    }

    set attachment(path: string) {
        this._attachment = path;
    }

    get mill_id() {
        return this._mill_id;
    }


    get certificate_id() {
        return this._certificate_id;
    }


    get certificate() {
        return this._certificate;
    }


    get start_date() {
        return this._start_date;
    }


    get end_date() {
        return this._end_date;
    }


    get attachment() {
        return this._attachment;
    }


}
