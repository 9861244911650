import React from 'react';
import VlbSimple from './VlbSimple';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Row, Col, FormGroup} from 'reactstrap';
import logoOn from '../../assets/img/logo-on.png'
import Identity from '../user/Identity';
import { User } from '../models/User';
import { AxiosError, AxiosResponse } from 'axios';
import { PasswordInput } from '../../common/components/widgets/form/input/PasswordInput';
import Util from '../custom/Util';
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import { FormHandlingUtil } from '../../common/components/widgets/form/util/FormHandlingUtil';
import { IDestroy } from '../../common/components/pages/IDestroy';

@withRouter(Path.FORGOTTEN_PASSWORD_CHANGE.toString(), PageContainer)
@withSecurity([Role.GUEST.toString()], Identity, Path.HOMEPAGE)
export default class ForgottenPasswordChangePage extends VlbSimple {
    private user: User = new User(true, User.SCENARIO.CHANGE_PASSWORD)

    private formHandlingUtil: FormHandlingUtil;

    private inputs: { [attr: string]: React.RefObject<any> } = {
        password: React.createRef(),
        password_confirmed: React.createRef()
    };

    constructor(props: any) {
        super(props);

        let password_reset_token = this.props.match.params.token;

        this.formHandlingUtil = new FormHandlingUtil(
            this.inputs,
            this.handleSubmit.bind(this),
            [this.user]
        );

        this.state = {
            password_reset_token
        }

    }


    public componentDidMount() {
        super.componentDidMount();

        this.user.setFromPlainObject({
            password_reset_token: this.props.match.params.token
        });
    }

    pageTitle() {
        return "New password" + super.pageTitle();
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.user
        ];
    }

    handleSubmit(event: React.MouseEvent<HTMLElement>) {

        this.user.resetPassword(this.state.password_reset_token)
            .then((response: AxiosResponse) => {
                this.user.setFromPlainObject({ ...response.data, ...this.user });

                this.user.login(this.user).then((error: AxiosError | void) => {
                    document.location.pathname = Path.HOMEPAGE.toString();
                })
            })
            .catch((error: AxiosError) => {

                Util.notification('error', 'An error occurred, please try again later.', 1500)
            });
    }

    renderContent() {

        return (
            <div className="forgotten-password-change-page">

                <div className="form d-flex justify-content-center align-items-center min-vh-100">
                    <div className="max-screen-440">
                        <Row>
                            <Col xs="12">
                                <div className="logo-form mb-3"><img alt="logo" src={logoOn} /></div>
                            </Col>
                            <Col xs="12">
                                <form>
                                    <FormGroup>
                                        <PasswordInput
                                            placeholder="New password"
                                            label="New password"
                                            ref={this.inputs.password}
                                            id="newPassword"
                                            onBlur={this.formHandlingUtil.getValidateHandle()}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <PasswordInput
                                            placeholder="Confirm new password"
                                            label="Confirm new password"
                                            ref={this.inputs.password_confirmed}
                                            id="renewPassword" />
                                    </FormGroup>
                                    <a href="#" className="mt-3 btn btn-primary" onClick={this.formHandlingUtil.getSubmitHandle()} type="submit">Change password</a>

                                </form>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div >
        );
    }
}
