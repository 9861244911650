import React from 'react';
import { ITextInputProps, TextInput } from "./TextInput";
import DatePicker,{registerLocale, setDefaultLocale } from 'react-datepicker';

require('react-datepicker/dist/react-datepicker.css');

export interface IDateInputProps extends ITextInputProps {
    format?: string,
    maxDate?: string,
    minDate?: string
}

export class DateInput extends TextInput<IDateInputProps> {

    public constructor(props: any) {
        super(props);
        this.state = {
            startDate: null
        };

        this.handleChange = this.handleChange.bind(this);
    }

    private handleChange(date: any) {

        this.setState({
            startDate: date ? new Date(date): date
        });
    }

    protected renderInput(): React.ReactNode {
        const state: any = this.state as any;

        const dateFormat: string = (this.props.format) ? this.props.format : "dd.MM.YYYY";

        return (<DatePicker
            className={"form-control " + this.getInputErrorClass()}
            dateFormat={dateFormat}
            ref={this.inputRef}
            selected={state.startDate}
            onBlur={(this.props.onBlur) ? this.props.onBlur : () => {
            }}
            onChange={this.handleChange}
            maxDate={this.props.maxDate? new Date(this.props.maxDate) : null}
            minDate={this.props.minDate? new Date(this.props.minDate) : null}
             />);
    }

    public getValue(): any {
        const state: any = this.state as any;

        return state.startDate ? new Date(state.startDate) : state.startDate;
    }

    public setValue(initialValue: any): any {
        this.setState({
            startDate: initialValue ? new Date(initialValue) : initialValue
        });
    }
}
