import React, { Fragment } from 'react';
import VlbPage from './VlbPage';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Container, FormGroup, Col, Row, CustomInput, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Spinner } from 'reactstrap';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import Util from '../custom/Util';
import { Supplier, ISupplier } from '../models/Supplier';
import { DataProvider } from '../../common/model/DataProvider';
import { NumberInput } from '../../common/components/widgets/form/input/NumberInput';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { SupplierClassification } from '../models/SupplierClassification';
import { Country } from '../models/Country';
import { AxiosResponse, AxiosError } from 'axios';
import { DropdownInput } from '../../common/components/widgets/form/input/DropdownInput';
import { BasicFilter } from '../../common/components/widgets/table/filter/BasicFilter';
import { DropdownFilter } from '../../common/components/widgets/table/filter/DropdownFilter';
import { Role } from '../user/Role';
import { DateInput } from '../../common/components/widgets/form/input/DateInput';
import { User } from '../models/User';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { TablePage } from '../../common/components/widgets/table/TablePage';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import { BasicPaging } from '../../common/components/widgets/table/paging/BasicPaging';
import Identity from '../user/Identity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { File as FileModel } from '../models/File';
import { CustomActionColumn } from '../custom/CustomActionColumn';
import { withSecurity } from '../../common/security/Security';


@withRouter(Path.SUPPLIERS.toString(), PageContainer)

@withSecurity([Role.USER, Role.ADMIN, Role.CATEGORY_MANAGER], Identity, Path.LOGIN)
export default class SuppliersPage extends VlbPage {
    private submitted = false;
    private modalTitle = "Add new";

    private countryModel: Country = new Country();
    private supplierClassification = new SupplierClassification();
    private userModel: User = new User();
    private model: Supplier = new Supplier(false);
    private file = new FileModel();

    private dataProvider = new DataProvider<ISupplier>();

    private suppliersInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        manager_id: React.createRef(),
        supplier_classification_id: React.createRef(),
        country_id: React.createRef(),
        city: React.createRef(),
        zip_code: React.createRef(),
        address: React.createRef(),
        phone_number: React.createRef(),
        website: React.createRef(),
        framework_agreement_date: React.createRef(),
        bonus_agreement_date: React.createRef(),
        framework_agreement_attachment: React.createRef(),
        bonus_agreement_attachment: React.createRef(),
        code_of_conduct: React.createRef(),
    };

    constructor(props: any) {
        super(props);

        this.delete = this.delete.bind(this);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.readInputs = this.readInputs.bind(this);
        this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setInputValues = this.setInputValues.bind(this);
        this.addFile = this.addFile.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.sendSupplierRequest = this.sendSupplierRequest.bind(this);
        this.searchCallback = this.searchCallback.bind(this);

        this.state = {
            supplierClassificationList: null,
            countryList: null,
            usersList: null,
            titleAttr: "name",
            singularTitle: "supplier",
            addPrivileges: [Role.CATEGORY_MANAGER],
            loader: [5, 15],

            modalDelete: false,
            currentModelDelete: {},
            modalFormOpen: false,
            modalEdit: false,
            reload: false,
            isCreate: true,
            files: { bonus_agreement_attachment: null, framework_agreement_attachment: null, code_of_conduct: null },
            fileUrls: [],

            modalFileDelete: false,
            modalFileDeleteAttr: "",
            modalFileDeleteEvent: null,

            waitForUpload: false,
        };
    }

    pageTitle() {
        return "Suppliers" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
        ];
    }

    delete(row?: any) {
        this.setState(prevState => ({
            modalDelete: !prevState.modalDelete,
            currentModelDelete: row
        }));
    }

    fileDelete(attr: string, event: React.MouseEvent<HTMLElement>) {
        const inputElement: HTMLInputElement = document.getElementById(attr) as HTMLInputElement

        if (inputElement.value === "") {
            Util.notification("info", "No file to delete", 1500);
        } else {
            this.setState(prevState => ({
                modalFileDelete: !prevState.modalFileDelete,
                modalFileDeleteAttr: attr,
                modalFileDeleteEvent: event,
            }));
        }
    }

    add() {

        this.modalTitle = "Add new ";
        this.model.changeScenario(this.state.scenarios ? this.state.scenarios.create : "default");

        this.setState(prevState => ({
            modalFormOpen: !prevState.modalFormOpen,
            isCreate: true
        }));
    }

    edit(row?: any) {
        this.modalTitle = "Edit ";
        this.model.changeScenario(this.state.scenarios ? this.state.scenarios.update : "default");

        this.model.loadById(row.id)
            .then((response: any) => {
                this.model.setFromPlainObject(response);
                this.setInputValues(response);
            })
            .catch((error: AxiosError) => {
                Util.notification("error", "Data could not be loaded", 1500);
            });

        this.setState(prevState => ({
            isCreate: false,
            modalFormOpen: !prevState.modalFormOpen
        }));
    }

    setInputValues(values: any) {
        let inputs = this.suppliersInputs;

        for (let key in values) {
            if (inputs[key] && inputs[key].current) {
                inputs[key].current.setValue(values[key]);
            }
        }
    }

    protected handleSubmit(event: React.MouseEvent<HTMLElement>): void {

        this.submitted = true;

        if (this.validate(event)) {

            if (this.state.files["framework_agreement_attachment"] || this.state.files["bonus_agreement_attachment"] || this.state.files["code_of_conduct"]) {

                this.uploadFilesToServer()
                this.setState({
                    waitForUpload: true
                })

                setTimeout(() => {

                    this.sendSupplierRequest()
                }, 2000);
            } else {
                this.sendSupplierRequest()
            }


        }
    }

    sendSupplierRequest() {
        let promise: Promise<AxiosResponse>;

        if (this.state.isCreate) {
            promise = this.model.createNew();
        } else {
            promise = this.model.update(this.model.asPlainObject());
        }

        promise.then((response: AxiosResponse) => {

            this.setState({
                waitForUpload: false
            })

            Util.notification("success", "Success.", 2000);
            this.setState({ reload: true, modalFormOpen: false, modalEdit: false })

        })
            .catch((error: any) => {
                const aError: any = error;

                switch (aError.response.status) {
                    case 422:

                        if (aError.response && aError.response.data && aError.response.data.errors) {
                            const respErrors: any[] = aError.response.data.errors;
                            const errors: { [attr: string]: string } = {};

                            respErrors.forEach((error: any) => {
                                errors[error.fieldName] = error.errorMessage;
                            });

                            this.fillInputsWithErrors(errors, this.suppliersInputs);
                        }
                        break;
                    default:

                        Util.notification("error", "Error.", 2000);
                        break;
                }

                this.setState({ reload: true, waitForUpload: false })
            });
    }


    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key) && inputs[key] != undefined && inputs[key].current) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    private validate(event: any): boolean {
        this.readInputs();

        let inputs = this.suppliersInputs;
        const valid = this.model.validate();

        if (this.submitted && !valid) {
            const errors: { [attr: string]: string } = this.model.getErrors();
            this.fillInputsWithErrors(errors, inputs);
        } else {
            const plainObject: ISupplier = {};
            for (const key in inputs) {
                if (inputs.hasOwnProperty(key) && inputs[key].current) {
                    plainObject[key] = inputs[key].current.getValue();
                }
            }
            this.model.setFromPlainObject({...this.model.asPlainObject(), ...plainObject});
        }

        event.preventDefault();

        return valid;
    }

    private readInputs(): any {

        const plainObject = {};
        const inputs = this.suppliersInputs;

        for (const key in inputs) {
            if (inputs.hasOwnProperty(key) && inputs[key].current) {
                plainObject[key] = inputs[key].current.getValue();
                inputs[key].current.removeError();
            }
        }

        this.model.setFromPlainObject({ ...this.model.asPlainObject(), ...plainObject });

        return plainObject;
    }

    componentDidMount() {
        this.countryModel.getListPlain({
            sort: {
                attr: "name",
                ascending: true
            }
        })
            .then((response: AxiosResponse) => {

                this.setState({
                    countryList: response.data,
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Country list cannot be loaded", 1500);
            });

        this.supplierClassification.getListPlain({
            sort: {
                attr: "name",
                ascending: true
            }
        })
            .then((response: AxiosResponse) => {

                this.setState({
                    supplierClassificationList: response.data,
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Supplier classification list cannot be loaded", 1500);
            });

        this.userModel.getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    usersList: response.data,
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "User list cannot be loaded", 1500);
            });


        super.componentDidMount();
    }


    uploadFilesToServer() {
        let data = new FormData();

        //Append files to form data
        let files = this.state.files;
        if (files.bonus_agreement_attachment) {
            data.append('files', files.bonus_agreement_attachment, files.bonus_agreement_attachment.name);

            this.file.createNew(data).then((response: AxiosResponse) => {
                let filesResponse: string[] = response.data;
                this.model.bonus_agreement_attachment = filesResponse[0];
                this.setState({
                    fileUrls: { ...this.state.fileUrls, bonus_agreement_attachment: filesResponse[0] }
                })

            })
        }


        if (files.framework_agreement_attachment) {
            data.append('files', files.framework_agreement_attachment, files.framework_agreement_attachment.name);

            this.file.createNew(data).then((response: AxiosResponse) => {
                let filesResponse: string[] = response.data;

                this.model.framework_agreement_attachment = filesResponse[0];
                this.setState({
                    fileUrls: { ...this.state.fileUrls, framework_agreement_attachment: filesResponse[0] }
                })

            })
        }

        if (files.code_of_conduct) {
            data.append('files', files.code_of_conduct, files.code_of_conduct.name);

            this.file.createNew(data).then((response: AxiosResponse) => {
                let filesResponse: string[] = response.data;

                this.model.code_of_conduct = filesResponse[0];
                this.setState({
                    fileUrls: { ...this.state.fileUrls, code_of_conduct: filesResponse[0] }
                })

            })
        }
    }


    removeFile(attr: string, event: React.MouseEvent<HTMLElement>) {
        const inputElement: HTMLInputElement = document.getElementById(attr) as HTMLInputElement

        inputElement.value = ''

        this.model[attr] = null;
        this.model.update(this.model).then((response: AxiosResponse) => {
            Util.notification("success", "Supplier successfully updated.", 10000);
            this.setState({
                modalFileDelete: false,
                reload: true,
                modalFormOpen: false,
                modalEdit: false
            })
        });
    }

    addFile = (attr: string, event?: any) => {

        let newFilesObj = { bonus_agreement_attachment: null, framework_agreement_attachment: null, code_of_conduct: null };
        let files = event.target.files;

        for (var i = 0; i < files.length; i++) {
            let fileGood = true;
            var regex = new RegExp("(.*?)\.(pdf)$");

            if (!(regex.test(files[i].type))) {
                Util.notification("error", "Document format is not valid. Allowed: pdf", 10000);
                fileGood = false;
            }

            if (fileGood) {
                newFilesObj[attr] = files[i];
                this.suppliersInputs[attr].current.setValue(files[i].name)
            }
        }

        this.setState({ files: { ...this.state.files, ...newFilesObj } })

    }

    searchCallback() {
        if (!this.state.modelEdit) {
            return (params: IRestDataSourceParams): void => {
                this.dataProvider.refreshWithHeaders(
                    this.model.getListPlain(params)
                )
            }
        }
    }

    renderContent() {

        let usersList = [];
        if (this.state.usersList) {
            for (var i = 0; i < this.state.usersList.length; i++) {

                if(this.state.usersList[i].item_name === "category_manager"){
                    usersList.push({
                        id: this.state.usersList[i].id,
                        name: this.state.usersList[i].username,
                    });
                }
            }
        }

        let countryFilterList = {};
        if (this.state.countryList) {
            for (let i = 0; i < this.state.countryList.length; i++) {
                countryFilterList[this.state.countryList[i].id] = this.state.countryList[i].name;
            }
        }

        const paging = new BasicPaging({
            pageSize: 15
        });

        let formElements = (
            <>
                <FormGroup>
                    <TextInput
                        label="Name"
                        id="s_name"
                        placeholder="Name"
                        ref={this.suppliersInputs.name}
                    />
                </FormGroup>
                <FormGroup>
                    <DropdownInput
                        listItems={usersList}
                        label="Manager"
                        id="s_manager"
                        placeholder="Manager"
                        ref={this.suppliersInputs.manager_id}
                    />
                </FormGroup>
                <FormGroup>
                    <DropdownInput
                        listItems={this.state.supplierClassificationList}
                        label="Classification"
                        id="s_classification"
                        placeholder="Classification"
                        ref={this.suppliersInputs.supplier_classification_id}
                    />
                </FormGroup>
                <FormGroup>
                    <DropdownInput
                        listItems={this.state.countryList}
                        label="Country"
                        id="s_country"
                        placeholder="Country"
                        ref={this.suppliersInputs.country_id}
                    />
                </FormGroup>
                <Row>
                    <Col xl="8">
                        <FormGroup>
                            <TextInput
                                label="City"
                                id="s_city"
                                placeholder="City"
                                ref={this.suppliersInputs.city}
                            />
                        </FormGroup>
                    </Col>
                    <Col xl="4">
                        <FormGroup>
                            <NumberInput
                                label="Zip Code"
                                id="s_zip_code"
                                placeholder="Zip Code"
                                ref={this.suppliersInputs.zip_code}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <TextInput
                        label="Address"
                        id="s_address"
                        placeholder="Address"
                        ref={this.suppliersInputs.address}
                    />
                </FormGroup>
                <Row>
                    <Col xl="6">
                        <FormGroup>
                            <NumberInput
                                label="Phone number"
                                id="s_phone_number"
                                placeholder="Phone number"
                                ref={this.suppliersInputs.phone_number}
                            />
                        </FormGroup>
                    </Col>
                    <Col xl="6">
                        <FormGroup>
                            <TextInput
                                label="Website"
                                id="s_website"
                                placeholder="Website"
                                ref={this.suppliersInputs.website}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xl="6">
                        <FormGroup>
                            <DateInput
                                label="Framework agreement"
                                id="s_framework_agreement_date"
                                placeholder="Framework agreement"
                                ref={this.suppliersInputs.framework_agreement_date}
                            />
                        </FormGroup>
                    </Col>
                    <Col xl="6">
                        <FormGroup>
                            <TextInput
                                label="Framework agreement"
                                id="framework_agreement_attachment"
                                placeholder="Framework agreement"
                                ref={this.suppliersInputs.framework_agreement_attachment}
                                disabled
                                class="upload-text-input"
                            />

                            <label className="uploadButton" onChange={(e) => this.addFile("framework_agreement_attachment", e)}>
                                <input type="file" accept="application/pdf" />
                                <FontAwesomeIcon icon={faPlus} />
                            </label>

                            <label className="deleteButton" id="framework_agreement_attachment" onClick={(e) => this.fileDelete("framework_agreement_attachment", e)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </label>

                        </FormGroup>
                    </Col>
                    <Col xl="6">
                        <FormGroup>
                            <DateInput
                                label="Bonus agreement"
                                id="s_bonus_agreement_date"
                                placeholder="Bonus agreement"
                                ref={this.suppliersInputs.bonus_agreement_date}
                            />
                        </FormGroup>
                    </Col>
                    <Col xl="6">
                        <FormGroup>
                            <TextInput
                                label="Bonus agreement"
                                id="bonus_agreement_attachment"
                                placeholder="Bonus agreement"
                                ref={this.suppliersInputs.bonus_agreement_attachment}
                                disabled
                                class="upload-text-input"
                            />

                            <label className="uploadButton"
                                id="bonus_agreement_attachment"
                                onChange={(e) => this.addFile("bonus_agreement_attachment", e)}>
                                <input type="file" accept="application/pdf" />
                                <FontAwesomeIcon icon={faPlus} />
                            </label>

                            <label className="deleteButton" onClick={(e) => this.fileDelete("bonus_agreement_attachment", e)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xl="12">
                        <FormGroup>
                            <TextInput
                                label="Code of Conduct"
                                id="code_of_conduct"
                                placeholder="code_of_conduct.pdf"
                                ref={this.suppliersInputs.code_of_conduct}
                                disabled
                                class="upload-text-input"
                            />
    
                            <label className="uploadButton"
                                id="code_of_conduct"
                                onChange={(e) => this.addFile("code_of_conduct", e)}>
                                <input type="file" accept="application/pdf" />
                                <FontAwesomeIcon icon={faPlus} />
                            </label>
    
                            <label className="deleteButton" onClick={(e) => this.fileDelete("code_of_conduct", e)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </label>
                        </FormGroup>
                    </Col>
                </Row>
            </>

        )

        let deleteModal;
        deleteModal = (
            <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.delete}>
                <ModalHeader toggle={this.delete}>{this.state.currentModelDelete[this.state.titleAttr]}</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this?
                    </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalDelete: !this.state.modalDelete })}>Cancel</Button>
                    <Button outline color="primary" onClick={() =>
                        (this.model).del(this.state.currentModelDelete["id"]).then(() => {
                            this.setState(prevState => ({
                                modalDelete: !prevState.modalDelete,
                            }));
                        })

                    }>Delete</Button>
                </ModalFooter>
            </Modal>
        )

        let fileDeleteModal;
        fileDeleteModal = (
            <Modal centered size="md" isOpen={this.state.modalFileDelete} fade={false} toggle={() => this.fileDelete}>
                <ModalHeader toggle={() => this.setState({ modalFileDelete: !this.state.modalFileDelete })}>Delete file</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this?
                    </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalFileDelete: !this.state.modalFileDelete })}>Cancel</Button>
                    <Button outline color="primary" onClick={(e) => this.removeFile(this.state.modalFileDeleteAttr, this.state.modalFileDeleteEvent)}>Delete</Button>
                </ModalFooter>
            </Modal>
        )

        let submitButton;
        if (!this.state.waitForUpload) {
            submitButton = <Button outline color="primary" onClick={this.handleSubmit}> {this.state.isCreate ? "Add" : "Update"}</Button>
        } else {
            submitButton = <Button outline color="primary" disabled><Spinner color="secondary" size="sm" className="sppiner-button" /> {this.state.isCreate ? "Add" : "Update"}</Button>
        }

        let modalForm;
        modalForm = (
            <>
                <ModalHeader toggle={() => this.add()}>{this.modalTitle} {this.state.singularTitle}</ModalHeader>
                <ModalBody>
                    <Form>
                        {formElements}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalFormOpen: !this.state.modalFormOpen })}>Cancel</Button>
                    {submitButton}
                </ModalFooter>
            </>
        )

        let modal;
        modal = (
            <Modal centered size="md" isOpen={this.state.modalFormOpen} fade={false} toggle={() => this.add()}>
                {modalForm}
            </Modal>
        )

        let add;
        if (this.state.addPrivileges.indexOf(Identity.role) !== -1) {
            add = <div className="add-new"><Button color="primary" onClick={() => this.add()}><FontAwesomeIcon icon={faPlus} /> Add {this.state.singularTitle}</Button></div>
        }


        const suppliersColumns: any[] = [
            new DataColumn('Name', 'name', 25, new BasicSorter(), new BasicFilter(false, 'Search name', 5)), //isnumber, placeholder, colsize
            new DataColumn('Country', 'country_id', 20, new BasicSorter(), new DropdownFilter(countryFilterList, 'All Countries', 4)), //Sakrivena kolona po kojoj se radi filter
            new DataColumn('Country', 'country.name', 25, new BasicSorter()),
            new DataColumn('City', 'city', 20, new BasicSorter(), new BasicFilter(false, 'Search city', 3)),
            new DataColumn('Manager', 'manager.username', 20, new BasicSorter()),
            new CustomActionColumn(
                'supplier/',
                (row: Object) => {
                    this.delete(row)
                },
                (row: Object) => {
                    this.edit(row)
                },
                20,
                true
            )
        ];

        return (
            <>
                <Container fluid className="page-content supplierspage" >
                    <Fragment>
                        <div className="title-box">
                            <h1 className="page-title">Suppliers</h1>
                            {add}
                        </div>

                        <div className="element-box">

                            <ReduxComponentWrapper component={TablePage} componentPropsCallback={(state: any) => ({
                                provider: this.dataProvider,
                                loader: this.state.loader,
                                searchCallback: this.searchCallback(),
                                columns: suppliersColumns,
                                paging: paging
                            })} />


                            {modal}
                            {deleteModal}
                            {fileDeleteModal}

                        </div>
                    </Fragment>
                </Container >
            </>
        );
    }
}
