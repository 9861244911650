import * as React from 'react';
import { TextInput, ITextInputProps } from "./TextInput";

export interface INumberInputProps extends ITextInputProps {
    min?: number | Date,
    max?: number | Date,
    step?: string,
}

export class NumberInput<T extends INumberInputProps = INumberInputProps> extends TextInput<T> {
    protected type: string = 'number';

    protected renderInput(): React.ReactNode {
        const additionalProps: any = {};

        if (this.props.maxLength) {
            additionalProps['maxLength'] = this.props.maxLength;
        }

        if (this.props.min) {
            additionalProps['min'] = this.props.min;
        }

        if (this.props.max) {
            additionalProps['max'] = this.props.max;
        }

        if (this.props.autoFocus) {
            additionalProps['autoFocus'] = this.props.autoFocus;
        }

        if (this.props.step) {
            additionalProps['step'] = this.props.step;
        }

        return (<input ref={this.inputRef}
            id={this.props.id}
            placeholder={this.props.placeholder ? this.props.placeholder : ""}
            onBlur={(this.props.onBlur) ? this.props.onBlur : () => {
            }}
            {...additionalProps}
            value={this.props.initialValue}
            onChange={(this.props.onChange) ? this.props.onChange : () => {
            }}
            className={"hidespinner form-control " + this.getInputErrorClass()} type={this.type} />);
    }
}
