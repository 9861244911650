import * as React from 'react';
import { ITableColumn } from "../Table";
import * as bootbox from "bootbox";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontawesomeObject } from '@fortawesome/fontawesome-svg-core';

export interface IActionColumn extends ITableColumn {
    viewPath: string,
    deleteAction: ((row: any) => void) | null,
    editAction: ((row: any) => void) | null,
    pathItems: IActionColumnPathItem[] | null
}

export interface IActionColumnPathItem {
    operationCb: ((row: any) => void) | null,
    iconClassName: string,
    message: string | null,
    icon?: JSX.Element | null,
    iconStyle?: React.CSSProperties
}

export class ActionColumn implements IActionColumn {
    label: string;
    viewPath: string;
    deleteAction: ((row: Object) => void) | null;
    editAction: ((row: Object) => void) | null;
    pathItems: IActionColumnPathItem[] | null;
    width: number;

    public constructor(
        editPath: string,
        deleteAction: ((row: Object) => void) | null = null,
        editAction: ((row: Object) => void) | null = null,
        width: number = 0,
        pathItems: IActionColumnPathItem[] | null = null,
        label: string = "Actions") {
        this.label = label;
        this.viewPath = editPath;
        this.deleteAction = deleteAction;
        this.editAction = editAction;
        this.pathItems = pathItems;
        this.width = width;
    }

    protected evaluateDelete(row: any): void {

        if (this.deleteAction) {
            this.deleteAction(row);
        }
    }

    protected evaluateCustomOperation(row: Object, operationCallback: (row: Object) => void | null): void {

        if (operationCallback) {
            operationCallback(row);
        }
    }

    protected performDelete(row: Object, event: React.MouseEvent<HTMLElement>): void {

        if (this.deleteAction) {
            this.evaluateDelete(row);
        }

        event.preventDefault();
    }

    protected performEdit(row: Object, event: React.MouseEvent<HTMLElement>): void {

        if (this.editAction) {
            this.editAction(row);
        }

        event.preventDefault();
    }

    protected performCustomOperation(row: Object, operationCallback: ((row: Object) => void) | null,
        message: string | null, event: React.MouseEvent<HTMLElement>): void {

        if (operationCallback) {

            if (message !== null) {
                bootbox.confirm(message, this.evaluateCustomOperation.bind(this, row, operationCallback));
            } else {
                this.evaluateCustomOperation.bind(this, row, operationCallback)();
            }
        }

        event.preventDefault();
    }

    public get content(): (row: any) => React.ReactNode {
        let deleteActionNode: React.ReactNode | null = null;
        let editActionNode: React.ReactNode | null = null;
        let pathItemsNode: React.ReactNode[] = [];
        let viewPathItemNode: React.ReactNode | null = null;

        return (row: any) => {

            if (this.deleteAction !== null) {
                deleteActionNode = (
                    <Link to={window.location} onClick={this.performDelete.bind(this, row)} className="table-edit delete"><FontAwesomeIcon icon={faTrash} /></Link>
                );
            }


            if (this.editAction !== null) {
                editActionNode = (
                    <Link  to={window.location} onClick={this.performEdit.bind(this, row)} className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></Link>
                );
            }

            if (this.pathItems) {
                this.pathItems.forEach((pathItem: IActionColumnPathItem) => {

                    const cssProps: React.CSSProperties = (pathItem.iconStyle) ? pathItem.iconStyle : {};

                    pathItemsNode.push(<a key={row.id} href={'#'} onClick={
                        this.performCustomOperation.bind(this, row, pathItem.operationCb, pathItem.message)}
                        className={pathItem.iconClassName}
                        style={{
                             cursor: 'pointer',
                            ...cssProps
                        }} >{pathItem.icon ? pathItem.icon : null }</a>);
                });
            }

            if(this.viewPath){
                viewPathItemNode = (
                    <Link to={this.viewPath + row.id} className="table-edit view"><FontAwesomeIcon icon={faEye} /></Link>
                )
            }

            return (<div>
                {viewPathItemNode}
                {editActionNode}
                {deleteActionNode}
                {pathItemsNode}
            </div>);
        }
    }
}
