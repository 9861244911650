import React, { Component } from 'react';
import { Path } from '../../container/Path';
import { Navbar, NavbarToggler, Nav, Collapse, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { User } from '../../models/User';
import Identity from '../../user/Identity';
import { Role } from '../../user/Role';

interface IHeaderNavState {
    isOpen: boolean;
}

class HeaderNav extends Component<{}, IHeaderNavState> {
    constructor(props: any) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    protected logout(event: React.MouseEvent<HTMLElement>): void {

        const user: User = new User(false);
        Identity.eraseUserIdentity();

        user.logout().then(() => {
            document.location.pathname = Path.HOMEPAGE.toString();
        }).catch((error) => {
            console.log(error)
        });

        event.preventDefault();
    }

    render() {

        const headerNav = [
            { name: "Home", url: Path.HOMEPAGE.toString() },
            { name: "Suppliers", url: Path.SUPPLIERS.toString() },
            { name: "News", url: Path.NEWS.toString() },
            { name: "Meetings", url: Path.MEETINGS.toString() },
        ];

        let generalSettings;
        if (Role.ADMIN === Identity.role) {
            generalSettings = (
                <DropdownItem tag={Link} to={Path.GENERAL_SETTINGS}>
                    General settings
                </DropdownItem>
            )
        }

        return (
            <>
                <Navbar light expand="md" className="header-navigation py-1">
                    <NavbarToggler onClick={this.toggle} className="ml-auto float-right" />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav navbar>
                            {headerNav.map((single, index) => (
                                <NavItem key={index} className={single.url === document.location.pathname ? 'active' : ''}>
                                    <NavLink tag={Link} to={single.url}>{single.name}</NavLink>
                                </NavItem>
                            )
                            )}
                        </Nav>
                    </Collapse>

                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem className={Path.USERS.toString() === document.location.pathname ? 'active' : ''}>
                                <NavLink tag={Link} to={Path.USERS.toString()}>Users</NavLink>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav className="settings-dropdown">
                                    <FontAwesomeIcon className="user-desktop-settings" icon={faCog} /> <i className="user-settings">Settings & Log out <FontAwesomeIcon icon={faSortDown} /></i>
                                </DropdownToggle>
                                <DropdownMenu right className="header-navigation-settings">

                                    {generalSettings}

                                    <DropdownItem tag={Link} to={Path.PROFILE_SETTINGS}>
                                        Profile settings
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem tag="a" className="log-out" onClick={this.logout}>
                                        Log out
                                     </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </>
        );
    }
}

export default HeaderNav
